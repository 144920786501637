/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionnaireFromTemplateRequest
 */
export interface QuestionnaireFromTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireFromTemplateRequest
     */
    template: number;
}

/**
 * Check if a given object implements the QuestionnaireFromTemplateRequest interface.
 */
export function instanceOfQuestionnaireFromTemplateRequest(value: object): value is QuestionnaireFromTemplateRequest {
    if (!('template' in value) || value['template'] === undefined) return false;
    return true;
}

export function QuestionnaireFromTemplateRequestFromJSON(json: any): QuestionnaireFromTemplateRequest {
    return QuestionnaireFromTemplateRequestFromJSONTyped(json, false);
}

export function QuestionnaireFromTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireFromTemplateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'template': json['template'],
    };
}

export function QuestionnaireFromTemplateRequestToJSON(value?: QuestionnaireFromTemplateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template': value['template'],
    };
}

