/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedRiskRequestStatus } from './PatchedRiskRequestStatus';
import {
    PatchedRiskRequestStatusFromJSON,
    PatchedRiskRequestStatusFromJSONTyped,
    PatchedRiskRequestStatusToJSON,
} from './PatchedRiskRequestStatus';
import type { ControlApprovalStatus } from './ControlApprovalStatus';
import {
    ControlApprovalStatusFromJSON,
    ControlApprovalStatusFromJSONTyped,
    ControlApprovalStatusToJSON,
} from './ControlApprovalStatus';
import type { ReverseRelation } from './ReverseRelation';
import {
    ReverseRelationFromJSON,
    ReverseRelationFromJSONTyped,
    ReverseRelationToJSON,
} from './ReverseRelation';
import type { PatchedRiskRequestTreatmentStrategy } from './PatchedRiskRequestTreatmentStrategy';
import {
    PatchedRiskRequestTreatmentStrategyFromJSON,
    PatchedRiskRequestTreatmentStrategyFromJSONTyped,
    PatchedRiskRequestTreatmentStrategyToJSON,
} from './PatchedRiskRequestTreatmentStrategy';

/**
 * 
 * @export
 * @interface Risk
 */
export interface Risk {
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    approvalBy?: number;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Risk
     */
    tasks?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Risk
     */
    vendors?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Risk
     */
    questionnaires?: Array<ReverseRelation>;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    residualRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    residualRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    inherentRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    inherentRiskImpact?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    readonly riskId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Risk
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    readonly residualRiskLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    readonly residualRiskLabelDescription: string;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    readonly residualRiskScore: number;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    readonly inherentRiskLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    readonly inherentRiskLabelDescription: string;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    readonly inherentRiskScore: number;
    /**
     * 
     * @type {Date}
     * @memberof Risk
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Risk
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Risk
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {ControlApprovalStatus}
     * @memberof Risk
     */
    approvalStatus?: ControlApprovalStatus | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    description?: string | null;
    /**
     * 
     * @type {PatchedRiskRequestStatus}
     * @memberof Risk
     */
    status?: PatchedRiskRequestStatus | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    customId?: string | null;
    /**
     * 
     * @type {PatchedRiskRequestTreatmentStrategy}
     * @memberof Risk
     */
    treatmentStrategy?: PatchedRiskRequestTreatmentStrategy | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    inherentSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    inherentAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    inherentAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    residualSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    residualAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    residualAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {any}
     * @memberof Risk
     */
    scores?: any | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    target?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    riskType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    inherentSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    inherentAnnualLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    residualSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    residualAnnualLossExpectancyCurrency?: number | null;
}

/**
 * Check if a given object implements the Risk interface.
 */
export function instanceOfRisk(value: object): value is Risk {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('riskId' in value) || value['riskId'] === undefined) return false;
    if (!('residualRiskLabel' in value) || value['residualRiskLabel'] === undefined) return false;
    if (!('residualRiskLabelDescription' in value) || value['residualRiskLabelDescription'] === undefined) return false;
    if (!('residualRiskScore' in value) || value['residualRiskScore'] === undefined) return false;
    if (!('inherentRiskLabel' in value) || value['inherentRiskLabel'] === undefined) return false;
    if (!('inherentRiskLabelDescription' in value) || value['inherentRiskLabelDescription'] === undefined) return false;
    if (!('inherentRiskScore' in value) || value['inherentRiskScore'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function RiskFromJSON(json: any): Risk {
    return RiskFromJSONTyped(json, false);
}

export function RiskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Risk {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'approvalBy': json['approval_by'] == null ? undefined : json['approval_by'],
        'tasks': json['tasks'] == null ? undefined : ((json['tasks'] as Array<any>).map(ReverseRelationFromJSON)),
        'vendors': json['vendors'] == null ? undefined : ((json['vendors'] as Array<any>).map(ReverseRelationFromJSON)),
        'questionnaires': json['questionnaires'] == null ? undefined : ((json['questionnaires'] as Array<any>).map(ReverseRelationFromJSON)),
        'residualRiskLikelihood': json['residual_risk_likelihood'] == null ? undefined : json['residual_risk_likelihood'],
        'residualRiskImpact': json['residual_risk_impact'] == null ? undefined : json['residual_risk_impact'],
        'inherentRiskLikelihood': json['inherent_risk_likelihood'] == null ? undefined : json['inherent_risk_likelihood'],
        'inherentRiskImpact': json['inherent_risk_impact'] == null ? undefined : json['inherent_risk_impact'],
        'riskId': json['risk_id'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'residualRiskLabel': json['residual_risk_label'],
        'residualRiskLabelDescription': json['residual_risk_label_description'],
        'residualRiskScore': json['residual_risk_score'],
        'inherentRiskLabel': json['inherent_risk_label'],
        'inherentRiskLabelDescription': json['inherent_risk_label_description'],
        'inherentRiskScore': json['inherent_risk_score'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlApprovalStatusFromJSON(json['approval_status']),
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : PatchedRiskRequestStatusFromJSON(json['status']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'treatmentStrategy': json['treatment_strategy'] == null ? undefined : PatchedRiskRequestTreatmentStrategyFromJSON(json['treatment_strategy']),
        'inherentSingleLossExpectancyValue': json['inherent_single_loss_expectancy_value'] == null ? undefined : json['inherent_single_loss_expectancy_value'],
        'inherentAnnualLossExpectancyValue': json['inherent_annual_loss_expectancy_value'] == null ? undefined : json['inherent_annual_loss_expectancy_value'],
        'inherentAnnualizedRateOfOccurrence': json['inherent_annualized_rate_of_occurrence'] == null ? undefined : json['inherent_annualized_rate_of_occurrence'],
        'residualSingleLossExpectancyValue': json['residual_single_loss_expectancy_value'] == null ? undefined : json['residual_single_loss_expectancy_value'],
        'residualAnnualLossExpectancyValue': json['residual_annual_loss_expectancy_value'] == null ? undefined : json['residual_annual_loss_expectancy_value'],
        'residualAnnualizedRateOfOccurrence': json['residual_annualized_rate_of_occurrence'] == null ? undefined : json['residual_annualized_rate_of_occurrence'],
        'scores': json['scores'] == null ? undefined : json['scores'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'target': json['target'] == null ? undefined : json['target'],
        'area': json['area'] == null ? undefined : json['area'],
        'riskType': json['risk_type'] == null ? undefined : json['risk_type'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'inherentSingleLossExpectancyCurrency': json['inherent_single_loss_expectancy_currency'] == null ? undefined : json['inherent_single_loss_expectancy_currency'],
        'inherentAnnualLossExpectancyCurrency': json['inherent_annual_loss_expectancy_currency'] == null ? undefined : json['inherent_annual_loss_expectancy_currency'],
        'residualSingleLossExpectancyCurrency': json['residual_single_loss_expectancy_currency'] == null ? undefined : json['residual_single_loss_expectancy_currency'],
        'residualAnnualLossExpectancyCurrency': json['residual_annual_loss_expectancy_currency'] == null ? undefined : json['residual_annual_loss_expectancy_currency'],
    };
}

export function RiskToJSON(value?: Omit<Risk, 'id'|'risk_id'|'residual_risk_label'|'residual_risk_label_description'|'residual_risk_score'|'inherent_risk_label'|'inherent_risk_label_description'|'inherent_risk_score'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'approval_by': value['approvalBy'],
        'tasks': value['tasks'] == null ? undefined : ((value['tasks'] as Array<any>).map(ReverseRelationToJSON)),
        'vendors': value['vendors'] == null ? undefined : ((value['vendors'] as Array<any>).map(ReverseRelationToJSON)),
        'questionnaires': value['questionnaires'] == null ? undefined : ((value['questionnaires'] as Array<any>).map(ReverseRelationToJSON)),
        'residual_risk_likelihood': value['residualRiskLikelihood'],
        'residual_risk_impact': value['residualRiskImpact'],
        'inherent_risk_likelihood': value['inherentRiskLikelihood'],
        'inherent_risk_impact': value['inherentRiskImpact'],
        'tags': value['tags'],
        'object_is_public': value['objectIsPublic'],
        'approval_status': ControlApprovalStatusToJSON(value['approvalStatus']),
        'decision_details': value['decisionDetails'],
        'name': value['name'],
        'description': value['description'],
        'status': PatchedRiskRequestStatusToJSON(value['status']),
        'custom_id': value['customId'],
        'treatment_strategy': PatchedRiskRequestTreatmentStrategyToJSON(value['treatmentStrategy']),
        'inherent_single_loss_expectancy_value': value['inherentSingleLossExpectancyValue'],
        'inherent_annual_loss_expectancy_value': value['inherentAnnualLossExpectancyValue'],
        'inherent_annualized_rate_of_occurrence': value['inherentAnnualizedRateOfOccurrence'],
        'residual_single_loss_expectancy_value': value['residualSingleLossExpectancyValue'],
        'residual_annual_loss_expectancy_value': value['residualAnnualLossExpectancyValue'],
        'residual_annualized_rate_of_occurrence': value['residualAnnualizedRateOfOccurrence'],
        'scores': value['scores'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
        'owner': value['owner'],
        'target': value['target'],
        'area': value['area'],
        'risk_type': value['riskType'],
        'updated_by': value['updatedBy'],
        'inherent_single_loss_expectancy_currency': value['inherentSingleLossExpectancyCurrency'],
        'inherent_annual_loss_expectancy_currency': value['inherentAnnualLossExpectancyCurrency'],
        'residual_single_loss_expectancy_currency': value['residualSingleLossExpectancyCurrency'],
        'residual_annual_loss_expectancy_currency': value['residualAnnualLossExpectancyCurrency'],
    };
}

