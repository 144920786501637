import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { TaskAttachmentResponse } from "../types";

export const deleteTaskFile = async (
  fileId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PaginatedApiResponse<TaskAttachmentResponse>>> => {
  return api.delete(`/tasks/attachments/${fileId}/`, { params: { ...permissions } });
};
