/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetTypeEnum } from './WidgetTypeEnum';
import {
    WidgetTypeEnumFromJSON,
    WidgetTypeEnumFromJSONTyped,
    WidgetTypeEnumToJSON,
} from './WidgetTypeEnum';

/**
 * 
 * @export
 * @interface PatchedWidgetRequest
 */
export interface PatchedWidgetRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    dashboard?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedWidgetRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {any}
     * @memberof PatchedWidgetRequest
     */
    data?: any | null;
    /**
     * 
     * @type {WidgetTypeEnum}
     * @memberof PatchedWidgetRequest
     */
    widgetType?: WidgetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedWidgetRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    xIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    yIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedWidgetRequest
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the PatchedWidgetRequest interface.
 */
export function instanceOfPatchedWidgetRequest(value: object): value is PatchedWidgetRequest {
    return true;
}

export function PatchedWidgetRequestFromJSON(json: any): PatchedWidgetRequest {
    return PatchedWidgetRequestFromJSONTyped(json, false);
}

export function PatchedWidgetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedWidgetRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboard': json['dashboard'] == null ? undefined : json['dashboard'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'data': json['data'] == null ? undefined : json['data'],
        'widgetType': json['widget_type'] == null ? undefined : WidgetTypeEnumFromJSON(json['widget_type']),
        'name': json['name'] == null ? undefined : json['name'],
        'xIndex': json['x_index'] == null ? undefined : json['x_index'],
        'yIndex': json['y_index'] == null ? undefined : json['y_index'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function PatchedWidgetRequestToJSON(value?: PatchedWidgetRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboard': value['dashboard'],
        'object_is_public': value['objectIsPublic'],
        'data': value['data'],
        'widget_type': WidgetTypeEnumToJSON(value['widgetType']),
        'name': value['name'],
        'x_index': value['xIndex'],
        'y_index': value['yIndex'],
        'width': value['width'],
        'height': value['height'],
        'object_owner': value['objectOwner'],
        'updated_by': value['updatedBy'],
    };
}

