/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface ControlComment
 */
export interface ControlComment {
    /**
     * 
     * @type {number}
     * @memberof ControlComment
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ControlComment
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof ControlComment
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ControlComment
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ControlComment
     */
    readonly updatedAt: Date;
}



/**
 * Check if a given object implements the ControlComment interface.
 */
export function instanceOfControlComment(value: object): value is ControlComment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ControlCommentFromJSON(json: any): ControlComment {
    return ControlCommentFromJSONTyped(json, false);
}

export function ControlCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function ControlCommentToJSON(value?: Omit<ControlComment, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
    };
}

