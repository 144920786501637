/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PENDING` - Pending
 * * `APPROVED` - Approved
 * * `NOT_APPROVED` - Not Approved
 * @export
 */
export const ControlApprovalStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    NotApproved: 'NOT_APPROVED'
} as const;
export type ControlApprovalStatusEnum = typeof ControlApprovalStatusEnum[keyof typeof ControlApprovalStatusEnum];


export function instanceOfControlApprovalStatusEnum(value: any): boolean {
    for (const key in ControlApprovalStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlApprovalStatusEnum, key)) {
            if (ControlApprovalStatusEnum[key as keyof typeof ControlApprovalStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlApprovalStatusEnumFromJSON(json: any): ControlApprovalStatusEnum {
    return ControlApprovalStatusEnumFromJSONTyped(json, false);
}

export function ControlApprovalStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlApprovalStatusEnum {
    return json as ControlApprovalStatusEnum;
}

export function ControlApprovalStatusEnumToJSON(value?: ControlApprovalStatusEnum | null): any {
    return value as any;
}

