/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportThemeRequest
 */
export interface ReportThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportThemeRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportThemeRequest
     */
    description?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ReportThemeRequest
     */
    data: any | null;
    /**
     * 
     * @type {number}
     * @memberof ReportThemeRequest
     */
    company: number;
}

/**
 * Check if a given object implements the ReportThemeRequest interface.
 */
export function instanceOfReportThemeRequest(value: object): value is ReportThemeRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function ReportThemeRequestFromJSON(json: any): ReportThemeRequest {
    return ReportThemeRequestFromJSONTyped(json, false);
}

export function ReportThemeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportThemeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'data': json['data'],
        'company': json['company'],
    };
}

export function ReportThemeRequestToJSON(value?: ReportThemeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'data': value['data'],
        'company': value['company'],
    };
}

