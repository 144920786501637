/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskAreaRequest
 */
export interface RiskAreaRequest {
    /**
     * 
     * @type {number}
     * @memberof RiskAreaRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RiskAreaRequest
     */
    name: string;
}

/**
 * Check if a given object implements the RiskAreaRequest interface.
 */
export function instanceOfRiskAreaRequest(value: object): value is RiskAreaRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function RiskAreaRequestFromJSON(json: any): RiskAreaRequest {
    return RiskAreaRequestFromJSONTyped(json, false);
}

export function RiskAreaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskAreaRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'],
    };
}

export function RiskAreaRequestToJSON(value?: RiskAreaRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

