/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `in_progress` - In Progress
 * * `completed` - Completed
 * @export
 */
export const QuestionnaireStatusEnum = {
    InProgress: 'in_progress',
    Completed: 'completed'
} as const;
export type QuestionnaireStatusEnum = typeof QuestionnaireStatusEnum[keyof typeof QuestionnaireStatusEnum];


export function instanceOfQuestionnaireStatusEnum(value: any): boolean {
    for (const key in QuestionnaireStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(QuestionnaireStatusEnum, key)) {
            if (QuestionnaireStatusEnum[key as keyof typeof QuestionnaireStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function QuestionnaireStatusEnumFromJSON(json: any): QuestionnaireStatusEnum {
    return QuestionnaireStatusEnumFromJSONTyped(json, false);
}

export function QuestionnaireStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireStatusEnum {
    return json as QuestionnaireStatusEnum;
}

export function QuestionnaireStatusEnumToJSON(value?: QuestionnaireStatusEnum | null): any {
    return value as any;
}

