/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `NEEDS_EVIDENCE` - Needs Evidence
 * @export
 */
export const RiskControlStatusEnum = {
    NeedsEvidence: 'NEEDS_EVIDENCE'
} as const;
export type RiskControlStatusEnum = typeof RiskControlStatusEnum[keyof typeof RiskControlStatusEnum];


export function instanceOfRiskControlStatusEnum(value: any): boolean {
    for (const key in RiskControlStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(RiskControlStatusEnum, key)) {
            if (RiskControlStatusEnum[key as keyof typeof RiskControlStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RiskControlStatusEnumFromJSON(json: any): RiskControlStatusEnum {
    return RiskControlStatusEnumFromJSONTyped(json, false);
}

export function RiskControlStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskControlStatusEnum {
    return json as RiskControlStatusEnum;
}

export function RiskControlStatusEnumToJSON(value?: RiskControlStatusEnum | null): any {
    return value as any;
}

