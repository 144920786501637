/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataAccessEnum } from './DataAccessEnum';
import {
    DataAccessEnumFromJSON,
    DataAccessEnumFromJSONTyped,
    DataAccessEnumToJSON,
} from './DataAccessEnum';
import type { VendorBusinessCriticality } from './VendorBusinessCriticality';
import {
    VendorBusinessCriticalityFromJSON,
    VendorBusinessCriticalityFromJSONTyped,
    VendorBusinessCriticalityToJSON,
} from './VendorBusinessCriticality';
import type { VendorStatusEnum } from './VendorStatusEnum';
import {
    VendorStatusEnumFromJSON,
    VendorStatusEnumFromJSONTyped,
    VendorStatusEnumToJSON,
} from './VendorStatusEnum';
import type { ReverseRelation } from './ReverseRelation';
import {
    ReverseRelationFromJSON,
    ReverseRelationFromJSONTyped,
    ReverseRelationToJSON,
} from './ReverseRelation';
import type { BusinessEngagementTypeEnum } from './BusinessEngagementTypeEnum';
import {
    BusinessEngagementTypeEnumFromJSON,
    BusinessEngagementTypeEnumFromJSONTyped,
    BusinessEngagementTypeEnumToJSON,
} from './BusinessEngagementTypeEnum';

/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    readonly id: number;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    websites?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    tasks?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Vendor
     */
    complianceAuthorityDocuments?: Array<number>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    articles?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    risks?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    incidents?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    questionnaires?: Array<ReverseRelation>;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    readonly commentsCount: string;
    /**
     * 
     * @type {Array<DataAccessEnum>}
     * @memberof Vendor
     */
    dataAccess?: Array<DataAccessEnum>;
    /**
     * 
     * @type {Array<BusinessEngagementTypeEnum>}
     * @memberof Vendor
     */
    businessEngagementType?: Array<BusinessEngagementTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    parentCompany?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Vendor
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Vendor
     */
    vendorLists?: Array<ReverseRelation>;
    /**
     * 
     * @type {Date}
     * @memberof Vendor
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Vendor
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Vendor
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    description?: string | null;
    /**
     * 
     * @type {VendorStatusEnum}
     * @memberof Vendor
     */
    status?: VendorStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    customId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    corporateRegistrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    legalEntityIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    registeredAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {VendorBusinessCriticality}
     * @memberof Vendor
     */
    businessCriticality?: VendorBusinessCriticality | null;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    relationshipOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    countryOfRegistration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the Vendor interface.
 */
export function instanceOfVendor(value: object): value is Vendor {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('commentsCount' in value) || value['commentsCount'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function VendorFromJSON(json: any): Vendor {
    return VendorFromJSONTyped(json, false);
}

export function VendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vendor {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'websites': json['websites'] == null ? undefined : ((json['websites'] as Array<any>).map(ReverseRelationFromJSON)),
        'tasks': json['tasks'] == null ? undefined : ((json['tasks'] as Array<any>).map(ReverseRelationFromJSON)),
        'complianceAuthorityDocuments': json['compliance_authority_documents'] == null ? undefined : json['compliance_authority_documents'],
        'articles': json['articles'] == null ? undefined : ((json['articles'] as Array<any>).map(ReverseRelationFromJSON)),
        'risks': json['risks'] == null ? undefined : ((json['risks'] as Array<any>).map(ReverseRelationFromJSON)),
        'incidents': json['incidents'] == null ? undefined : ((json['incidents'] as Array<any>).map(ReverseRelationFromJSON)),
        'questionnaires': json['questionnaires'] == null ? undefined : ((json['questionnaires'] as Array<any>).map(ReverseRelationFromJSON)),
        'commentsCount': json['comments_count'],
        'dataAccess': json['data_access'] == null ? undefined : ((json['data_access'] as Array<any>).map(DataAccessEnumFromJSON)),
        'businessEngagementType': json['business_engagement_type'] == null ? undefined : ((json['business_engagement_type'] as Array<any>).map(BusinessEngagementTypeEnumFromJSON)),
        'parentCompany': json['parent_company'] == null ? undefined : json['parent_company'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'vendorLists': json['vendor_lists'] == null ? undefined : ((json['vendor_lists'] as Array<any>).map(ReverseRelationFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : VendorStatusEnumFromJSON(json['status']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'corporateRegistrationNumber': json['corporate_registration_number'] == null ? undefined : json['corporate_registration_number'],
        'legalEntityIdentifier': json['legal_entity_identifier'] == null ? undefined : json['legal_entity_identifier'],
        'registeredAddress': json['registered_address'] == null ? undefined : json['registered_address'],
        'contactName': json['contact_name'] == null ? undefined : json['contact_name'],
        'contactEmail': json['contact_email'] == null ? undefined : json['contact_email'],
        'businessCriticality': json['business_criticality'] == null ? undefined : VendorBusinessCriticalityFromJSON(json['business_criticality']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'company': json['company'],
        'relationshipOwner': json['relationship_owner'] == null ? undefined : json['relationship_owner'],
        'countryOfRegistration': json['country_of_registration'] == null ? undefined : json['country_of_registration'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function VendorToJSON(value?: Omit<Vendor, 'id'|'comments_count'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'websites': value['websites'] == null ? undefined : ((value['websites'] as Array<any>).map(ReverseRelationToJSON)),
        'tasks': value['tasks'] == null ? undefined : ((value['tasks'] as Array<any>).map(ReverseRelationToJSON)),
        'compliance_authority_documents': value['complianceAuthorityDocuments'],
        'articles': value['articles'] == null ? undefined : ((value['articles'] as Array<any>).map(ReverseRelationToJSON)),
        'risks': value['risks'] == null ? undefined : ((value['risks'] as Array<any>).map(ReverseRelationToJSON)),
        'incidents': value['incidents'] == null ? undefined : ((value['incidents'] as Array<any>).map(ReverseRelationToJSON)),
        'questionnaires': value['questionnaires'] == null ? undefined : ((value['questionnaires'] as Array<any>).map(ReverseRelationToJSON)),
        'data_access': value['dataAccess'] == null ? undefined : ((value['dataAccess'] as Array<any>).map(DataAccessEnumToJSON)),
        'business_engagement_type': value['businessEngagementType'] == null ? undefined : ((value['businessEngagementType'] as Array<any>).map(BusinessEngagementTypeEnumToJSON)),
        'parent_company': value['parentCompany'],
        'tags': value['tags'],
        'vendor_lists': value['vendorLists'] == null ? undefined : ((value['vendorLists'] as Array<any>).map(ReverseRelationToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'status': VendorStatusEnumToJSON(value['status']),
        'custom_id': value['customId'],
        'corporate_registration_number': value['corporateRegistrationNumber'],
        'legal_entity_identifier': value['legalEntityIdentifier'],
        'registered_address': value['registeredAddress'],
        'contact_name': value['contactName'],
        'contact_email': value['contactEmail'],
        'business_criticality': VendorBusinessCriticalityToJSON(value['businessCriticality']),
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
        'company': value['company'],
        'relationship_owner': value['relationshipOwner'],
        'country_of_registration': value['countryOfRegistration'],
        'updated_by': value['updatedBy'],
    };
}

