import { useFormik } from "formik";

import { useCompany } from "shared/context/CompanyProvider";
import { GetTaskResultType } from "shared/types";

import { addTaskSchema } from "../components/validation";
import { TaskResultType } from "../types";

export const useTaskForm = (
  currentTeam: number,
  action: CallableFunction,
  currentTask?: GetTaskResultType | null
) => {
  const { currentCompany } = useCompany();

  return useFormik<TaskResultType>({
    initialValues: {
      name: currentTask?.name || "",
      description: currentTask?.description || "",
      company: currentTask?.company || currentCompany?.id || 0,
      team: currentTask?.team || currentTeam || 0,
      status: currentTask?.status?.id || 0,
      status_object: currentTask?.status || null,
      due_date: currentTask?.due_date || null,
      tags: currentTask?.tags || [],
      assignees: currentTask?.assignees || [],
      mentions: currentTask?.mentions || [],
      owner: currentTask?.owner || "",
      owner_object: currentTask?.owner_object || null,
      priority: currentTask?.priority || "",
      agreement: currentTask?.agreement || null,
      policy: currentTask?.policy || null,
      article: currentTask?.article || null,
      document: currentTask?.document || null,
      note: currentTask?.note || null,
      risk: currentTask?.risk || null,
      created_at: currentTask?.created_at || "",
      updated_at: currentTask?.updated_at || "",
      is_deleted: currentTask?.is_deleted || false,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: addTaskSchema,
    onSubmit: () => {
      return action();
    },
  });
};
