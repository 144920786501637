/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface ControlCommentRequest
 */
export interface ControlCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlCommentRequest
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof ControlCommentRequest
     */
    status?: CommentStatusEnum;
}



/**
 * Check if a given object implements the ControlCommentRequest interface.
 */
export function instanceOfControlCommentRequest(value: object): value is ControlCommentRequest {
    return true;
}

export function ControlCommentRequestFromJSON(json: any): ControlCommentRequest {
    return ControlCommentRequestFromJSONTyped(json, false);
}

export function ControlCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCommentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
    };
}

export function ControlCommentRequestToJSON(value?: ControlCommentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
    };
}

