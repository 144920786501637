/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EvidenceRequestRequestAreaOfFocus } from './EvidenceRequestRequestAreaOfFocus';
import {
    EvidenceRequestRequestAreaOfFocusFromJSON,
    EvidenceRequestRequestAreaOfFocusFromJSONTyped,
    EvidenceRequestRequestAreaOfFocusToJSON,
} from './EvidenceRequestRequestAreaOfFocus';
import type { ReviewFrequencyRequest } from './ReviewFrequencyRequest';
import {
    ReviewFrequencyRequestFromJSON,
    ReviewFrequencyRequestFromJSONTyped,
    ReviewFrequencyRequestToJSON,
} from './ReviewFrequencyRequest';
import type { EvidenceStatusEnum } from './EvidenceStatusEnum';
import {
    EvidenceStatusEnumFromJSON,
    EvidenceStatusEnumFromJSONTyped,
    EvidenceStatusEnumToJSON,
} from './EvidenceStatusEnum';

/**
 * 
 * @export
 * @interface EvidenceResponseRequest
 */
export interface EvidenceResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponseRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponseRequest
     */
    description?: string | null;
    /**
     * 
     * @type {EvidenceRequestRequestAreaOfFocus}
     * @memberof EvidenceResponseRequest
     */
    areaOfFocus?: EvidenceRequestRequestAreaOfFocus | null;
    /**
     * 
     * @type {ReviewFrequencyRequest}
     * @memberof EvidenceResponseRequest
     */
    reviewFrequency: ReviewFrequencyRequest;
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponseRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EvidenceResponseRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {EvidenceStatusEnum}
     * @memberof EvidenceResponseRequest
     */
    status?: EvidenceStatusEnum;
}



/**
 * Check if a given object implements the EvidenceResponseRequest interface.
 */
export function instanceOfEvidenceResponseRequest(value: object): value is EvidenceResponseRequest {
    if (!('reviewFrequency' in value) || value['reviewFrequency'] === undefined) return false;
    return true;
}

export function EvidenceResponseRequestFromJSON(json: any): EvidenceResponseRequest {
    return EvidenceResponseRequestFromJSONTyped(json, false);
}

export function EvidenceResponseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceResponseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'areaOfFocus': json['area_of_focus'] == null ? undefined : EvidenceRequestRequestAreaOfFocusFromJSON(json['area_of_focus']),
        'reviewFrequency': ReviewFrequencyRequestFromJSON(json['review_frequency']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'status': json['status'] == null ? undefined : EvidenceStatusEnumFromJSON(json['status']),
    };
}

export function EvidenceResponseRequestToJSON(value?: EvidenceResponseRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'area_of_focus': EvidenceRequestRequestAreaOfFocusToJSON(value['areaOfFocus']),
        'review_frequency': ReviewFrequencyRequestToJSON(value['reviewFrequency']),
        'custom_id': value['customId'],
        'object_owner': value['objectOwner'],
        'status': EvidenceStatusEnumToJSON(value['status']),
    };
}

