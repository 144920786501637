/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IncidentOverallImpact } from './IncidentOverallImpact';
import {
    IncidentOverallImpactFromJSON,
    IncidentOverallImpactFromJSONTyped,
    IncidentOverallImpactToJSON,
} from './IncidentOverallImpact';
import type { IncidentStatus } from './IncidentStatus';
import {
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface PatchedIncidentRequest
 */
export interface PatchedIncidentRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedIncidentRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedIncidentRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedIncidentRequest
     */
    description?: string | null;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof PatchedIncidentRequest
     */
    status?: IncidentStatus | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedIncidentRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof PatchedIncidentRequest
     */
    overallImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof PatchedIncidentRequest
     */
    operationImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof PatchedIncidentRequest
     */
    financialImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedIncidentRequest
     */
    grossLossImpact?: string | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof PatchedIncidentRequest
     */
    reputationalImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof PatchedIncidentRequest
     */
    legalComplianceImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    company?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    reportedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedIncidentRequest
     */
    grossLossImpactCurrency?: number | null;
}

/**
 * Check if a given object implements the PatchedIncidentRequest interface.
 */
export function instanceOfPatchedIncidentRequest(value: object): value is PatchedIncidentRequest {
    return true;
}

export function PatchedIncidentRequestFromJSON(json: any): PatchedIncidentRequest {
    return PatchedIncidentRequestFromJSONTyped(json, false);
}

export function PatchedIncidentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedIncidentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : IncidentStatusFromJSON(json['status']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'overallImpact': json['overall_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['overall_impact']),
        'operationImpact': json['operation_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['operation_impact']),
        'financialImpact': json['financial_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['financial_impact']),
        'grossLossImpact': json['gross_loss_impact'] == null ? undefined : json['gross_loss_impact'],
        'reputationalImpact': json['reputational_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['reputational_impact']),
        'legalComplianceImpact': json['legal_compliance_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['legal_compliance_impact']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'company': json['company'] == null ? undefined : json['company'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'reportedBy': json['reported_by'] == null ? undefined : json['reported_by'],
        'grossLossImpactCurrency': json['gross_loss_impact_currency'] == null ? undefined : json['gross_loss_impact_currency'],
    };
}

export function PatchedIncidentRequestToJSON(value?: PatchedIncidentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'status': IncidentStatusToJSON(value['status']),
        'custom_id': value['customId'],
        'overall_impact': IncidentOverallImpactToJSON(value['overallImpact']),
        'operation_impact': IncidentOverallImpactToJSON(value['operationImpact']),
        'financial_impact': IncidentOverallImpactToJSON(value['financialImpact']),
        'gross_loss_impact': value['grossLossImpact'],
        'reputational_impact': IncidentOverallImpactToJSON(value['reputationalImpact']),
        'legal_compliance_impact': IncidentOverallImpactToJSON(value['legalComplianceImpact']),
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
        'company': value['company'],
        'owner': value['owner'],
        'reported_by': value['reportedBy'],
        'gross_loss_impact_currency': value['grossLossImpactCurrency'],
    };
}

