/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArtifactRecord
 */
export interface ArtifactRecord {
    /**
     * 
     * @type {number}
     * @memberof ArtifactRecord
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRecord
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRecord
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRecord
     */
    readonly filePath: string;
    /**
     * 
     * @type {Date}
     * @memberof ArtifactRecord
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ArtifactRecord
     */
    readonly originalArtifactId: number;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRecord
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArtifactRecord
     */
    readonly createdBy: number | null;
}

/**
 * Check if a given object implements the ArtifactRecord interface.
 */
export function instanceOfArtifactRecord(value: object): value is ArtifactRecord {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('filePath' in value) || value['filePath'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('originalArtifactId' in value) || value['originalArtifactId'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    return true;
}

export function ArtifactRecordFromJSON(json: any): ArtifactRecord {
    return ArtifactRecordFromJSONTyped(json, false);
}

export function ArtifactRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtifactRecord {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'url': json['url'] == null ? undefined : json['url'],
        'filePath': json['file_path'],
        'createdAt': (new Date(json['created_at'])),
        'originalArtifactId': json['original_artifact_id'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdBy': json['created_by'],
    };
}

export function ArtifactRecordToJSON(value?: Omit<ArtifactRecord, 'id'|'file_path'|'created_at'|'original_artifact_id'|'created_by'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'url': value['url'],
        'description': value['description'],
    };
}

