/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardList
 */
export interface DashboardList {
    /**
     * 
     * @type {number}
     * @memberof DashboardList
     */
    readonly id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardList
     */
    dashboards?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof DashboardList
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardList
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardList
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardList
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardList
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardList
     */
    company: number;
}

/**
 * Check if a given object implements the DashboardList interface.
 */
export function instanceOfDashboardList(value: object): value is DashboardList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function DashboardListFromJSON(json: any): DashboardList {
    return DashboardListFromJSONTyped(json, false);
}

export function DashboardListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dashboards': json['dashboards'] == null ? undefined : json['dashboards'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
    };
}

export function DashboardListToJSON(value?: Omit<DashboardList, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboards': value['dashboards'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

