/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `OPEN` - Open
 * * `SOLVED` - Solved
 * @export
 */
export const IncidentStatusEnum = {
    Open: 'OPEN',
    Solved: 'SOLVED'
} as const;
export type IncidentStatusEnum = typeof IncidentStatusEnum[keyof typeof IncidentStatusEnum];


export function instanceOfIncidentStatusEnum(value: any): boolean {
    for (const key in IncidentStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(IncidentStatusEnum, key)) {
            if (IncidentStatusEnum[key as keyof typeof IncidentStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IncidentStatusEnumFromJSON(json: any): IncidentStatusEnum {
    return IncidentStatusEnumFromJSONTyped(json, false);
}

export function IncidentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentStatusEnum {
    return json as IncidentStatusEnum;
}

export function IncidentStatusEnumToJSON(value?: IncidentStatusEnum | null): any {
    return value as any;
}

