import { forwardRef } from "react";

import { cn } from "lib/utils";

import styles from "assets/css/formControls.module.css";

import Icon from "shared/components/icon/Icon";

import { BadgeProps } from "../types";

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      handleClear,
      badgeSize = "md",
      badgeType = "primary",
      icon,
      badgeTitle,
      badgeColor,
      badgeBgColor,
      iconSize = "md",
      badgeID,
      labelClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          styles.badge,
          styles[`badge_${badgeSize}`],
          styles[`badge_${badgeType}`],
          props.className
        )}
        style={{ backgroundColor: badgeBgColor }}
      >
        {icon ? icon : null}
        {badgeTitle ? (
          <label
            htmlFor={badgeID || ""}
            style={{ color: badgeColor }}
            id="badgeTitle"
            className={cn("select-none", labelClassName)}
          >
            {badgeTitle}
          </label>
        ) : null}
        {handleClear ? (
          <button onClick={handleClear}>
            <Icon
              type="x-marks"
              fill={true}
              size={`icon-${iconSize}`}
              className="clearIcon"
              badgeColor={badgeColor}
            />
          </button>
        ) : null}
      </div>
    );
  }
);

export default Badge;
