import { toast } from "react-toastify";

import { client, clientPublic } from "client";
import { Article, ArticleImpactEnum, ArticleStatusEnum, PaginatedArticleListList } from "openapi";

import { InfiniteData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useFeedItemActions, useFeedItemBulkActions } from "./useFeedActionState";

type ArticleQueryProps = { id: ID; feedId: ID; companyId: ID; isPublic?: boolean };

export const useArticle = (params: ArticleQueryProps) => {
  return useQuery({
    queryKey: ["article-v1", params],
    queryFn: () => {
      if (params.isPublic)
        return clientPublic.horizonScanning.horizonFeedsArticlesRetrieve(params, {});
      return client.horizonScanning.horizonFeedsArticlesRetrieve(params);
    },
  });
};

enum UpdateArticleAction {
  archive = "archive",
  unarchive = "unarchive",
  save = "save",
  unsave = "unsave",
  read = "read",
  unread = "unread",
}

type UpdatePayload =
  | { action: UpdateArticleAction }
  | { action: "assignees"; assignees: ID[] }
  | { action: "add_tags"; tags: string[] }
  | { action: "add_status"; payload: ArticleStatusEnum }
  | { action: "add_impact"; payload: ArticleImpactEnum };

export const useUpdateArticle = (params: { id: ID; feedId: ID; companyId: ID }) => {
  const queryClient = useQueryClient();

  const stateAction = useFeedItemActions({ feedId: params.feedId, articleId: params.id });

  const updateFeedArticleCache = (newData: Partial<Article>) => {
    const queryCache = queryClient.getQueryCache();
    const feedId = params.feedId;
    const companyId = params.companyId;

    queryCache
      .findAll({ queryKey: ["feed-articles", feedId, companyId] })
      .forEach(({ queryKey }) => {
        const [queryName, currentFeedId, currentCompanyId, filters] = queryKey;

        if (currentFeedId === feedId && currentCompanyId === companyId) {
          queryClient.setQueryData<InfiniteData<PaginatedArticleListList>>(
            [queryName, currentFeedId, currentCompanyId, filters],
            (oldData) => {
              if (!oldData) return oldData;

              return {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  results:
                    newData.isArchived !== undefined
                      ? page.results.filter((article) => article.id !== params.id)
                      : page.results.map((article) =>
                          article.id === params.id ? { ...article, ...newData } : article
                        ),
                })),
              };
            }
          );
        }
      });
  };

  const handleActions = useMutation({
    onMutate(payload) {
      switch (payload.action) {
        case UpdateArticleAction.archive:
          return stateAction.archive();
        case UpdateArticleAction.unarchive:
          return stateAction.unarchive();
        case UpdateArticleAction.save:
          return stateAction.save();
        case UpdateArticleAction.unsave:
          return stateAction.unsave();
        case UpdateArticleAction.read:
          return stateAction.read();
        case UpdateArticleAction.unread:
          return stateAction.unread();
      }
    },
    onSuccess(_, data) {
      switch (data.action) {
        case "assignees":
          queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, assignees: data.assignees } : undefined
          );
          updateFeedArticleCache({ assignees: data.assignees });
          break;
        case "add_impact":
          queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, impact: data.payload } : undefined
          );
          updateFeedArticleCache({ impact: data.payload });
          break;
        case "add_status":
          queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, status: data.payload } : undefined
          );
          updateFeedArticleCache({ status: data.payload });
          break;
        case "add_tags": {
          const tags = data.tags.map((e) => ({
            name: e,
            slug: e,
            company: 0,
            team: 0,
            id: new Date().getTime(),
          }));
          queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, tags } : undefined
          );
          updateFeedArticleCache({ tags });
          break;
        }
        case UpdateArticleAction.archive:
          updateFeedArticleCache({ isArchived: true });
          break;
        case UpdateArticleAction.unarchive:
          updateFeedArticleCache({ isArchived: false });
          break;
        default:
          break;
      }
    },
    mutationFn: (payload: UpdatePayload) => {
      switch (payload.action) {
        case UpdateArticleAction.archive:
          return client.horizonScanning.horizonFeedsArticlesArchiveCreate(params);
        case UpdateArticleAction.unarchive:
          return client.horizonScanning.horizonFeedsArticlesUnarchiveCreate(params);
        case UpdateArticleAction.save:
          return client.horizonScanning.horizonFeedsArticlesSaveForLaterCreate(params);
        case UpdateArticleAction.unsave:
          return client.horizonScanning.horizonFeedsArticlesUnsaveForLaterCreate(params);
        case UpdateArticleAction.read:
          return client.horizonScanning.horizonFeedsArticlesMarkAsReadCreate(params);
        case UpdateArticleAction.unread:
          return client.horizonScanning.horizonFeedsArticlesMarkAsUnreadCreate(params);
        case "assignees":
          return client.horizonScanning.horizonFeedsArticlesUpdateAssigneesCreate({
            ...params,
            feedId: params.feedId,
            companyId: params.companyId,
            addAssigneesRequest: { assignees: payload.assignees },
          });
        case "add_impact":
          return client.horizonScanning.horizonFeedsArticlesAddImpactCreate({
            ...params,
            addImpactRequest: { impact: payload.payload },
          });
        case "add_status":
          return client.horizonScanning.horizonFeedsArticlesAddStatusCreate({
            ...params,
            addStatusRequest: { status: payload.payload },
          });
        case "add_tags":
          return client.horizonScanning.horizonFeedsArticlesAddTagsCreate({
            ...params,
            addTagsRequest: { tags: payload.tags },
          });
        default:
          throw new Error("Invalid action");
      }
    },
  });

  const archive = (beforeRequest?: () => void) => {
    if (beforeRequest) beforeRequest();
    return handleActions.mutateAsync({ action: UpdateArticleAction.archive });
  };

  const unarchive = (beforeRequest?: () => void) => {
    if (beforeRequest) beforeRequest();
    return handleActions.mutateAsync({ action: UpdateArticleAction.unarchive });
  };

  const save = (onSuccess?: () => void) =>
    handleActions.mutateAsync({ action: UpdateArticleAction.save }).then(() => {
      if (onSuccess) onSuccess();
    });
  const unsave = (onSuccess?: () => void) =>
    handleActions.mutateAsync({ action: UpdateArticleAction.unsave }).then(() => {
      if (onSuccess) onSuccess();
    });

  const read = (onSuccess?: () => void) =>
    handleActions.mutateAsync({ action: UpdateArticleAction.read }).then(() => {
      if (onSuccess) onSuccess();
    });

  const unread = (onSuccess?: () => void) =>
    handleActions.mutateAsync({ action: UpdateArticleAction.unread }).then(() => {
      if (onSuccess) onSuccess();
    });

  const updateAssignees = (assignees: ID[]) =>
    handleActions.mutateAsync({ action: "assignees", assignees });

  const addImpact = (payload: ArticleImpactEnum) =>
    handleActions.mutateAsync({ action: "add_impact", payload });

  const addStatus = (payload: ArticleStatusEnum) =>
    handleActions.mutateAsync({ action: "add_status", payload });

  const addTags = (tags: string[]) => handleActions.mutateAsync({ action: "add_tags", tags });

  return {
    addImpact,
    addStatus,
    addTags,
    archive,
    unarchive,
    save,
    unsave,
    read,
    unread,
    updateAssignees,
    isLoading: handleActions.isLoading,
    isSuccess: handleActions.isSuccess,
    isError: handleActions.isError,
  };
};

export const useBulkUpdateArticles = (params: { feedId: ID; companyId: ID }) => {
  const queryClient = useQueryClient();
  const stateAction = useFeedItemBulkActions();

  const handleActions = useMutation({
    onMutate(payload) {
      const mappedPayload = payload.ids.map((id) => ({ feedId: params.feedId, articleId: id }));

      switch (payload.action) {
        case UpdateArticleAction.archive:
          return stateAction.bulkArchive(mappedPayload);
        case UpdateArticleAction.unarchive:
          return stateAction.bulkUnarchive(mappedPayload);
        case UpdateArticleAction.save:
          return stateAction.bulkSave(mappedPayload);
        case UpdateArticleAction.unsave:
          return stateAction.bulkUnsave(mappedPayload);
        case UpdateArticleAction.read:
          return stateAction.bulkRead(mappedPayload);
        case UpdateArticleAction.unread:
          return stateAction.bulkUnread(mappedPayload);
      }
    },
    onSuccess(_, payload) {
      const actionMap = {
        [UpdateArticleAction.archive]: "Articles archived",
        [UpdateArticleAction.unarchive]: "Articles unarchived",
        [UpdateArticleAction.save]: "Articles saved",
        [UpdateArticleAction.unsave]: "Articles unsaved",
        [UpdateArticleAction.read]: "Articles marked as read",
        [UpdateArticleAction.unread]: "Articles marked as unread",
      };

      toast(actionMap[payload.action], { type: "success" });

      switch (payload.action) {
        case UpdateArticleAction.archive:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isArchived: true } : undefined
          );
        case UpdateArticleAction.unarchive:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isArchived: false } : undefined
          );
        case UpdateArticleAction.save:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isSaved: true } : undefined
          );
        case UpdateArticleAction.unsave:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isSaved: false } : undefined
          );
        case UpdateArticleAction.read:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isRead: true } : undefined
          );
        case UpdateArticleAction.unread:
          return queryClient.setQueryData<Article>(["article-v1", params], (old) =>
            old ? { ...old, isRead: false } : undefined
          );
        default:
          throw new Error("Invalid action");
      }
    },
    mutationFn: ({ ids, action }: { ids: ID[]; action: UpdateArticleAction }) => {
      switch (action) {
        case UpdateArticleAction.archive:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesArchiveCreate({ id, ...params })
            )
          );

        case UpdateArticleAction.unarchive:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesUnarchiveCreate({ id, ...params })
            )
          );

        case UpdateArticleAction.save:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesSaveForLaterCreate({ id, ...params })
            )
          );
        case UpdateArticleAction.unsave:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesUnsaveForLaterCreate({ id, ...params })
            )
          );
        case UpdateArticleAction.read:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesMarkAsReadCreate({ id, ...params })
            )
          );
        case UpdateArticleAction.unread:
          return Promise.all(
            ids.map((id) =>
              client.horizonScanning.horizonFeedsArticlesMarkAsUnreadCreate({ id, ...params })
            )
          );
        default:
          throw new Error("Invalid action");
      }
    },
  });

  const archive = (ids: ID[]) =>
    handleActions.mutateAsync({ ids, action: UpdateArticleAction.archive });

  const unarchive = (ids: ID[]) =>
    handleActions.mutateAsync({ ids, action: UpdateArticleAction.unarchive });

  const save = (ids: ID[]) => handleActions.mutateAsync({ ids, action: UpdateArticleAction.save });

  const unsave = (ids: ID[]) =>
    handleActions.mutateAsync({ ids, action: UpdateArticleAction.unsave });

  const read = (ids: ID[]) => handleActions.mutateAsync({ ids, action: UpdateArticleAction.read });

  const unread = (ids: ID[]) =>
    handleActions.mutateAsync({ ids, action: UpdateArticleAction.unread });

  return {
    archive,
    unarchive,
    save,
    unsave,
    read,
    unread,
    isLoading: handleActions.isLoading,
    isSuccess: handleActions.isSuccess,
    isError: handleActions.isError,
  };
};
