/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    utmSource?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof User
     */
    customPermissions?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profilePicture?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    receiveEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    receiveWebPushNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    showWelcomeMessage?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    readonly lastLogin: Date | null;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lastLogin' in value) || value['lastLogin'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'id': json['id'],
        'utmSource': json['utm_source'] == null ? undefined : json['utm_source'],
        'customPermissions': json['custom_permissions'] == null ? undefined : json['custom_permissions'],
        'profilePicture': json['profile_picture'] == null ? undefined : json['profile_picture'],
        'receiveEmailNotifications': json['receive_email_notifications'] == null ? undefined : json['receive_email_notifications'],
        'receiveWebPushNotifications': json['receive_web_push_notifications'] == null ? undefined : json['receive_web_push_notifications'],
        'showWelcomeMessage': json['show_welcome_message'] == null ? undefined : json['show_welcome_message'],
        'lastLogin': (json['last_login'] == null ? null : new Date(json['last_login'])),
    };
}

export function UserToJSON(value?: Omit<User, 'email'|'id'|'last_login'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'utm_source': value['utmSource'],
        'custom_permissions': value['customPermissions'],
        'profile_picture': value['profilePicture'],
        'receive_email_notifications': value['receiveEmailNotifications'],
        'receive_web_push_notifications': value['receiveWebPushNotifications'],
        'show_welcome_message': value['showWelcomeMessage'],
    };
}

