/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { RiskStatusEnum } from './RiskStatusEnum';
import {
    instanceOfRiskStatusEnum,
    RiskStatusEnumFromJSON,
    RiskStatusEnumFromJSONTyped,
    RiskStatusEnumToJSON,
} from './RiskStatusEnum';

/**
 * @type PatchedRiskRequestStatus
 * 
 * @export
 */
export type PatchedRiskRequestStatus = BlankEnum | RiskStatusEnum;

export function PatchedRiskRequestStatusFromJSON(json: any): PatchedRiskRequestStatus {
    return PatchedRiskRequestStatusFromJSONTyped(json, false);
}

export function PatchedRiskRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedRiskRequestStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfRiskStatusEnum(json)) {
        return RiskStatusEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function PatchedRiskRequestStatusToJSON(value?: PatchedRiskRequestStatus | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfRiskStatusEnum(value)) {
        return RiskStatusEnumToJSON(value as RiskStatusEnum);
    }

    return {};
}

