/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedRiskRequestStatus } from './PatchedRiskRequestStatus';
import {
    PatchedRiskRequestStatusFromJSON,
    PatchedRiskRequestStatusFromJSONTyped,
    PatchedRiskRequestStatusToJSON,
} from './PatchedRiskRequestStatus';
import type { ControlApprovalStatus } from './ControlApprovalStatus';
import {
    ControlApprovalStatusFromJSON,
    ControlApprovalStatusFromJSONTyped,
    ControlApprovalStatusToJSON,
} from './ControlApprovalStatus';
import type { PatchedRiskRequestTreatmentStrategy } from './PatchedRiskRequestTreatmentStrategy';
import {
    PatchedRiskRequestTreatmentStrategyFromJSON,
    PatchedRiskRequestTreatmentStrategyFromJSONTyped,
    PatchedRiskRequestTreatmentStrategyToJSON,
} from './PatchedRiskRequestTreatmentStrategy';

/**
 * 
 * @export
 * @interface PatchedRiskTemplateRequest
 */
export interface PatchedRiskTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    company?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedRiskTemplateRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {ControlApprovalStatus}
     * @memberof PatchedRiskTemplateRequest
     */
    approvalStatus?: ControlApprovalStatus | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {PatchedRiskRequestStatus}
     * @memberof PatchedRiskTemplateRequest
     */
    status?: PatchedRiskRequestStatus | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    residualRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    residualRiskLikelihood?: number | null;
    /**
     * 
     * @type {PatchedRiskRequestTreatmentStrategy}
     * @memberof PatchedRiskTemplateRequest
     */
    treatmentStrategy?: PatchedRiskRequestTreatmentStrategy | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    residualSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    residualAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    residualAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskTemplateRequest
     */
    riskArea?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    approvalBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    target?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    riskType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    inherentAnnualLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    residualSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskTemplateRequest
     */
    residualAnnualLossExpectancyCurrency?: number | null;
}

/**
 * Check if a given object implements the PatchedRiskTemplateRequest interface.
 */
export function instanceOfPatchedRiskTemplateRequest(value: object): value is PatchedRiskTemplateRequest {
    return true;
}

export function PatchedRiskTemplateRequestFromJSON(json: any): PatchedRiskTemplateRequest {
    return PatchedRiskTemplateRequestFromJSONTyped(json, false);
}

export function PatchedRiskTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedRiskTemplateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'company': json['company'] == null ? undefined : json['company'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlApprovalStatusFromJSON(json['approval_status']),
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : PatchedRiskRequestStatusFromJSON(json['status']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'inherentRiskImpact': json['inherent_risk_impact'] == null ? undefined : json['inherent_risk_impact'],
        'inherentRiskLikelihood': json['inherent_risk_likelihood'] == null ? undefined : json['inherent_risk_likelihood'],
        'residualRiskImpact': json['residual_risk_impact'] == null ? undefined : json['residual_risk_impact'],
        'residualRiskLikelihood': json['residual_risk_likelihood'] == null ? undefined : json['residual_risk_likelihood'],
        'treatmentStrategy': json['treatment_strategy'] == null ? undefined : PatchedRiskRequestTreatmentStrategyFromJSON(json['treatment_strategy']),
        'inherentSingleLossExpectancyValue': json['inherent_single_loss_expectancy_value'] == null ? undefined : json['inherent_single_loss_expectancy_value'],
        'inherentAnnualLossExpectancyValue': json['inherent_annual_loss_expectancy_value'] == null ? undefined : json['inherent_annual_loss_expectancy_value'],
        'inherentAnnualizedRateOfOccurrence': json['inherent_annualized_rate_of_occurrence'] == null ? undefined : json['inherent_annualized_rate_of_occurrence'],
        'residualSingleLossExpectancyValue': json['residual_single_loss_expectancy_value'] == null ? undefined : json['residual_single_loss_expectancy_value'],
        'residualAnnualLossExpectancyValue': json['residual_annual_loss_expectancy_value'] == null ? undefined : json['residual_annual_loss_expectancy_value'],
        'residualAnnualizedRateOfOccurrence': json['residual_annualized_rate_of_occurrence'] == null ? undefined : json['residual_annualized_rate_of_occurrence'],
        'riskArea': json['risk_area'] == null ? undefined : json['risk_area'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'approvalBy': json['approval_by'] == null ? undefined : json['approval_by'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'target': json['target'] == null ? undefined : json['target'],
        'area': json['area'] == null ? undefined : json['area'],
        'riskType': json['risk_type'] == null ? undefined : json['risk_type'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'inherentSingleLossExpectancyCurrency': json['inherent_single_loss_expectancy_currency'] == null ? undefined : json['inherent_single_loss_expectancy_currency'],
        'inherentAnnualLossExpectancyCurrency': json['inherent_annual_loss_expectancy_currency'] == null ? undefined : json['inherent_annual_loss_expectancy_currency'],
        'residualSingleLossExpectancyCurrency': json['residual_single_loss_expectancy_currency'] == null ? undefined : json['residual_single_loss_expectancy_currency'],
        'residualAnnualLossExpectancyCurrency': json['residual_annual_loss_expectancy_currency'] == null ? undefined : json['residual_annual_loss_expectancy_currency'],
    };
}

export function PatchedRiskTemplateRequestToJSON(value?: PatchedRiskTemplateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': value['company'],
        'object_is_public': value['objectIsPublic'],
        'approval_status': ControlApprovalStatusToJSON(value['approvalStatus']),
        'decision_details': value['decisionDetails'],
        'name': value['name'],
        'description': value['description'],
        'status': PatchedRiskRequestStatusToJSON(value['status']),
        'custom_id': value['customId'],
        'inherent_risk_impact': value['inherentRiskImpact'],
        'inherent_risk_likelihood': value['inherentRiskLikelihood'],
        'residual_risk_impact': value['residualRiskImpact'],
        'residual_risk_likelihood': value['residualRiskLikelihood'],
        'treatment_strategy': PatchedRiskRequestTreatmentStrategyToJSON(value['treatmentStrategy']),
        'inherent_single_loss_expectancy_value': value['inherentSingleLossExpectancyValue'],
        'inherent_annual_loss_expectancy_value': value['inherentAnnualLossExpectancyValue'],
        'inherent_annualized_rate_of_occurrence': value['inherentAnnualizedRateOfOccurrence'],
        'residual_single_loss_expectancy_value': value['residualSingleLossExpectancyValue'],
        'residual_annual_loss_expectancy_value': value['residualAnnualLossExpectancyValue'],
        'residual_annualized_rate_of_occurrence': value['residualAnnualizedRateOfOccurrence'],
        'risk_area': value['riskArea'],
        'object_owner': value['objectOwner'],
        'approval_by': value['approvalBy'],
        'owner': value['owner'],
        'target': value['target'],
        'area': value['area'],
        'risk_type': value['riskType'],
        'updated_by': value['updatedBy'],
        'inherent_single_loss_expectancy_currency': value['inherentSingleLossExpectancyCurrency'],
        'inherent_annual_loss_expectancy_currency': value['inherentAnnualLossExpectancyCurrency'],
        'residual_single_loss_expectancy_currency': value['residualSingleLossExpectancyCurrency'],
        'residual_annual_loss_expectancy_currency': value['residualAnnualLossExpectancyCurrency'],
    };
}

