/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardTemplateListItemList
 */
export interface DashboardTemplateListItemList {
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplateListItemList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateListItemList
     */
    objectType?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateListItemList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateListItemList
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplateListItemList
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplateListItemList
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplateListItemList
     */
    readonly companyId: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplateListItemList
     */
    readonly objectOwnerId: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardTemplateListItemList
     */
    objectIsPublic?: boolean;
}

/**
 * Check if a given object implements the DashboardTemplateListItemList interface.
 */
export function instanceOfDashboardTemplateListItemList(value: object): value is DashboardTemplateListItemList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('objectOwnerId' in value) || value['objectOwnerId'] === undefined) return false;
    return true;
}

export function DashboardTemplateListItemListFromJSON(json: any): DashboardTemplateListItemList {
    return DashboardTemplateListItemListFromJSONTyped(json, false);
}

export function DashboardTemplateListItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardTemplateListItemList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'objectOwnerId': json['object_owner_id'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
    };
}

export function DashboardTemplateListItemListToJSON(value?: Omit<DashboardTemplateListItemList, 'id'|'created_at'|'updated_at'|'company_id'|'object_owner_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_type': value['objectType'],
        'name': value['name'],
        'description': value['description'],
        'object_is_public': value['objectIsPublic'],
    };
}

