import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import clsx from "clsx";

import styles from "assets/css/sidebarV2.module.css";

import { MOBILE_DEVICE } from "shared/helpers/constant";
import useModuleEnabled from "shared/hooks/useModuleEnabled";
import useScreen from "shared/hooks/useScreen";
import { useSideMenu } from "shared/store/rightSideBar";
import { useSidebar } from "shared/store/sidebar";

import { getSidebarItemIcon } from "../../components/icons/Icons";
import { ItemTypes, RenderListProps } from "./types";

export default function RenderList({ sidebarItem, index, onClick }: RenderListProps) {
  const deviceType = useScreen();

  const setIsSideMenuShown = useSideMenu((state) => state.setIsSideMenuShown);

  const { checkEnabled } = useModuleEnabled();

  const { pathname } = useLocation();

  const setIsVisible = useSidebar((state) => state.setIsVisible);

  const onLinkClick = () => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    }
  };

  const module = sidebarItem?.subject;

  if (module && !checkEnabled(module)) {
    return null;
  }

  return (
    <li key={index}>
      <Link
        to={sidebarItem.path || pathname}
        onClick={() => {
          onClick(index);
        }}
        className={clsx(
          "flex items-center rounded-lg px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray_700 md:hover:bg-gray-100 md:hover:text-mirage md:dark:hover:bg-balticsea md:dark:hover:text-white",
          {
            "!text-gray-900 dark:!text-white md:bg-gray-100 md:dark:bg-balticsea":
              pathname?.startsWith(sidebarItem?.path || "") && !!sidebarItem?.path?.slice(1),
          }
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="flex min-w-[1.5rem] flex-shrink-0 items-center justify-center">
              <span className="vendors_icon inline-flex">
                {getSidebarItemIcon(sidebarItem?.label)}
              </span>
            </div>
            <span className="ml-3">{sidebarItem.label}</span>
          </div>
          {sidebarItem?.isBeta ? (
            <div className="flex items-center rounded-md bg-transparent px-1.5 py-0.5 outline outline-1 outline-santagrey dark:outline-gray_400">
              <h4 className="font-inter-regular text-xs font-normal text-aurometalsaurus dark:text-battleshipgray">
                Beta
              </h4>
            </div>
          ) : null}
        </div>
        {sidebarItem?.items ? (
          <svg
            sidebar-toggle-item=""
            className={clsx("ml-auto h-6 w-6", {
              "rotate-180": sidebarItem.isOpen,
            })}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        ) : null}
      </Link>

      {sidebarItem?.items ? (
        <ul
          id="dropdown-example"
          className={clsx(
            { hidden: !sidebarItem.isOpen, block: sidebarItem.isOpen },
            styles.sidebarSubMenu,
            "md:space-y-1.5 md:py-2"
          )}
        >
          {sidebarItem.items.map((item: ItemTypes, itemIndex: number) => (
            <li key={itemIndex} className="item-center flex pl-8">
              <Link
                to={`${item?.path || pathname}`}
                className={clsx(
                  "light:text-white light:hover:bg-gray-700 group flex w-full  items-center border-antiflashwhite px-3 py-1 text-sm font-medium text-gray-700 transition duration-75 hover:bg-gray-100 dark:text-greychateau dark:hover:bg-balticsea dark:hover:text-white md:rounded-lg md:py-1 maxMd:border-b maxMd:dark:border-thunders",
                  {
                    "!text-gray-900 dark:!text-white md:bg-gray-100 md:dark:bg-balticsea":
                      pathname?.includes(item?.path as string),
                  }
                )}
                onClick={() => {
                  !pathname?.includes(item?.path as string) && setIsSideMenuShown(false);
                  onLinkClick();
                }}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}
