import { create } from "zustand";

import * as Sentry from "@sentry/react";

import { MY_ACCOUNT } from "shared/helpers/constant";

import {
  PermissionProps,
  ProviderResponseProps,
  SSOGroupMappingResponseProps,
} from "modules/settings/authentication/types";
import { TeamResponse } from "modules/settings/teams/types";
import { Plans } from "modules/settings/upgrade/types";

interface Settings {
  screen: string;
  setScreen: (screen: string) => void;
  sidebarTitle: string;
  setSidebarTitle: (sidebarTitle: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isManageModalOpen: boolean;
  setIsManageModalOpen: (isManageModalOpen: boolean) => void;
}

interface Team {
  teams: TeamResponse[];
  setTeams: (team: TeamResponse[]) => void;
  isFiltered: boolean;
  setIsFiltered: (isFiltered: boolean) => void;
  filteredTeam: TeamResponse[];
  setFilteredTeam: (filteredTeam: TeamResponse[]) => void;
  currentTeam: TeamResponse;
  setCurrentTeam: (team: TeamResponse) => void;
  selectedTeam: TeamResponse | null;
  setSelectedTeam: (team: TeamResponse | null) => void;
  isManageModalOpen: boolean;
  setIsManageModalOpen: (data: boolean) => void;
  activeTab: number;
  setActiveTab: (data: number) => void;

  taskTeam?: TeamResponse;
}

interface PlansProps {
  plans: Plans[];
  setPlans: (plans: Plans[]) => void;
}

type UpdateBillingDetailsProps = {
  isUpdateCardModalOpen: boolean;
  setIsUpdateCardModalOpen: (isUpdateCardModalOpen: boolean) => void;
  isUpdateEmailModalOpen: boolean;
  setIsUpdateEmailModalOpen: (isUpdateEmailModalOpen: boolean) => void;
  isUpdateAddressModalOpen: boolean;
  setIsUpdateAddressModalOpen: (isUpdateAddressModalOpen: boolean) => void;
  isUpdateVATorGSTModalOpen: boolean;
  setIsUpdateVATorGSTModalOpen: (isUpdateVATorGSTModalOpen: boolean) => void;
};

export const useSettings = create<Settings>((set) => ({
  screen: MY_ACCOUNT,
  setScreen: (screen: string) => set({ screen }),
  sidebarTitle: "Account",
  setSidebarTitle: (sidebarTitle: string) => set({ sidebarTitle }),
  isModalOpen: false,
  setIsModalOpen: (isModalOpen: boolean) => set({ isModalOpen }),
  isManageModalOpen: false,
  setIsManageModalOpen: (isManageModalOpen: boolean) => set({ isManageModalOpen }),
}));

export const useTeam = create<Team>((set) => ({
  teams: [],
  setTeams: (response: TeamResponse[]) => {
    if (response.length > 0) {
      const allTeam: TeamResponse = {
        id: 0,
        company: response[0]["company"],
        custom_permissions: {
          note: ["view", "add", "change", "delete", "comment", "permission"],
          risk: ["view", "add", "change", "delete", "comment", "permission"],
          task: ["view", "add", "change", "delete", "comment", "permission"],
          policy: ["view", "add", "change", "delete", "comment", "permission"],
          search: ["view", "add", "change", "delete", "comment", "permission"],
          article: ["view", "add", "change", "delete", "comment", "permission"],
          calendar: ["view", "add", "change", "delete", "comment", "permission"],
          document: ["view", "add", "change", "delete", "comment", "permission"],
          agreement: ["view", "add", "change", "delete", "comment", "permission"],
          dashboard: ["view", "add", "change", "delete", "comment", "permission"],
        },
        description: "Get data from all your teams",
        name: "All",
        is_common_team: false,
        is_general_team: false,
        is_all_team: true,
        members: [],
        team_picture: undefined,
      };
      const teams: TeamResponse[] = [allTeam, ...response];

      const taskTeam = teams.find((team) => team.name === "Task 1.5");
      set({ teams, taskTeam });
    } else {
      const teams: TeamResponse[] = response;
      const taskTeam = teams?.find((team) => team.name === "Task 1.5");

      set({ teams, taskTeam });
    }
  },
  isFiltered: false,
  setIsFiltered: (isFiltered: boolean) => set({ isFiltered }),
  filteredTeam: [],
  setFilteredTeam: (filteredTeam: TeamResponse[]) => set({ filteredTeam }),
  currentTeam: {} as TeamResponse,
  setCurrentTeam: (currentTeam: TeamResponse) => {
    try {
      if (currentTeam?.id) {
        Sentry.setContext("team", {
          id: currentTeam?.id,
          isCommonTeam: currentTeam?.is_common_team,
          isGeneralTeam: currentTeam?.is_general_team,
          permissions: currentTeam?.custom_permissions,
        });
      } else {
        Sentry.setContext("team", null);
      }
    } catch (error) {
      /* empty */
    }

    set({ currentTeam });
  },
  selectedTeam: null,
  setSelectedTeam: (selectedTeam: TeamResponse | null) => {
    try {
      if (selectedTeam?.id) {
        Sentry.setContext("team", {
          id: selectedTeam?.id,
          isCommonTeam: selectedTeam?.is_common_team,
          isGeneralTeam: selectedTeam?.is_general_team,
          permissions: selectedTeam?.custom_permissions,
        });
      } else {
        Sentry.setContext("team", null);
      }
    } catch (error) {
      /* empty */
    }

    set({ selectedTeam });
  },
  isManageModalOpen: false,
  setIsManageModalOpen: (isManageModalOpen: boolean) => set({ isManageModalOpen }),
  activeTab: 0,
  setActiveTab: (activeTab: number) => set({ activeTab }),
}));

export const usePlans = create<PlansProps>((set) => ({
  plans: [],
  setPlans: (plans: Plans[]) => set({ plans }),
}));

export const useUpdateCard = create<UpdateBillingDetailsProps>((set) => ({
  isUpdateCardModalOpen: false,
  setIsUpdateCardModalOpen: (isUpdateCardModalOpen: boolean) => set({ isUpdateCardModalOpen }),
  isUpdateEmailModalOpen: false,
  setIsUpdateEmailModalOpen: (isUpdateEmailModalOpen: boolean) => set({ isUpdateEmailModalOpen }),
  isUpdateAddressModalOpen: false,
  setIsUpdateAddressModalOpen: (isUpdateAddressModalOpen: boolean) =>
    set({ isUpdateAddressModalOpen }),
  isUpdateVATorGSTModalOpen: false,
  setIsUpdateVATorGSTModalOpen: (isUpdateVATorGSTModalOpen: boolean) =>
    set({ isUpdateVATorGSTModalOpen }),
}));

interface AuthProviderProps {
  provider: ProviderResponseProps | null;
  setProvider: (plans: ProviderResponseProps | null) => void;
  addProviderModal: boolean;
  setAddProviderModal: (addProviderModal: boolean) => void;
  group: SSOGroupMappingResponseProps | null;
  setGroup: (group: SSOGroupMappingResponseProps | null) => void;
}

export const useAuthProvider = create<AuthProviderProps>((set) => ({
  provider: null,
  setProvider: (provider: ProviderResponseProps | null) => set({ provider }),
  addProviderModal: false,
  setAddProviderModal: (addProviderModal: boolean) => set({ addProviderModal }),
  group: null,
  setGroup: (group: SSOGroupMappingResponseProps | null) => set({ group }),
}));

interface TeamMemberPermissionProps {
  memberPermission: PermissionProps | null;
  setMemberPermission: (memberPermission: PermissionProps | null) => void;
  teamMemberPermissionModal: boolean;
  setTeamMemberPermissionModal: (teamMemberPermissionModal: boolean) => void;
}

export const useTeamMemberPermission = create<TeamMemberPermissionProps>((set) => ({
  memberPermission: null,
  setMemberPermission: (memberPermission: PermissionProps | null) => set({ memberPermission }),
  teamMemberPermissionModal: false,
  setTeamMemberPermissionModal: (teamMemberPermissionModal: boolean) =>
    set({ teamMemberPermissionModal }),
}));
