/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LikelihoodLevelRequest
 */
export interface LikelihoodLevelRequest {
    /**
     * 
     * @type {number}
     * @memberof LikelihoodLevelRequest
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof LikelihoodLevelRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof LikelihoodLevelRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LikelihoodLevelRequest
     */
    minimumProbability?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LikelihoodLevelRequest
     */
    maximumProbability?: number | null;
}

/**
 * Check if a given object implements the LikelihoodLevelRequest interface.
 */
export function instanceOfLikelihoodLevelRequest(value: object): value is LikelihoodLevelRequest {
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function LikelihoodLevelRequestFromJSON(json: any): LikelihoodLevelRequest {
    return LikelihoodLevelRequestFromJSONTyped(json, false);
}

export function LikelihoodLevelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LikelihoodLevelRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'score': json['score'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'minimumProbability': json['minimum_probability'] == null ? undefined : json['minimum_probability'],
        'maximumProbability': json['maximum_probability'] == null ? undefined : json['maximum_probability'],
    };
}

export function LikelihoodLevelRequestToJSON(value?: LikelihoodLevelRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'score': value['score'],
        'label': value['label'],
        'description': value['description'],
        'minimum_probability': value['minimumProbability'],
        'maximum_probability': value['maximumProbability'],
    };
}

