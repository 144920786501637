/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface PatchedQuestionnaireCommentRequest
 */
export interface PatchedQuestionnaireCommentRequest {
    /**
     * 
     * @type {Array<Blob>}
     * @memberof PatchedQuestionnaireCommentRequest
     */
    attachments?: Array<Blob>;
    /**
     * 
     * @type {string}
     * @memberof PatchedQuestionnaireCommentRequest
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof PatchedQuestionnaireCommentRequest
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedQuestionnaireCommentRequest
     */
    questionnaire?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedQuestionnaireCommentRequest
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the PatchedQuestionnaireCommentRequest interface.
 */
export function instanceOfPatchedQuestionnaireCommentRequest(value: object): value is PatchedQuestionnaireCommentRequest {
    return true;
}

export function PatchedQuestionnaireCommentRequestFromJSON(json: any): PatchedQuestionnaireCommentRequest {
    return PatchedQuestionnaireCommentRequestFromJSONTyped(json, false);
}

export function PatchedQuestionnaireCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedQuestionnaireCommentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'attachments': json['attachments'] == null ? undefined : json['attachments'],
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'questionnaire': json['questionnaire'] == null ? undefined : json['questionnaire'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function PatchedQuestionnaireCommentRequestToJSON(value?: PatchedQuestionnaireCommentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attachments': value['attachments'],
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'questionnaire': value['questionnaire'],
        'mentions': value['mentions'],
    };
}

