/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `table` - Table
 * * `table_by_risk_area` - Table by Risk Area
 * * `matrix` - Matrix
 * * `matrix_with_table` - Matrix with Table
 * * `scatter_plot` - Scatter Plot
 * * `bar_chart` - Bar Chart
 * * `line_chart` - Line Chart
 * * `pie_chart` - Pie Chart
 * * `column_chart` - Column Chart
 * * `text` - Text
 * @export
 */
export const WidgetTypeEnum = {
    Table: 'table',
    TableByRiskArea: 'table_by_risk_area',
    Matrix: 'matrix',
    MatrixWithTable: 'matrix_with_table',
    ScatterPlot: 'scatter_plot',
    BarChart: 'bar_chart',
    LineChart: 'line_chart',
    PieChart: 'pie_chart',
    ColumnChart: 'column_chart',
    Text: 'text'
} as const;
export type WidgetTypeEnum = typeof WidgetTypeEnum[keyof typeof WidgetTypeEnum];


export function instanceOfWidgetTypeEnum(value: any): boolean {
    for (const key in WidgetTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(WidgetTypeEnum, key)) {
            if (WidgetTypeEnum[key as keyof typeof WidgetTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WidgetTypeEnumFromJSON(json: any): WidgetTypeEnum {
    return WidgetTypeEnumFromJSONTyped(json, false);
}

export function WidgetTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetTypeEnum {
    return json as WidgetTypeEnum;
}

export function WidgetTypeEnumToJSON(value?: WidgetTypeEnum | null): any {
    return value as any;
}

