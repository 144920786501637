/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `active` - Active
 * * `in_review` - In Review
 * * `in_negotiation` - In Negotiation
 * * `in_due_diligence` - In Due Diligence
 * * `onboarding` - Onboarding
 * * `offboarding` - Offboarding
 * * `terminated` - Terminated
 * @export
 */
export const VendorStatusEnum = {
    Active: 'active',
    InReview: 'in_review',
    InNegotiation: 'in_negotiation',
    InDueDiligence: 'in_due_diligence',
    Onboarding: 'onboarding',
    Offboarding: 'offboarding',
    Terminated: 'terminated'
} as const;
export type VendorStatusEnum = typeof VendorStatusEnum[keyof typeof VendorStatusEnum];


export function instanceOfVendorStatusEnum(value: any): boolean {
    for (const key in VendorStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(VendorStatusEnum, key)) {
            if (VendorStatusEnum[key as keyof typeof VendorStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VendorStatusEnumFromJSON(json: any): VendorStatusEnum {
    return VendorStatusEnumFromJSONTyped(json, false);
}

export function VendorStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorStatusEnum {
    return json as VendorStatusEnum;
}

export function VendorStatusEnumToJSON(value?: VendorStatusEnum | null): any {
    return value as any;
}

