/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `MONTHLY` - Monthly
 * * `QUARTERLY` - Quarterly
 * * `BI_ANNUALLY` - Bi-annually
 * * `ANNUALLY` - Annually
 * * `DAILY` - Daily
 * * `WEEKLY` - Weekly
 * @export
 */
export const ReviewScheduleEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    BiAnnually: 'BI_ANNUALLY',
    Annually: 'ANNUALLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY'
} as const;
export type ReviewScheduleEnum = typeof ReviewScheduleEnum[keyof typeof ReviewScheduleEnum];


export function instanceOfReviewScheduleEnum(value: any): boolean {
    for (const key in ReviewScheduleEnum) {
        if (Object.prototype.hasOwnProperty.call(ReviewScheduleEnum, key)) {
            if (ReviewScheduleEnum[key as keyof typeof ReviewScheduleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReviewScheduleEnumFromJSON(json: any): ReviewScheduleEnum {
    return ReviewScheduleEnumFromJSONTyped(json, false);
}

export function ReviewScheduleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewScheduleEnum {
    return json as ReviewScheduleEnum;
}

export function ReviewScheduleEnumToJSON(value?: ReviewScheduleEnum | null): any {
    return value as any;
}

