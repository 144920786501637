/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuestionnaireList } from './QuestionnaireList';
import {
    QuestionnaireListFromJSON,
    QuestionnaireListFromJSONTyped,
    QuestionnaireListToJSON,
} from './QuestionnaireList';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';
import type { QuestionnaireStatusEnum } from './QuestionnaireStatusEnum';
import {
    QuestionnaireStatusEnumFromJSON,
    QuestionnaireStatusEnumFromJSONTyped,
    QuestionnaireStatusEnumToJSON,
} from './QuestionnaireStatusEnum';
import type { Risk } from './Risk';
import {
    RiskFromJSON,
    RiskFromJSONTyped,
    RiskToJSON,
} from './Risk';
import type { PatchedQuestionnaireRequestQuestionnaireType } from './PatchedQuestionnaireRequestQuestionnaireType';
import {
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSON,
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSONTyped,
    PatchedQuestionnaireRequestQuestionnaireTypeToJSON,
} from './PatchedQuestionnaireRequestQuestionnaireType';
import type { Incident } from './Incident';
import {
    IncidentFromJSON,
    IncidentFromJSONTyped,
    IncidentToJSON,
} from './Incident';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';
import type { DocumentTypeEnum } from './DocumentTypeEnum';
import {
    DocumentTypeEnumFromJSON,
    DocumentTypeEnumFromJSONTyped,
    DocumentTypeEnumToJSON,
} from './DocumentTypeEnum';
import type { ArticleList } from './ArticleList';
import {
    ArticleListFromJSON,
    ArticleListFromJSONTyped,
    ArticleListToJSON,
} from './ArticleList';

/**
 * 
 * @export
 * @interface QuestionnaireDetail
 */
export interface QuestionnaireDetail {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    readonly id: number;
    /**
     * 
     * @type {Document}
     * @memberof QuestionnaireDetail
     */
    readonly document: Document | null;
    /**
     * 
     * @type {Array<Task>}
     * @memberof QuestionnaireDetail
     */
    readonly tasks: Array<Task> | null;
    /**
     * 
     * @type {Array<ArticleList>}
     * @memberof QuestionnaireDetail
     */
    readonly articles: Array<ArticleList> | null;
    /**
     * 
     * @type {Array<Risk>}
     * @memberof QuestionnaireDetail
     */
    readonly risks: Array<Risk> | null;
    /**
     * 
     * @type {Array<Incident>}
     * @memberof QuestionnaireDetail
     */
    readonly incidents: Array<Incident> | null;
    /**
     * 
     * @type {Array<Vendor>}
     * @memberof QuestionnaireDetail
     */
    readonly vendors: Array<Vendor> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireDetail
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<QuestionnaireList>}
     * @memberof QuestionnaireDetail
     */
    readonly questionnaireLists: Array<QuestionnaireList> | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireDetail
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireDetail
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireDetail
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDetail
     */
    description?: string | null;
    /**
     * 
     * @type {QuestionnaireStatusEnum}
     * @memberof QuestionnaireDetail
     */
    status?: QuestionnaireStatusEnum;
    /**
     * 
     * @type {PatchedQuestionnaireRequestQuestionnaireType}
     * @memberof QuestionnaireDetail
     */
    questionnaireType?: PatchedQuestionnaireRequestQuestionnaireType | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireDetail
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {DocumentTypeEnum}
     * @memberof QuestionnaireDetail
     */
    documentType?: DocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    associatedObjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    baseTemplate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    associatedObjectContentType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDetail
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof QuestionnaireDetail
     */
    owners?: Array<number>;
}



/**
 * Check if a given object implements the QuestionnaireDetail interface.
 */
export function instanceOfQuestionnaireDetail(value: object): value is QuestionnaireDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('document' in value) || value['document'] === undefined) return false;
    if (!('tasks' in value) || value['tasks'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('risks' in value) || value['risks'] === undefined) return false;
    if (!('incidents' in value) || value['incidents'] === undefined) return false;
    if (!('vendors' in value) || value['vendors'] === undefined) return false;
    if (!('questionnaireLists' in value) || value['questionnaireLists'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function QuestionnaireDetailFromJSON(json: any): QuestionnaireDetail {
    return QuestionnaireDetailFromJSONTyped(json, false);
}

export function QuestionnaireDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'document': DocumentFromJSON(json['document']),
        'tasks': (json['tasks'] == null ? null : (json['tasks'] as Array<any>).map(TaskFromJSON)),
        'articles': (json['articles'] == null ? null : (json['articles'] as Array<any>).map(ArticleListFromJSON)),
        'risks': (json['risks'] == null ? null : (json['risks'] as Array<any>).map(RiskFromJSON)),
        'incidents': (json['incidents'] == null ? null : (json['incidents'] as Array<any>).map(IncidentFromJSON)),
        'vendors': (json['vendors'] == null ? null : (json['vendors'] as Array<any>).map(VendorFromJSON)),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'questionnaireLists': (json['questionnaire_lists'] == null ? null : (json['questionnaire_lists'] as Array<any>).map(QuestionnaireListFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : QuestionnaireStatusEnumFromJSON(json['status']),
        'questionnaireType': json['questionnaire_type'] == null ? undefined : PatchedQuestionnaireRequestQuestionnaireTypeFromJSON(json['questionnaire_type']),
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'documentType': json['document_type'] == null ? undefined : DocumentTypeEnumFromJSON(json['document_type']),
        'associatedObjectId': json['associated_object_id'] == null ? undefined : json['associated_object_id'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'company': json['company'],
        'baseTemplate': json['base_template'] == null ? undefined : json['base_template'],
        'associatedObjectContentType': json['associated_object_content_type'] == null ? undefined : json['associated_object_content_type'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'owners': json['owners'] == null ? undefined : json['owners'],
    };
}

export function QuestionnaireDetailToJSON(value?: Omit<QuestionnaireDetail, 'id'|'document'|'tasks'|'articles'|'risks'|'incidents'|'vendors'|'questionnaire_lists'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'status': QuestionnaireStatusEnumToJSON(value['status']),
        'questionnaire_type': PatchedQuestionnaireRequestQuestionnaireTypeToJSON(value['questionnaireType']),
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'document_type': DocumentTypeEnumToJSON(value['documentType']),
        'associated_object_id': value['associatedObjectId'],
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
        'company': value['company'],
        'base_template': value['baseTemplate'],
        'associated_object_content_type': value['associatedObjectContentType'],
        'updated_by': value['updatedBy'],
        'owners': value['owners'],
    };
}

