/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LikelihoodLevelRequest } from './LikelihoodLevelRequest';
import {
    LikelihoodLevelRequestFromJSON,
    LikelihoodLevelRequestFromJSONTyped,
    LikelihoodLevelRequestToJSON,
} from './LikelihoodLevelRequest';
import type { RatingLevelRequest } from './RatingLevelRequest';
import {
    RatingLevelRequestFromJSON,
    RatingLevelRequestFromJSONTyped,
    RatingLevelRequestToJSON,
} from './RatingLevelRequest';
import type { RiskMatrixSettingsLogBase } from './RiskMatrixSettingsLogBase';
import {
    RiskMatrixSettingsLogBaseFromJSON,
    RiskMatrixSettingsLogBaseFromJSONTyped,
    RiskMatrixSettingsLogBaseToJSON,
} from './RiskMatrixSettingsLogBase';
import type { MatrixTypeEnum } from './MatrixTypeEnum';
import {
    MatrixTypeEnumFromJSON,
    MatrixTypeEnumFromJSONTyped,
    MatrixTypeEnumToJSON,
} from './MatrixTypeEnum';
import type { RiskAreaRequest } from './RiskAreaRequest';
import {
    RiskAreaRequestFromJSON,
    RiskAreaRequestFromJSONTyped,
    RiskAreaRequestToJSON,
} from './RiskAreaRequest';
import type { ImpactLevelRequest } from './ImpactLevelRequest';
import {
    ImpactLevelRequestFromJSON,
    ImpactLevelRequestFromJSONTyped,
    ImpactLevelRequestToJSON,
} from './ImpactLevelRequest';
import type { RiskTargetRequest } from './RiskTargetRequest';
import {
    RiskTargetRequestFromJSON,
    RiskTargetRequestFromJSONTyped,
    RiskTargetRequestToJSON,
} from './RiskTargetRequest';
import type { RiskTypeRequest } from './RiskTypeRequest';
import {
    RiskTypeRequestFromJSON,
    RiskTypeRequestFromJSONTyped,
    RiskTypeRequestToJSON,
} from './RiskTypeRequest';

/**
 * 
 * @export
 * @interface RiskMatrixSettingsRequest
 */
export interface RiskMatrixSettingsRequest {
    /**
     * 
     * @type {MatrixTypeEnum}
     * @memberof RiskMatrixSettingsRequest
     */
    matrixType?: MatrixTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettingsRequest
     */
    ratingLevelsAutoCalculate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettingsRequest
     */
    ratingLevelsAutoColor?: boolean;
    /**
     * 
     * @type {Array<ImpactLevelRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    impactLevels?: Array<ImpactLevelRequest>;
    /**
     * 
     * @type {Array<LikelihoodLevelRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    likelihoodLevels?: Array<LikelihoodLevelRequest>;
    /**
     * 
     * @type {Array<RatingLevelRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    ratingLevels?: Array<RatingLevelRequest>;
    /**
     * 
     * @type {Array<RiskTargetRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    riskTargets?: Array<RiskTargetRequest>;
    /**
     * 
     * @type {Array<RiskTypeRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    riskTypes?: Array<RiskTypeRequest>;
    /**
     * 
     * @type {Array<RiskAreaRequest>}
     * @memberof RiskMatrixSettingsRequest
     */
    riskAreas?: Array<RiskAreaRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettingsRequest
     */
    automaticallyCalculate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettingsRequest
     */
    numberOfRiskLevels?: number;
    /**
     * 
     * @type {RiskMatrixSettingsLogBase}
     * @memberof RiskMatrixSettingsRequest
     */
    logBase?: RiskMatrixSettingsLogBase | null;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixSettingsRequest
     */
    minimumFinancialImpact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixSettingsRequest
     */
    maximumFinancialImpact?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettingsRequest
     */
    minimumProbability?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettingsRequest
     */
    maximumProbability?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettingsRequest
     */
    minimumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettingsRequest
     */
    maximumFinancialImpactCurrency?: number | null;
}



/**
 * Check if a given object implements the RiskMatrixSettingsRequest interface.
 */
export function instanceOfRiskMatrixSettingsRequest(value: object): value is RiskMatrixSettingsRequest {
    return true;
}

export function RiskMatrixSettingsRequestFromJSON(json: any): RiskMatrixSettingsRequest {
    return RiskMatrixSettingsRequestFromJSONTyped(json, false);
}

export function RiskMatrixSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrixSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'matrixType': json['matrix_type'] == null ? undefined : MatrixTypeEnumFromJSON(json['matrix_type']),
        'ratingLevelsAutoCalculate': json['rating_levels_auto_calculate'] == null ? undefined : json['rating_levels_auto_calculate'],
        'ratingLevelsAutoColor': json['rating_levels_auto_color'] == null ? undefined : json['rating_levels_auto_color'],
        'impactLevels': json['impact_levels'] == null ? undefined : ((json['impact_levels'] as Array<any>).map(ImpactLevelRequestFromJSON)),
        'likelihoodLevels': json['likelihood_levels'] == null ? undefined : ((json['likelihood_levels'] as Array<any>).map(LikelihoodLevelRequestFromJSON)),
        'ratingLevels': json['rating_levels'] == null ? undefined : ((json['rating_levels'] as Array<any>).map(RatingLevelRequestFromJSON)),
        'riskTargets': json['risk_targets'] == null ? undefined : ((json['risk_targets'] as Array<any>).map(RiskTargetRequestFromJSON)),
        'riskTypes': json['risk_types'] == null ? undefined : ((json['risk_types'] as Array<any>).map(RiskTypeRequestFromJSON)),
        'riskAreas': json['risk_areas'] == null ? undefined : ((json['risk_areas'] as Array<any>).map(RiskAreaRequestFromJSON)),
        'automaticallyCalculate': json['automatically_calculate'] == null ? undefined : json['automatically_calculate'],
        'numberOfRiskLevels': json['number_of_risk_levels'] == null ? undefined : json['number_of_risk_levels'],
        'logBase': json['log_base'] == null ? undefined : RiskMatrixSettingsLogBaseFromJSON(json['log_base']),
        'minimumFinancialImpact': json['minimum_financial_impact'] == null ? undefined : json['minimum_financial_impact'],
        'maximumFinancialImpact': json['maximum_financial_impact'] == null ? undefined : json['maximum_financial_impact'],
        'minimumProbability': json['minimum_probability'] == null ? undefined : json['minimum_probability'],
        'maximumProbability': json['maximum_probability'] == null ? undefined : json['maximum_probability'],
        'minimumFinancialImpactCurrency': json['minimum_financial_impact_currency'] == null ? undefined : json['minimum_financial_impact_currency'],
        'maximumFinancialImpactCurrency': json['maximum_financial_impact_currency'] == null ? undefined : json['maximum_financial_impact_currency'],
    };
}

export function RiskMatrixSettingsRequestToJSON(value?: RiskMatrixSettingsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'matrix_type': MatrixTypeEnumToJSON(value['matrixType']),
        'rating_levels_auto_calculate': value['ratingLevelsAutoCalculate'],
        'rating_levels_auto_color': value['ratingLevelsAutoColor'],
        'impact_levels': value['impactLevels'] == null ? undefined : ((value['impactLevels'] as Array<any>).map(ImpactLevelRequestToJSON)),
        'likelihood_levels': value['likelihoodLevels'] == null ? undefined : ((value['likelihoodLevels'] as Array<any>).map(LikelihoodLevelRequestToJSON)),
        'rating_levels': value['ratingLevels'] == null ? undefined : ((value['ratingLevels'] as Array<any>).map(RatingLevelRequestToJSON)),
        'risk_targets': value['riskTargets'] == null ? undefined : ((value['riskTargets'] as Array<any>).map(RiskTargetRequestToJSON)),
        'risk_types': value['riskTypes'] == null ? undefined : ((value['riskTypes'] as Array<any>).map(RiskTypeRequestToJSON)),
        'risk_areas': value['riskAreas'] == null ? undefined : ((value['riskAreas'] as Array<any>).map(RiskAreaRequestToJSON)),
        'automatically_calculate': value['automaticallyCalculate'],
        'number_of_risk_levels': value['numberOfRiskLevels'],
        'log_base': RiskMatrixSettingsLogBaseToJSON(value['logBase']),
        'minimum_financial_impact': value['minimumFinancialImpact'],
        'maximum_financial_impact': value['maximumFinancialImpact'],
        'minimum_probability': value['minimumProbability'],
        'maximum_probability': value['maximumProbability'],
        'minimum_financial_impact_currency': value['minimumFinancialImpactCurrency'],
        'maximum_financial_impact_currency': value['maximumFinancialImpactCurrency'],
    };
}

