/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface QuestionnaireComment
 */
export interface QuestionnaireComment {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireComment
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireComment
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireComment
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireComment
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof QuestionnaireComment
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireComment
     */
    questionnaire: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof QuestionnaireComment
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the QuestionnaireComment interface.
 */
export function instanceOfQuestionnaireComment(value: object): value is QuestionnaireComment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('questionnaire' in value) || value['questionnaire'] === undefined) return false;
    return true;
}

export function QuestionnaireCommentFromJSON(json: any): QuestionnaireComment {
    return QuestionnaireCommentFromJSONTyped(json, false);
}

export function QuestionnaireCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'questionnaire': json['questionnaire'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function QuestionnaireCommentToJSON(value?: Omit<QuestionnaireComment, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'questionnaire': value['questionnaire'],
        'mentions': value['mentions'],
    };
}

