import { create } from "zustand";

import { DASHBOARDS_REPORTS_MODULE } from "shared/helpers/ability";

type Apps = "risks" | "questionnaires" | "thirdparty" | "reports" | "horizon-scanning" | "controls";

interface Module {
  currentModule: { id: number; name: string; app: Apps } | null;
  setCurrentModule: (data: { id: number; name: string; app: Apps } | null) => void;
}

interface SubModule {
  currentSubModule: { id?: number; name?: string } | null;
  setCurrentSubModule: (data: { id?: number; name?: string } | null) => void;
}

export const useCurrentModule = create<Module>((set) => ({
  currentModule: null,
  setCurrentModule: (currentModule) => {
    if (currentModule?.app === "reports" && currentModule.name === DASHBOARDS_REPORTS_MODULE) {
      currentModule.name = "dashboard";
    }

    set({ currentModule });
  },
}));

export const useCurrentSubModule = create<SubModule>((set) => ({
  currentSubModule: null,
  setCurrentSubModule: (currentSubModule: { id?: number; name?: string } | null) =>
    set({ currentSubModule }),
}));
