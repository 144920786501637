/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArtifactRequest
 */
export interface ArtifactRequest {
    /**
     * 
     * @type {string}
     * @memberof ArtifactRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArtifactRequest
     */
    evidence: number;
    /**
     * 
     * @type {string}
     * @memberof ArtifactRequest
     */
    url?: string | null;
    /**
     * 
     * @type {Blob}
     * @memberof ArtifactRequest
     */
    file?: Blob | null;
}

/**
 * Check if a given object implements the ArtifactRequest interface.
 */
export function instanceOfArtifactRequest(value: object): value is ArtifactRequest {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('evidence' in value) || value['evidence'] === undefined) return false;
    return true;
}

export function ArtifactRequestFromJSON(json: any): ArtifactRequest {
    return ArtifactRequestFromJSONTyped(json, false);
}

export function ArtifactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtifactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'evidence': json['evidence'],
        'url': json['url'] == null ? undefined : json['url'],
        'file': json['file'] == null ? undefined : json['file'],
    };
}

export function ArtifactRequestToJSON(value?: ArtifactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'evidence': value['evidence'],
        'url': value['url'],
        'file': value['file'],
    };
}

