/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { RiskControlStatusEnum } from './RiskControlStatusEnum';
import {
    instanceOfRiskControlStatusEnum,
    RiskControlStatusEnumFromJSON,
    RiskControlStatusEnumFromJSONTyped,
    RiskControlStatusEnumToJSON,
} from './RiskControlStatusEnum';

/**
 * @type ControlStatus
 * 
 * @export
 */
export type ControlStatus = BlankEnum | RiskControlStatusEnum;

export function ControlStatusFromJSON(json: any): ControlStatus {
    return ControlStatusFromJSONTyped(json, false);
}

export function ControlStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfRiskControlStatusEnum(json)) {
        return RiskControlStatusEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlStatusToJSON(value?: ControlStatus | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfRiskControlStatusEnum(value)) {
        return RiskControlStatusEnumToJSON(value as RiskControlStatusEnum);
    }

    return {};
}

