/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `BASE_E` - Base e
 * * `BASE_10` - Base 10 (Common Logarithm)
 * @export
 */
export const LogBaseEnum = {
    E: 'BASE_E',
    _10: 'BASE_10'
} as const;
export type LogBaseEnum = typeof LogBaseEnum[keyof typeof LogBaseEnum];


export function instanceOfLogBaseEnum(value: any): boolean {
    for (const key in LogBaseEnum) {
        if (Object.prototype.hasOwnProperty.call(LogBaseEnum, key)) {
            if (LogBaseEnum[key as keyof typeof LogBaseEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LogBaseEnumFromJSON(json: any): LogBaseEnum {
    return LogBaseEnumFromJSONTyped(json, false);
}

export function LogBaseEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogBaseEnum {
    return json as LogBaseEnum;
}

export function LogBaseEnumToJSON(value?: LogBaseEnum | null): any {
    return value as any;
}

