/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ApprovalStatusEnum } from './ApprovalStatusEnum';
import {
    instanceOfApprovalStatusEnum,
    ApprovalStatusEnumFromJSON,
    ApprovalStatusEnumFromJSONTyped,
    ApprovalStatusEnumToJSON,
} from './ApprovalStatusEnum';
import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';

/**
 * @type ControlApprovalStatus
 * 
 * @export
 */
export type ControlApprovalStatus = ApprovalStatusEnum | BlankEnum;

export function ControlApprovalStatusFromJSON(json: any): ControlApprovalStatus {
    return ControlApprovalStatusFromJSONTyped(json, false);
}

export function ControlApprovalStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlApprovalStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfApprovalStatusEnum(json)) {
        return ApprovalStatusEnumFromJSONTyped(json, true);
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlApprovalStatusToJSON(value?: ControlApprovalStatus | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfApprovalStatusEnum(value)) {
        return ApprovalStatusEnumToJSON(value as ApprovalStatusEnum);
    }
    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }

    return {};
}

