import {
  AGREEMENT_TABLE,
  ARTICLE_TABLE,
  DIRECTORIES,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  MITIGATION_TABLE,
  POLICY_TABLE,
  RISK_TABLE,
} from "shared/helpers/constant";
import { RISK } from "shared/helpersV2/constant";
import { GetTaskResultType } from "shared/types";

import { RelatedObjectTypeName } from "../types";

export default function getRelatedObjectTypeName(
  task: GetTaskResultType
): RelatedObjectTypeName | undefined {
  if (task.agreement) {
    return AGREEMENT_TABLE;
  }
  if (task.policy) {
    return POLICY_TABLE;
  }
  if (task.article) {
    return ARTICLE_TABLE;
  }
  if (task.document) {
    return DOCUMENT_TABLE;
  }
  if (task.risk) {
    return RISK_TABLE;
  }
  if (task.mitigation) {
    return MITIGATION_TABLE;
  }
  if (task.incident) {
    return INCIDENTS_TABLE;
  }
  if (task.directory) {
    return DIRECTORIES;
  }
  if (task.risk) {
    return RISK;
  }
  return undefined;
}
