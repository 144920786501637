import { UserRole } from "modules/home/types";

export const TokenKey = "Token";

export const RefreshTokenKey = "RefreshToken";

export const MY_ACCOUNT = "_myAccount";

export const NOTIFICATION = "_notification";

export const APPEARANCE = "_appearance";

export const COMPANY = "_companysetting";

export const AGREEMENT_REMINDERS = "_agreement_reminders";

export const AGREEMENTS = "_agreements";

export const INSTITUTION_TYPES = "_institution_types";

export const TEAMS = "_teams";

export const GROUPS = "_groups";

export const BILLING = "_billing";

export const MEMBERS = "_members";

export const UPGRADE = "_upgrade";

export const SETTING = "_setting";

export const AUTHENTICATION = "_authentication";

export const hideSettingsModuleWithEnterprise = [UPGRADE, BILLING];
export const showSettingsModuleWithEnterprise = [AUTHENTICATION];

export const IS_SIDEBAR_TOGGLE = "IsSidebarToggle";
export const IS_DARK_MODE = "IsDarkMode";

export const SEARCH_TYPE = "search";
export const SEARCH_SUGGESTION = "suggestions";
export const FILTER_TYPE = "filter";
export const FAVORITE_TYPE = "favorite";
export const VENDOR_LIST = "vendor-list-storage";

export const GRAND_COMPLIANCE_SCORE_CHART = "GRAND_COMPLIANCE_SCORE_CHART";
export const POLICIES_PER_STATUS_CHART = "POLICIES_PER_STATUS_CHART";
export const TASKS_PER_STATUS_CHART = "TASKS_PER_STATUS_CHART";
export const AGREEMENT_PER_STATUS_CHART = "AGREEMENT_PER_STATUS_CHART";
export const DUE_DATE_TABLE = "DUE_DATE_TABLE";
export const ASSIGNED_OBJECT_TABLE = "ASSIGNED_OBJECT_TABLE";
export const AGREEMENTS_WITH_WARNING_TABLE = "AGREEMENTS_WITH_WARNING_TABLE";
export const BOOKMARKS_TABLE = "BOOKMARKS_TABLE";
export const NUMBER_OF_WARNINGS_CHART = "NUMBER_OF_WARNINGS_CHART";

export const TEXT_FILTER = "TEXT_FILTER";
export const DROPDOWN_FILTER = "DROPDOWN_FILTER";
export const SMART_FILTER = "SMART_FILTER";
export const CHECKBOX_SELECTION_FILTER = "CHECKBOX_SELECTION_FILTER";
export const DROPDOWN_SELECTION_FILTER = "DROPDOWN_SELECTION_FILTER";
export const TOTAL_VALUE_AGREEMENT = "TOTAL_VALUE_AGREEMENT";
export const TYPEAHEAD_FILTER = "TYPEAHEAD_FILTER";
export const DATE_FILTER = "DATE_FILTER";
export const BOOLEAN_FILTER = "BOOLEAN_FILTER";
export const UNIT_VALUE_FILTER = "UNIT_VALUE_FILTER";
export const DATETIME_FILTER = "DATETIME_FILTER";
export const PREDEFINED_DATE_FILTER = "PREDEFINED_DATE_FILTER";
export const PAGINATED_FILTER = "PAGINATED_FILTER";

export const AFTER_OPERATOR = "after";
export const BEFORE_OPERATOR = "before";
export const ICONTAINS_OPERATOR = "icontains";
export const IN_OPERATOR = "in";
export const GTE_OPERATOR = "gte";
export const LTE_OPERATOR = "lte";
export const EXACT_OPERATOR = "exact";
export const EQUALS_OPERATOR = "eq";
export const TS_OPERATOR = "ts";

export type PredefinedType = "custom" | "this_week" | "this_month" | "last_7_days" | "last_30_days";
export type PredefinedFilterType = {
  key: PredefinedType;
  value: string;
};
export const PREDEFINED_FILTER_VALUES: PredefinedFilterType[] = [
  { key: "custom", value: "Custom" },
  { key: "this_week", value: "This Week" },
  { key: "this_month", value: "This Month" },
  { key: "last_7_days", value: "Last 7 Days" },
  { key: "last_30_days", value: "Last 30 Days" },
];

export const singleUnderscoreOperators = [AFTER_OPERATOR, BEFORE_OPERATOR];
export const preOrderOperators = [TS_OPERATOR];

// DONT CHANGE BELOW CONSTANT'S VALUE, IT IS USED IN FAVORITES.
export const AGREEMENT_TYPE_TABLE = "agreement_type";
export const AGREEMENT_CATEGORY_TABLE = "agreement_category";
export const INTERNAL_ENTITY_TABLE = "internal_entity";
export const AGREEMENT_TABLE = "agreement";
export const SUPPLIER_TABLE = "supplier";
export const AGREEMENT_VALIDATION_TABLE = "agreement_enterprise_validation";
export const ARTICLE_TABLE = "article";
export const SUBSCRIPTION_TABLE = "subscription";
export const DOCUMENT_TABLE = "document";
export const CATEGORY_TABLE = "category";
export const INSTRUCTION_TABLE = "instruction";
export const POLICY_TABLE = "policy";
export const POLICY_TEMPLATE_TABLE = "policy_template";
export const TASK_TABLE = "task";
export const TASK_STATUS_TABLE = "task_status";
export const MITIGATION_TABLE = "mitigation";
export const RISK_TABLE = "risk";
export const INCIDENTS_TABLE = "incident";
export const COMMUNITY_TABLE = "community";
export const REGISTER_TABLE = "risk";
export const VENDOR_TABLE = "vendor";
export const ONE_YEAR_PLAN_TABLE = "one_year_plan";
export const QUARTER_1 = "quarter_1";
export const QUARTER_2 = "quarter_2";
export const QUARTER_3 = "quarter_3";
export const QUARTER_4 = "quarter_4";
export const THREE_YEAR_PLAN_TABLE = "three_year_plan";
export const DIRECTORIES = "directory";

//--------------------------------------------------------------
// UPGRADE CONSTANTS

export const ACTIVE = "active";

export const TOGGLE_VIEW = "TOGGLE_VIEW";
export const TASK_VIEW = "TASK_VIEW";
export const TABLE_VIEW = "TABLE_VIEW";
export const GRID_VIEW = "GRID_VIEW";

//module type
export const AGREEMENT_TYPE = "agreement";
export const INCIDENT_TYPE = "incident";
export const POLICY_TYPE = "policy";

export type UploadableModuleType = typeof AGREEMENT_TYPE | typeof POLICY_TYPE;

/**
 * List of modules for which upload file is allowed
 */
export const UPLOADABLE_MODULE_LIST: UploadableModuleType[] = [AGREEMENT_TYPE, POLICY_TYPE];

export type PriorityType = "low" | "medium" | "high";
export type PriorityFilterType = {
  key: PriorityType;
  value: string;
};
export const PRIORITY_FILTER_VALUES: PriorityFilterType[] = [
  { key: "low", value: "Low" },
  { key: "medium", value: "Medium" },
  { key: "high", value: "High" },
];

//--------------------------------------------------------------
// SWITCH MODAL CONSTANT

export const COMMON = "common";

export const ADD_TASK_FORM = "add_task";
export const EDIT_TASK_FORM = "edit_task";

export const RELATED_AGREEEMENT_TYPE = "agreement";
export const RELATED_POLICY_TYPE = "policy";
export const RELATED_DOCUMENT_TYPE = "document";
export const RELATED_ARTICLE_TYPE = "article";

// -------------------------------------
// DEVICE TYPE

export const MOBILE_DEVICE = "mobile";
export const TABLET_DEVICE = "tablet";
export const DESKTOP_DEVICE = "desktop";
export const LAPTOP_DEVICE = "laptop";

export const REACT_APP_ENABLE_POC = "REACT_APP_ENABLE_POC";
export const REACT_APP_ENABLE_DASHBOARD = "REACT_APP_ENABLE_DASHBOARD";
export const REACT_APP_ENABLE_COMMUNITY = "REACT_APP_ENABLE_COMMUNITY";
export const REACT_APP_ENABLE_OBJECT_MODAL = "REACT_APP_ENABLE_OBJECT_MODAL";

// active path for different routes
export const subMenuOverview = ["gridview", "overview"];
export const datePickerSetToTop = ["publication_date", "updated_at", "created_at"];

export const GRAND_DOCUMENTATION_LINK = "https://intercom.help/grand-57d47019f0e0/en/";

// policy module
export const EMPTY_PARAGRAPH = " ";

// Metadata common type
export const AGREEMENT_METADATA = "agreements";
export const ARTICLE_METADATA = "articles";
export const TASK_METADATA = "tasks";
export const POLICY_METADATA = "policies";
export const MITIGATION_METADATA = "risks/mitigations";
export const INCIDENT_METADATA = "risks/incidents";
export const DOCUMENT_METADATA = "documents";
export const RISK_METADATA = "risks";
export const DIRECTORY_METADATA = "documents/directories";

// color code for input progress bar
export const VERY_LOW = "#057A55";
export const LOW = "#31C48D";
export const MODERATE = "#FACA15";
export const HIGH = "#FF5A1F";
export const VERY_HIGH = "#C81E1E";

export const VERY_LOW_RING = "#84E1BC";
export const LOW_RING = "#BCF0DA";
export const MODERATE_RING = "#FDF6B2";
export const HIGH_RING = "#FDBA8C";
export const VERY_HIGH_RING = "#F98080";

export const LEVEL_1 = "#3F83F8";
export const LEVEL_2 = "#057A55";
export const LEVEL_3 = "#31C48D";
export const LEVEL_4 = "#FACA15";
export const LEVEL_5 = "#E3A008";
export const LEVEL_6 = "#FF5A1F";
export const LEVEL_7 = "#C81E1E";
export const LEVEL_8 = "#E74694";
export const LEVEL_9 = "#9061F9";
export const LEVEL_10 = "#5145CD";

//ring color
export const LEVEL_1_RING = "#C3DDFD";
export const LEVEL_2_RING = "#84E1BC";
export const LEVEL_3_RING = "#BCF0DA";
export const LEVEL_4_RING = "#FDF6B2";
export const LEVEL_5_RING = "#FCE96A";
export const LEVEL_6_RING = "#FDBA8C";
export const LEVEL_7_RING = "#F98080";
export const LEVEL_8_RING = "#F8B4D9";
export const LEVEL_9_RING = "#CABFFD";
export const LEVEL_10_RING = "#8DA2FB";

// className for the input progress bar
export const VERY_LOW_CLASSNAME = "very_low";
export const LOW_CLASSNAME = "low";
export const MODERATE_CLASSNAME = "moderate";
export const HIGH_CLASSNAME = "high";
export const VERY_HIGH_CLASSNAME = "very_high";

// default Custom DropDown Access Options
export const options = [
  { icon: "edit-fill", name: "Edit", value: "EDITOR" },
  { icon: "message-new", name: "Comment", value: "COMMENTER" },
  { icon: "eye-v2", name: "View", value: "VIEWER" },
  { icon: "user", name: " Transfer ownership", value: "TRANSFER_OWNER" },
  { icon: "lock-new", name: "Remove access", value: "REMOVE_ACCESS" },
];

export const team_options = [
  { icon: "edit-fill", name: "Edit", value: "EDITOR" },
  { icon: "message-new", name: "Comment", value: "COMMENTER" },
  { icon: "eye-v2", name: "View", value: "VIEWER" },
  { icon: "lock-new", name: "Remove access", value: "REMOVE_ACCESS" },
];

export const ownerOptions = [{ name: "Owner", value: "OWNER" }];

// default Custom General  DropDown Access Options
export const general_access_options = [
  { icon: "lock", value: "RESTRICTED", name: "Restricted" },
  { icon: "edit", value: "EDITWITHLINK", name: "Anyone with a link can edit" },
  { icon: "message", value: "COMMENTWITHLINK", name: "Anyone with a link can comment" },
  { icon: "eye", value: "VIEWWITHLINK", name: "Any one with a link can view" },
];

// default value for the progressbar
export const DEFAULT_STEPS = 237;

// getting all data with pagination.
export const DEFAULT_LIMIT_PAGINATION = 10;

export const DEFAULT_PAGE_SIZE = 100;

// document module
export const MAXIMUM_FILE_SIZE_MB = 9.5;

// member roles
export const OWNER_ROLE = "OWNER";
export const ADMIN_ROLE = "ADMIN";
export const MEMBER_ROLE = "MEMBER";
export const GUEST_ROLE = "GUEST";

export const ROLES_LIST: Array<{ level: number; name: string; value: UserRole }> = [
  { level: 4, name: "Owner", value: OWNER_ROLE },
  { level: 3, name: "Admin", value: ADMIN_ROLE },
  { level: 2, name: "Member", value: MEMBER_ROLE },
  { level: 1, name: "Guest", value: GUEST_ROLE },
];

export const CREATE_ACCESS_ROLE_LEVEL = 3;
export const INITIAL_ROLE_LEVEL = 1;

export const DEFAULT_GROUP_MEMBER = {
  email: { label: "", value: 0, data: null },
  role: "",
};
