// DeepSource ignore: JS-0440
import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { cloneDeep, forEach } from "lodash";
import { useWindowSize } from "usehooks-ts";

import { useMutation } from "@tanstack/react-query";

import gridStyle from "assets/css/task-gridview.module.css";
import styles from "assets/css/task.module.css";

import AddTaskComponent from "shared/components/add-task-component";
import CommonTable from "shared/components/common-table";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import Icon from "shared/components/icon/Icon";
import ServerErrors from "shared/components/server-errors";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import TasksCardView from "shared/components/tasks-card-view";
import { useCompany } from "shared/context/CompanyProvider";
import { tasksDownloadFields } from "shared/data/downloadFieldList";
import {
  DEFAULT_PAGE_SIZE,
  GRID_VIEW,
  TABLE_VIEW,
  TASK_TABLE,
  TOGGLE_VIEW,
} from "shared/helpers/constant";
import { getElementOffSetTop } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";
import { GetTaskResultType, TeamId } from "shared/types";

import { getTaskById } from "modules/tasks/overview/api";
import AddStatus from "modules/tasks/statuses/components/AddStatus";
import { TaskStatusResultType } from "modules/tasks/statuses/types";

import { useWorkspaceModal } from "../../../../shared/store/trial";
import { deleteTaskById, downloadFile, kanbanOrderUpdate, orderUpdateTaskStatus } from "../api";
import { TaskTitle } from "../components/TaskTitle";
import TaskFormModal from "../create/TaskFormModal";
import EditTaskModal from "../edit";
import useTaskGridView from "../hooks/useTaskGridView";
import { KanbanOrderResultType } from "../types";
import CurrentTaskModal from "../view/CurrentTaskModal";

const Kanban = () => {
  const setViewType = useTask((state) => state.setViewType);

  const currentTeam = useTeam((state) => state.currentTeam);
  const { currentCompany } = useCompany();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);

  const setIsWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const { height } = useWindowSize();

  const taskListRef = useRef<HTMLDivElement | null>(null);
  const taskListHeight = height - getElementOffSetTop(taskListRef.current) - 20;

  const taskContainer = useRef<HTMLDivElement | null>(null);
  const taskContainerHeight = height - getElementOffSetTop(taskContainer.current);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  const [toBeDeleted, setToBeDeleted] = useState<GetTaskResultType | null>(null);
  const [toBeEdited, setToBeEdited] = useState<GetTaskResultType | null>(null);

  const currentTask = useTask((state) => state.currentTask);
  const setCurrentTask = useTask((state) => state.setCurrentTask);

  const {
    hasStatusInOrder,
    taskList,
    setTaskList,
    columns,
    filters,
    actions,
    searchFields,
    isEditModalOpen,
    refetchTasks,
    setIsEditModalOpen,
    setStatusId,
    statusId,
    isLoading,
    statuses,
    refetchTasksData,
  } = useTaskGridView();

  const { isLoading: isDownloadLoading, mutate: downloadDocuments } = useMutation(
    ({ fields, output_format }: { fields: string; output_format: string }) =>
      downloadFile({
        fields,
        output_format,
        teamId: currentTeam?.id,
      }),
    {
      onSuccess: (res, validators) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `tasks.${validators?.output_format.toLowerCase() === "csv" ? "csv" : "xls"}`
        );
        document.body.appendChild(link);
        link.click();
      },
      onError: (error: AxiosError) => {
        toast(error?.message || "Something went wrong", {
          type: "error",
        });
      },
    }
  );

  const {
    isLoading: isTaskStatusChange,
    mutateAsync: onTaskChangeOrder,
    isError: hasKanbanOrderError,
    error: kanbanOrderError,
  } = useMutation(
    (data: {
      requestOrder?: KanbanOrderResultType;
      team: TeamId;
      status: number | string;
      order: Array<number>;
    }) => {
      return kanbanOrderUpdate({
        ...data,
        permission: { team: data.team },
      });
    },
    {
      onSuccess: () => {
        refetchTasksData();
      },
      onError: (error: AxiosError) => {
        toast(error?.message || "Something went wrong", {
          type: "error",
        });
        return error;
      },
    }
  );

  const {
    isLoading: isOrderChange,
    mutateAsync: onChangeOrder,
    isError: hasOrderError,
    error: orderError,
  } = useMutation(
    (data: {
      team: number | string;
      statusFrom: number | string;
      taskIdsFrom: number[];
      statusTo: number | string;
      taskIdsTo: number[];
      requestFrom?: KanbanOrderResultType;
      requestTo?: KanbanOrderResultType;
      taskId: number;
      data: { [key: string]: string };
      companyId: number;
    }) => {
      return orderUpdateTaskStatus(data);
    },
    {
      onSuccess: (res) => {
        refetchTasksData();
        toast(
          `Moved to ${
            statuses?.find((status) => status?.id?.toString() === res?.data?.status?.toString())
              ?.name || ""
          }`,
          { type: "success" }
        );
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const {
    isLoading: isDeleting,
    mutateAsync: deleteTask,
    isError: hasDeleteError,
    error: deleteError,
  } = useMutation(
    ({ taskId, teamId }: { taskId: number; teamId: number }) =>
      deleteTaskById({ taskId }, { team: teamId }),
    {
      onSuccess: () => {
        refetchTasksData();
        toast("Task deleted successfully.", { type: "success" });
        setIsDeleteModalOpen(false);
      },
      onError: (e: AxiosError): AxiosError => {
        toast("Something went wrong", { type: "error" });
        return e;
      },
    }
  );

  const setList = (statusId: number, tasks: GetTaskResultType[]) => {
    setTaskList((_filteredTask) => {
      return _filteredTask.map((kanban) => {
        if (kanban?.id === statusId) {
          const newStatus = cloneDeep(kanban);
          newStatus.tasks = tasks;
          return newStatus;
        }
        return kanban;
      });
    });
  };

  const editTask = (task: GetTaskResultType) => {
    setToBeEdited({
      ...task,
      tags: task.tags_object?.map((tag) => tag.name) || task.tags,
    });
  };

  const onDelete = (task: GetTaskResultType) => {
    setToBeDeleted(task);
    setIsDeleteModalOpen(true);
  };

  const onCardClick = (task: GetTaskResultType) => {
    setViewType(TOGGLE_VIEW);
    getTaskById({
      taskId: task.id,
      teamId: currentTeam.id,
      companyId: currentCompany.id,
    }).then((response) => {
      setCurrentTask(response?.data);
      setIsViewModalOpen(true);
    });
  };

  return (
    <>
      {hasDeleteError || hasKanbanOrderError || hasOrderError ? (
        <ServerErrors className="mb-4" error={deleteError || orderError || kanbanOrderError} />
      ) : null}
      {isLoading || isDownloadLoading || isDeleting || isOrderChange || isTaskStatusChange ? (
        <CentralSpinner />
      ) : null}
      <TaskTitle
        setIsModalOpen={setIsEditModalOpen}
        callback={() => setStatusId((statuses && statuses[0]?.id) || 0)}
      />
      <CommonTable<TaskStatusResultType>
        columns={columns}
        data={{
          page: 1,
          total_pages: 1,
          count: taskList.length,
          next: null,
          previous: null,
          results: taskList,
          page_size: DEFAULT_PAGE_SIZE,
        }}
        filters={filters}
        actions={actions}
        refetch={refetchTasks}
        searchFields={searchFields}
        downloadFn={(fields: string, output_format: string) => {
          downloadDocuments({ fields, output_format });
        }}
        downloadFields={tasksDownloadFields}
        name={TASK_TABLE}
        displayColumnSelection={false}
        displayPagination={false}
        displayFavorites={!currentTeam.is_all_team}
      >
        {(table, viewType) => (
          <>
            {viewType === TABLE_VIEW ? <Navigate to="/tasks/overview" /> : null}
            {viewType === GRID_VIEW ? (
              <div className={gridStyle.task_wrapper}>
                <div className="min-w-full  align-middle">
                  <div
                    ref={taskContainer}
                    style={{ height: taskContainerHeight }}
                    className={clsx(gridStyle.taskContainer)}
                  >
                    {taskList?.map((item, index) => {
                      return (
                        <div key={index} id={item?.id + ""} data-status-id={item?.id}>
                          <div key={item?.id} id={`kanban-list-${item?.id}`}>
                            <div className={clsx(styles.kanban_col)}>
                              <h3>{item.name}</h3>
                              <AddTaskComponent
                                label="Add another task"
                                icon={<Icon type="plus" fill={true} size="icon-smd" />}
                                onClick={() => {
                                  if (currentCompany?.trial_expired) {
                                    setIsWorkspaceModalOpen(true);
                                  } else {
                                    setStatusId(item?.id);
                                    setIsEditModalOpen(true);
                                  }
                                }}
                              />
                            </div>
                            <div
                              className={gridStyle.taskList}
                              ref={taskListRef}
                              style={{ height: taskListHeight }}
                              data-status-id={item?.id}
                            >
                              <ReactSortable
                                filter=".addButton"
                                list={item.tasks || []}
                                onUpdate={(ev, sortable) => {
                                  const currentTaskObj = taskList
                                    ?.find(
                                      (item: TaskStatusResultType) =>
                                        item?.id?.toString() ===
                                        ev.item.getAttribute("data-status-id")?.toString()
                                    )
                                    ?.tasks?.find(
                                      (task) =>
                                        task.id?.toString() ===
                                        ev.item.getAttribute("data-task-id")?.toString()
                                    );

                                  const orderArray: number[] = [];
                                  forEach(
                                    document.getElementById(
                                      `kanban-list-${sortable?.options?.dataIdAttr}`
                                    )?.children[1].children[0].children,
                                    (children) => {
                                      orderArray?.push(
                                        parseInt(children?.getAttribute("data-task-id") || "") || 0
                                      );
                                    }
                                  );
                                  onTaskChangeOrder({
                                    requestOrder: hasStatusInOrder(
                                      sortable?.options?.dataIdAttr || "0"
                                    ),
                                    status: sortable?.options?.dataIdAttr || "0",
                                    order: orderArray,
                                    team: currentTaskObj?.team || 0,
                                  });
                                }}
                                setList={(data) => {
                                  setList(item?.id, data);
                                }}
                                dataIdAttr={`${item?.id}`}
                                onAdd={async (ev, sortable) => {
                                  const currentTaskObj = taskList
                                    ?.find(
                                      (item: TaskStatusResultType) =>
                                        item?.id?.toString() ===
                                        ev.item.getAttribute("data-status-id")?.toString()
                                    )
                                    ?.tasks?.find(
                                      (task) =>
                                        task.id?.toString() ===
                                        ev.item.getAttribute("data-task-id")?.toString()
                                    );
                                  const fromArray: number[] = [];
                                  const toArray: number[] = [];

                                  forEach(
                                    document.getElementById(
                                      `kanban-list-${sortable?.options?.dataIdAttr}`
                                    )?.children[1].children[0].children,
                                    (children) => {
                                      toArray?.push(
                                        parseInt(children?.getAttribute("data-task-id") || "") || 0
                                      );
                                    }
                                  );

                                  forEach(
                                    document.getElementById(
                                      `kanban-list-${currentTaskObj?.status?.id}`
                                    )?.children[1].children[0].children,
                                    (children) => {
                                      fromArray?.push(
                                        parseInt(children?.getAttribute("data-task-id") || "") || 0
                                      );
                                    }
                                  );
                                  onChangeOrder({
                                    team: parseInt(currentTaskObj?.team?.toString() || "0") || 0,
                                    statusTo: sortable?.options?.dataIdAttr || "0",
                                    taskIdsTo: toArray,
                                    statusFrom: currentTaskObj?.status?.id || "0",
                                    taskIdsFrom: fromArray,
                                    taskId: parseInt(currentTaskObj?.id?.toString() || "0") || 0,
                                    companyId: parseInt(currentCompany?.id?.toString() || "0") || 0,
                                    data: {
                                      status: sortable?.options?.dataIdAttr || "",
                                    },
                                    requestFrom: hasStatusInOrder(
                                      currentTaskObj?.status?.id || "0"
                                    ),
                                    requestTo: hasStatusInOrder(
                                      sortable?.options?.dataIdAttr || "0"
                                    ),
                                  });
                                }}
                                animation={200}
                                group="shared"
                                style={{ height: taskListHeight }}
                              >
                                {item?.tasks?.map((task: GetTaskResultType) => {
                                  return (
                                    <>
                                      <TasksCardView
                                        task={task}
                                        onEdit={() => editTask(task)}
                                        onDelete={() => onDelete(task)}
                                        onCardClick={() => {
                                          setStatusId(Number(task?.status?.id));
                                          onCardClick(task);
                                        }}
                                      />
                                    </>
                                  );
                                })}
                              </ReactSortable>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className={styles.kanban_col}>
                      <div>Add another group</div>
                      <button
                        onClick={() => setIsStatusModalOpen(true)}
                        type="button"
                        data-modal-toggle="new-card-modal"
                        className={styles.addStatusButton}
                      >
                        <Icon type="plus" fill={true} size="icon-md" className="custom" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <ConfirmModal
              isOpen={isDeleteModalOpen}
              setIsOpen={setIsDeleteModalOpen}
              action={() => {
                deleteTask({
                  taskId: toBeDeleted?.id || 0,
                  teamId: toBeDeleted?.team || 0,
                });
                table.toggleAllRowsSelected(false);
              }}
            />
          </>
        )}
      </CommonTable>
      <TaskFormModal
        isModalOpen={isEditModalOpen}
        statusId={statusId}
        statuses={statuses}
        teamId={currentTeam?.id}
        onCreate={() => {
          setIsEditModalOpen(false);
          refetchTasksData();
        }}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
      />
      <AddStatus
        isStatusModalOpen={isStatusModalOpen}
        setIsStatusModalOpen={setIsStatusModalOpen}
        refetchStatus={refetchTasksData}
      />
      {isViewModalOpen ? (
        <CurrentTaskModal
          isViewModalOpen={isViewModalOpen}
          onEdit={() => {
            if (currentTask) {
              editTask(currentTask);
            }
            setIsViewModalOpen(false);
          }}
          onClose={(state) => {
            setIsViewModalOpen(state);
          }}
        />
      ) : null}
      {toBeEdited ? (
        <EditTaskModal
          isOpen={true}
          task={toBeEdited}
          teamId={currentTeam.id}
          onClose={() => {
            setIsViewModalOpen(false);
            setToBeEdited(null);
          }}
          onUpdated={() => {
            setToBeEdited(null);
            setIsViewModalOpen(false);
            refetchTasksData();
          }}
        />
      ) : null}
    </>
  );
};

export default Kanban;
