import { RelatedObjectTypeName } from "modules/tasks/overview/types";

import { HORIZON_SCANNING, RISK } from "../helpersV2/constant";
import {
  AGREEMENT_TABLE,
  ARTICLE_TABLE,
  DIRECTORIES,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  MITIGATION_TABLE,
  POLICY_TABLE,
  RISK_TABLE,
} from "./constant";

export const getModuleURL = (
  id: number,
  type: RelatedObjectTypeName | undefined,
  company?: number,
  list?: number,
  feedId?: number
): string => {
  switch (type) {
    case AGREEMENT_TABLE:
      return `/agreements/overview/${id}/view`;
    case ARTICLE_TABLE:
      return `/articles/overview/${id}`;
    case POLICY_TABLE:
      return `/policies/overview/${id}/view`;
    case DOCUMENT_TABLE:
      return `/document/${id}`;
    case RISK_TABLE:
      return `/risks/register/${id}/view`;
    case MITIGATION_TABLE:
      return `/risks/mitigations/${id}/view`;
    case INCIDENTS_TABLE:
      return `/risks/incidents/${id}/view`;
    case DIRECTORIES:
      return `/documents/${id}`;
    case RISK:
      return `/workspace/${company}/risks/registers/${list}/risks/${id}/edit`;
    case HORIZON_SCANNING:
      return `/workspace/${company}/horizon-scanning/feeds/${feedId}/article/${id}`;
    default:
      return `/`;
  }
};
