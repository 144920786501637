/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { ControlAreaEnum } from './ControlAreaEnum';
import {
    instanceOfControlAreaEnum,
    ControlAreaEnumFromJSON,
    ControlAreaEnumFromJSONTyped,
    ControlAreaEnumToJSON,
} from './ControlAreaEnum';

/**
 * @type ControlRequestControlArea
 * 
 * @export
 */
export type ControlRequestControlArea = BlankEnum | ControlAreaEnum;

export function ControlRequestControlAreaFromJSON(json: any): ControlRequestControlArea {
    return ControlRequestControlAreaFromJSONTyped(json, false);
}

export function ControlRequestControlAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequestControlArea {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfControlAreaEnum(json)) {
        return ControlAreaEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlRequestControlAreaToJSON(value?: ControlRequestControlArea | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfControlAreaEnum(value)) {
        return ControlAreaEnumToJSON(value as ControlAreaEnum);
    }

    return {};
}

