/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskMatrixSettings } from './RiskMatrixSettings';
import {
    RiskMatrixSettingsFromJSON,
    RiskMatrixSettingsFromJSONTyped,
    RiskMatrixSettingsToJSON,
} from './RiskMatrixSettings';
import type { PatchedRiskRegisterRequestReviewSchedule } from './PatchedRiskRegisterRequestReviewSchedule';
import {
    PatchedRiskRegisterRequestReviewScheduleFromJSON,
    PatchedRiskRegisterRequestReviewScheduleFromJSONTyped,
    PatchedRiskRegisterRequestReviewScheduleToJSON,
} from './PatchedRiskRegisterRequestReviewSchedule';

/**
 * 
 * @export
 * @interface RiskRegisterItem
 */
export interface RiskRegisterItem {
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterItem
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterItem
     */
    objectId: number;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterItem
     */
    objectType?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskRegisterItem
     */
    risks: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof RiskRegisterItem
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterItem
     */
    readonly objectIsEditable: string;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterItem
     */
    description?: string | null;
    /**
     * 
     * @type {PatchedRiskRegisterRequestReviewSchedule}
     * @memberof RiskRegisterItem
     */
    reviewSchedule?: PatchedRiskRegisterRequestReviewSchedule | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterItem
     */
    readonly objectOwner: string;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterItem
     */
    readonly objectOwnerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterItem
     */
    company: number;
    /**
     * 
     * @type {Date}
     * @memberof RiskRegisterItem
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {RiskMatrixSettings}
     * @memberof RiskRegisterItem
     */
    matrixSettings: RiskMatrixSettings;
}

/**
 * Check if a given object implements the RiskRegisterItem interface.
 */
export function instanceOfRiskRegisterItem(value: object): value is RiskRegisterItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('objectId' in value) || value['objectId'] === undefined) return false;
    if (!('risks' in value) || value['risks'] === undefined) return false;
    if (!('objectIsEditable' in value) || value['objectIsEditable'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('objectOwner' in value) || value['objectOwner'] === undefined) return false;
    if (!('objectOwnerId' in value) || value['objectOwnerId'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('matrixSettings' in value) || value['matrixSettings'] === undefined) return false;
    return true;
}

export function RiskRegisterItemFromJSON(json: any): RiskRegisterItem {
    return RiskRegisterItemFromJSONTyped(json, false);
}

export function RiskRegisterItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskRegisterItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'objectId': json['object_id'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'risks': json['risks'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'objectIsEditable': json['object_is_editable'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'reviewSchedule': json['review_schedule'] == null ? undefined : PatchedRiskRegisterRequestReviewScheduleFromJSON(json['review_schedule']),
        'objectOwner': json['object_owner'],
        'objectOwnerId': json['object_owner_id'],
        'company': json['company'],
        'createdAt': (new Date(json['created_at'])),
        'matrixSettings': RiskMatrixSettingsFromJSON(json['matrix_settings']),
    };
}

export function RiskRegisterItemToJSON(value?: Omit<RiskRegisterItem, 'id'|'object_is_editable'|'object_owner'|'object_owner_id'|'created_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'object_type': value['objectType'],
        'risks': value['risks'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'review_schedule': PatchedRiskRegisterRequestReviewScheduleToJSON(value['reviewSchedule']),
        'company': value['company'],
        'matrix_settings': RiskMatrixSettingsToJSON(value['matrixSettings']),
    };
}

