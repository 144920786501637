/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `aml` - AML
 * * `outsourcing_arrangement` - Outsourcing Arrangement
 * * `cloud_service` - Cloud Service
 * * `accounting_and_auditing` - Accounting and Auditing
 * * `crm_systems` - CRM Systems
 * * `compliance_consulting` - Compliance Consulting
 * * `compliance_services` - Compliance Services
 * * `credit_rating` - Credit Rating
 * * `customer_support` - Customer Support
 * * `data_center_services` - Data Center Services
 * * `data_storage` - Data Storage
 * * `data_transmission` - Data Transmission
 * * `debt_collection` - Debt Collection
 * * `disaster_recovery` - Disaster Recovery
 * * `email_platforms` - Email Platforms
 * * `facility_management` - Facility Management
 * * `file_transfer` - File Transfer
 * * `financial_planning` - Financial Planning
 * * `financial_software` - Financial Software
 * * `fraud_detection` - Fraud Detection
 * * `hr_and_recruitment` - HR and Recruitment
 * * `iam_solutions` - IAM Solutions
 * * `it_managed_services` - IT Managed Services
 * * `it_support` - IT Support
 * * `insurance_services` - Insurance Services
 * * `investment_advisory` - Investment Advisory
 * * `legal_services` - Legal Services
 * * `market_research` - Market Research
 * * `marketing_analytics` - Marketing Analytics
 * * `network_management` - Network Management
 * * `payment_processing` - Payment Processing
 * * `public_relations` - Public Relations
 * * `regulatory_advisory` - Regulatory Advisory
 * * `risk_management_services` - Risk Management Services
 * * `security_monitoring` - Security Monitoring
 * * `software_development` - Software Development
 * * `tax_advisory` - Tax Advisory
 * * `training_and_development` - Training and Development
 * * `transaction_systems` - Transaction Systems
 * * `vpn_services` - VPN Services
 * * `vendor_management` - Vendor Management
 * * `web_hosting` - Web Hosting
 * @export
 */
export const BusinessEngagementTypeEnum = {
    Aml: 'aml',
    OutsourcingArrangement: 'outsourcing_arrangement',
    CloudService: 'cloud_service',
    AccountingAndAuditing: 'accounting_and_auditing',
    CrmSystems: 'crm_systems',
    ComplianceConsulting: 'compliance_consulting',
    ComplianceServices: 'compliance_services',
    CreditRating: 'credit_rating',
    CustomerSupport: 'customer_support',
    DataCenterServices: 'data_center_services',
    DataStorage: 'data_storage',
    DataTransmission: 'data_transmission',
    DebtCollection: 'debt_collection',
    DisasterRecovery: 'disaster_recovery',
    EmailPlatforms: 'email_platforms',
    FacilityManagement: 'facility_management',
    FileTransfer: 'file_transfer',
    FinancialPlanning: 'financial_planning',
    FinancialSoftware: 'financial_software',
    FraudDetection: 'fraud_detection',
    HrAndRecruitment: 'hr_and_recruitment',
    IamSolutions: 'iam_solutions',
    ItManagedServices: 'it_managed_services',
    ItSupport: 'it_support',
    InsuranceServices: 'insurance_services',
    InvestmentAdvisory: 'investment_advisory',
    LegalServices: 'legal_services',
    MarketResearch: 'market_research',
    MarketingAnalytics: 'marketing_analytics',
    NetworkManagement: 'network_management',
    PaymentProcessing: 'payment_processing',
    PublicRelations: 'public_relations',
    RegulatoryAdvisory: 'regulatory_advisory',
    RiskManagementServices: 'risk_management_services',
    SecurityMonitoring: 'security_monitoring',
    SoftwareDevelopment: 'software_development',
    TaxAdvisory: 'tax_advisory',
    TrainingAndDevelopment: 'training_and_development',
    TransactionSystems: 'transaction_systems',
    VpnServices: 'vpn_services',
    VendorManagement: 'vendor_management',
    WebHosting: 'web_hosting'
} as const;
export type BusinessEngagementTypeEnum = typeof BusinessEngagementTypeEnum[keyof typeof BusinessEngagementTypeEnum];


export function instanceOfBusinessEngagementTypeEnum(value: any): boolean {
    for (const key in BusinessEngagementTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(BusinessEngagementTypeEnum, key)) {
            if (BusinessEngagementTypeEnum[key as keyof typeof BusinessEngagementTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BusinessEngagementTypeEnumFromJSON(json: any): BusinessEngagementTypeEnum {
    return BusinessEngagementTypeEnumFromJSONTyped(json, false);
}

export function BusinessEngagementTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessEngagementTypeEnum {
    return json as BusinessEngagementTypeEnum;
}

export function BusinessEngagementTypeEnumToJSON(value?: BusinessEngagementTypeEnum | null): any {
    return value as any;
}

