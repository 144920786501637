/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `customer_data` - Customer Data
 * * `personal_information` - Personal Information
 * * `employee_data` - Employee Data
 * @export
 */
export const DataAccessEnum = {
    CustomerData: 'customer_data',
    PersonalInformation: 'personal_information',
    EmployeeData: 'employee_data'
} as const;
export type DataAccessEnum = typeof DataAccessEnum[keyof typeof DataAccessEnum];


export function instanceOfDataAccessEnum(value: any): boolean {
    for (const key in DataAccessEnum) {
        if (Object.prototype.hasOwnProperty.call(DataAccessEnum, key)) {
            if (DataAccessEnum[key as keyof typeof DataAccessEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DataAccessEnumFromJSON(json: any): DataAccessEnum {
    return DataAccessEnumFromJSONTyped(json, false);
}

export function DataAccessEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataAccessEnum {
    return json as DataAccessEnum;
}

export function DataAccessEnumToJSON(value?: DataAccessEnum | null): any {
    return value as any;
}

