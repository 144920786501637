/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `BASIC` - Basic
 * * `INTERMEDIATE` - Intermediate
 * * `ADVANCED` - Advanced
 * * `MATURE` - Mature
 * @export
 */
export const MaturityEnum = {
    Basic: 'BASIC',
    Intermediate: 'INTERMEDIATE',
    Advanced: 'ADVANCED',
    Mature: 'MATURE'
} as const;
export type MaturityEnum = typeof MaturityEnum[keyof typeof MaturityEnum];


export function instanceOfMaturityEnum(value: any): boolean {
    for (const key in MaturityEnum) {
        if (Object.prototype.hasOwnProperty.call(MaturityEnum, key)) {
            if (MaturityEnum[key as keyof typeof MaturityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MaturityEnumFromJSON(json: any): MaturityEnum {
    return MaturityEnumFromJSONTyped(json, false);
}

export function MaturityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaturityEnum {
    return json as MaturityEnum;
}

export function MaturityEnumToJSON(value?: MaturityEnum | null): any {
    return value as any;
}

