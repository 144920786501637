// TODO: add auto refresh token
import {
  AssistantApi,
  Configuration,
  ConfigurationParameters,
  ControlsApi,
  HorizonApi,
  QuestionnairesApi,
  ReportsApi,
  RisksApi,
  RoiApi,
} from "openapi";

import { getToken } from "shared/helpers/util";

import { refreshTokenMiddleware } from "./middleware";

class ApiV1 {
  baseUrl!: string;

  roi!: RoiApi;
  assistant!: AssistantApi;
  horizonScanning!: HorizonApi;
  questionnaire!: QuestionnairesApi;
  reports!: ReportsApi;
  risks!: RisksApi;
  controls!: ControlsApi;

  constructor(protected params: ConfigurationParameters) {
    const config = new Configuration({
      ...params,
      middleware: [refreshTokenMiddleware(params.basePath || "")],
    });
    this.baseUrl = config.basePath;

    this.roi = new RoiApi(config);
    this.assistant = new AssistantApi(config);
    this.horizonScanning = new HorizonApi(config);
    this.questionnaire = new QuestionnairesApi(config);
    this.reports = new ReportsApi(config);
    this.risks = new RisksApi(config);
    this.controls = new ControlsApi(config);
  }
}

export const client = new ApiV1({
  basePath: process.env.REACT_APP_BACKEND_URL,
  apiKey() {
    return `Bearer ${getToken()}`;
  },
});

export const clientPublic = new ApiV1({
  basePath: process.env.REACT_APP_BACKEND_URL,
});
