/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ONE_OFF` - One-off
 * * `CONTINUOUS` - Continuous
 * * `DAILY` - Daily
 * * `WEEKLY` - Weekly
 * * `MONTHLY` - Monthly
 * * `QUARTERLY` - Quarterly
 * * `YEARLY` - Yearly
 * @export
 */
export const ControlFrequencyEnum = {
    OneOff: 'ONE_OFF',
    Continuous: 'CONTINUOUS',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY'
} as const;
export type ControlFrequencyEnum = typeof ControlFrequencyEnum[keyof typeof ControlFrequencyEnum];


export function instanceOfControlFrequencyEnum(value: any): boolean {
    for (const key in ControlFrequencyEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlFrequencyEnum, key)) {
            if (ControlFrequencyEnum[key as keyof typeof ControlFrequencyEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlFrequencyEnumFromJSON(json: any): ControlFrequencyEnum {
    return ControlFrequencyEnumFromJSONTyped(json, false);
}

export function ControlFrequencyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFrequencyEnum {
    return json as ControlFrequencyEnum;
}

export function ControlFrequencyEnumToJSON(value?: ControlFrequencyEnum | null): any {
    return value as any;
}

