/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { ControlApprovalStatusEnum } from './ControlApprovalStatusEnum';
import {
    instanceOfControlApprovalStatusEnum,
    ControlApprovalStatusEnumFromJSON,
    ControlApprovalStatusEnumFromJSONTyped,
    ControlApprovalStatusEnumToJSON,
} from './ControlApprovalStatusEnum';

/**
 * @type ControlRequestApprovalStatus
 * 
 * @export
 */
export type ControlRequestApprovalStatus = BlankEnum | ControlApprovalStatusEnum;

export function ControlRequestApprovalStatusFromJSON(json: any): ControlRequestApprovalStatus {
    return ControlRequestApprovalStatusFromJSONTyped(json, false);
}

export function ControlRequestApprovalStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequestApprovalStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfControlApprovalStatusEnum(json)) {
        return ControlApprovalStatusEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlRequestApprovalStatusToJSON(value?: ControlRequestApprovalStatus | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfControlApprovalStatusEnum(value)) {
        return ControlApprovalStatusEnumToJSON(value as ControlApprovalStatusEnum);
    }

    return {};
}

