import { Link, useNavigate, useParams } from "react-router-dom";

import { Button } from "flowbite-react";
import { cn } from "lib/utils";
import { useDarkMode } from "usehooks-ts";

import { ArrowRightIcon, ArrowTopRightOnSquareIcon, ShareIcon } from "@heroicons/react/24/solid";

import style from "assets/css/articlesV2.module.css";
import logoTextOnlyWhite from "assets/images/logo-text-only-white.svg";
import logoTextOnly from "assets/images/logo-text-only.svg";

import Icon from "shared/components/icon/main";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";

import { ArticleProvider, useArticleContext } from "../contexts/ArticleContext";
import ArticleDetails from "./ArticleDetails";

const ArticleDetailsPublicPage = () => {
  const { isDarkMode } = useDarkMode();
  const { articleId } = useParams();
  const navigate = useNavigate();
  const deviceType = useScreen();

  if (deviceType === MOBILE_DEVICE) {
    document.querySelector("body")?.classList.add("overflow-y-auto");
  }

  return (
    <ArticleProvider isPublic={true} feedId={0} companyId={0} articleId={Number(articleId)}>
      <div className="mx-8 mt-[1rem] flex flex-wrap items-center justify-between gap-4">
        <div className=" cursor-pointer">
          <img
            src={isDarkMode ? logoTextOnlyWhite : logoTextOnly}
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="flex flex-wrap gap-4">
          <Button
            className="flex-grow"
            pill
            size="xs"
            color="dark"
            onClick={() => navigate("/signup")}
          >
            Sign up for free
          </Button>
          <Button
            className="flex-grow"
            pill
            size="xs"
            color="light"
            onClick={() => navigate("/signup")}
          >
            <ArrowTopRightOnSquareIcon className="mr-2 h-4 w-4" />
            Article from Grand's Horizon Scanning
          </Button>
        </div>
      </div>
      <div className="mt-8 flex flex-col items-center md:mt-[3.75rem]">
        <ArticleDetails />
        <div className={cn(style.article_details_grid, "!pt-0")}>
          <PublicButtons />
          <div className="w-full rounded-2xl bg-mirage px-8 py-10 text-white dark:border dark:border-jet dark:bg-darkjunglegreenss dark:text-lighthouse">
            <Icon name={isDarkMode ? "logo" : "logowhite"} className="!h-8 !w-8" />
            <h1 className="mb-2.5 mt-6 font-[GrandSansDisplay] text-2xl leading-8">
              Let’s make compliance fun again
            </h1>
            <p className="mb-6 max-w-[70ch] text-sm">
              We are reinventing GRC for the finance community. Combining AI-enabled solutions with
              human expertise in our next gen compliance platform.
            </p>
            <Button size="xs" color="light" onClick={() => navigate("/signup")}>
              Sign up for free
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </Button>
          </div>
          <div className="mb-12 flex items-center gap-2">
            <span className="text-sm dark:text-white">Visit us in</span>
            <div className="flex gap-2">
              <Link
                to="https://www.linkedin.com/company/grandcompliance/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" className="h-4 w-4" />
              </Link>
              <Link
                to="https://www.facebook.com/p/Grand-Compliance-100089344027128/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="facebook" className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ArticleProvider>
  );
};

export default ArticleDetailsPublicPage;

const PublicButtons = () => {
  const {
    articleQuery: { data: article },
    setShareModalArticle,
  } = useArticleContext();

  return (
    <div className="flex gap-4">
      <Button pill size="xs" color="light" onClick={() => setShareModalArticle(article)}>
        <ShareIcon className="mr-2 h-4 w-4" />
        Share
      </Button>
      <Button pill size="xs" color="light" href={article?.url} target="_blank">
        Read original post
      </Button>
    </div>
  );
};
