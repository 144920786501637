/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `UP_TO_DATE` - Up To Date
 * * `NEEDS_REVIEW` - Needs Review
 * * `INACTIVE` - Inactive
 * @export
 */
export const EvidenceStatusEnum = {
    UpToDate: 'UP_TO_DATE',
    NeedsReview: 'NEEDS_REVIEW',
    Inactive: 'INACTIVE'
} as const;
export type EvidenceStatusEnum = typeof EvidenceStatusEnum[keyof typeof EvidenceStatusEnum];


export function instanceOfEvidenceStatusEnum(value: any): boolean {
    for (const key in EvidenceStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(EvidenceStatusEnum, key)) {
            if (EvidenceStatusEnum[key as keyof typeof EvidenceStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EvidenceStatusEnumFromJSON(json: any): EvidenceStatusEnum {
    return EvidenceStatusEnumFromJSONTyped(json, false);
}

export function EvidenceStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceStatusEnum {
    return json as EvidenceStatusEnum;
}

export function EvidenceStatusEnumToJSON(value?: EvidenceStatusEnum | null): any {
    return value as any;
}

