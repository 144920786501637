import { useCompany } from "shared/context/CompanyProvider";

import { Module } from "modules/home/types";

import {
  AGREEMENT_MODULE,
  ALL_MODULE,
  ARTICLE_MODULE,
  ASSISTANT_MODULE,
  CALENDAR_MODULE,
  CONTROL_MODULE,
  DASHBOARDS_REPORTS_MODULE,
  DASHBOARD_MODULE,
  DOCUMENT_MODULE,
  HORIZON_SCANNING_MODULE,
  MITIGATION_MODULE,
  Modules,
  POLICY_MODULE,
  QUESTIONNAIRE_MODULE,
  REPORT_MODULE,
  RISK_MODULE,
  ROI_MODULE,
  SEARCH_MODULE,
  TASK_MODULE,
  TEAM_MODULE,
  USER_MODULE,
  VENDOR_MODULE,
} from "../helpers/ability";

const map = {
  [CALENDAR_MODULE]: "calendar",
  [ARTICLE_MODULE]: "articles",
  [TASK_MODULE]: "tasks",
  [DOCUMENT_MODULE]: "documents",
  [TEAM_MODULE]: "teams",
  [USER_MODULE]: "users",
  [SEARCH_MODULE]: "search",
  [POLICY_MODULE]: "policies",
  [ASSISTANT_MODULE]: "assistant",
  [RISK_MODULE]: "risks",
  [ROI_MODULE]: "roi",
  [DASHBOARD_MODULE]: "dashboard",
  [AGREEMENT_MODULE]: "agreements",
  [MITIGATION_MODULE]: "mitigations",
  [VENDOR_MODULE]: "thirdparty",
  [REPORT_MODULE]: "report",
  [QUESTIONNAIRE_MODULE]: "questionnaires",
  [DASHBOARDS_REPORTS_MODULE]: "dashboards-reports",
  [HORIZON_SCANNING_MODULE]: "horizonscanning",
  [CONTROL_MODULE]: "controls",
  [ALL_MODULE]: "all",
} as Record<Modules, string>;

const useModuleEnabled = () => {
  const { currentCompany } = useCompany();

  const checkEnabled = (module: Modules) => {
    const abilities = currentCompany?.modules;
    const parsedModule = Object.values(map).includes(module) ? module : map[module];

    if (module === RISK_MODULE) {
      return !!abilities?.risks?.enabled;
    }

    if (parsedModule === "thirdparty") {
      return !!abilities?.thirdparty?.enabled && !!abilities?.thirdparty?.vendors_enabled;
    } else if (parsedModule === "questionnaires") {
      return !!abilities?.questionnaires?.enabled;
    }
    return !!abilities?.[parsedModule as Module]?.enabled;
  };
  return { checkEnabled };
};

export default useModuleEnabled;
