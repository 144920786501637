/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PENDING` - Pending
 * * `APPROVED` - Approved
 * * `REJECTED` - Rejected
 * @export
 */
export const ApprovalStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;
export type ApprovalStatusEnum = typeof ApprovalStatusEnum[keyof typeof ApprovalStatusEnum];


export function instanceOfApprovalStatusEnum(value: any): boolean {
    for (const key in ApprovalStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(ApprovalStatusEnum, key)) {
            if (ApprovalStatusEnum[key as keyof typeof ApprovalStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ApprovalStatusEnumFromJSON(json: any): ApprovalStatusEnum {
    return ApprovalStatusEnumFromJSONTyped(json, false);
}

export function ApprovalStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovalStatusEnum {
    return json as ApprovalStatusEnum;
}

export function ApprovalStatusEnumToJSON(value?: ApprovalStatusEnum | null): any {
    return value as any;
}

