/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskTargetRequest
 */
export interface RiskTargetRequest {
    /**
     * 
     * @type {number}
     * @memberof RiskTargetRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RiskTargetRequest
     */
    name: string;
}

/**
 * Check if a given object implements the RiskTargetRequest interface.
 */
export function instanceOfRiskTargetRequest(value: object): value is RiskTargetRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function RiskTargetRequestFromJSON(json: any): RiskTargetRequest {
    return RiskTargetRequestFromJSONTyped(json, false);
}

export function RiskTargetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskTargetRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'],
    };
}

export function RiskTargetRequestToJSON(value?: RiskTargetRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

