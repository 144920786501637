import checkEnvVar from "shared/helpers/checkEnvVar";
import { DropdownItemProps } from "shared/typesV2";

export const statusBackgroundColourCode = [
  "bg-lavenderindigo",
  "bg-mountainMeadow",
  "bg-fadedOrange",
  "bg-gamboge",
  "bg-dodgerblue",
  "bg-valentinered",
  "bg-dogwoodRose",
  "bg-lovelyPurple",
  "bg-lavared",
  "bg-slate-600",
  "bg-crayolasblue",
  "bg-purpleHeart",
  "bg-tropicalRainForest",
  "bg-bondiBlue",
  "bg-dirtyOrange",
  "bg-egyptianBlue",
  "bg-glantsOrange",
  "bg-raspberryPink",
  "bg-greenhaze",
  "bg-cornflower",
];
export const DEFAULT_VIEW_TYPE = "table";

export const VIEW_FIELD = "view";

export const GROUP_BY_FIELD = "group_by";

export const DEFAULT_VENDOR_GROUPING = "status";

export const TABLE_VIEW = "table";
export const MATRIX_VIEW = "matrix";

export const BOARD_VIEW = "board";

export const LIST_VIEW = "list";

export const QUESTIONNAIRE_TABLE = "questionnaires";
export const VENDOR_TABLE = "vendor";
export const RISK_REGISTER_TABLE = "risk-register";
export const RISK_TABLE = "risk";
export const DASHBOARDS_REPORTS_TABLE = "reports";
export const HORIZON_SCANNING = "horizon-scanning";
export const CONTROLS_TABLE = "controls";
export const EVIDENCES_TABLE = "evidences";
export const SORT_KEY = "sort";
export const ASC_SORTING = "ASC";
export const DESC_SORTING = "DESC";

export const AGREEEMENT_TABLE = "agreements";
export const ASSESSMENT_TABLE = "assessments";
export const ASSESSMENT_ATTACHMENT_TABLE = "assessmentsAttachment";

export const AGREEMENT_ATTACHMENT_TABLE = "agreements-attachment";

export const dropdownData: DropdownItemProps[] = [
  {
    id: 1,
    icon: "arrow-right",
    iconFill: true,
    iconClassName: "theme_icon",
    title: "Export",
  },
  { id: 2, icon: "user-plus", iconFill: true, iconClassName: "theme_icon", title: "Share" },
  {
    id: 3,
    icon: "trash-new",
    iconFill: true,
    iconClassName: "theme_icon",
    title: "Delete",
  },
];

export const vendorDropdownDown: DropdownItemProps[] = [
  {
    id: 1,
    icon: "status",
    iconFill: false,
    iconClassName: "table_circle_icon",
    title: "Change Status",
  },
  {
    id: 2,
    icon: "square",
    iconFill: true,
    iconClassName: "theme_icon",
    title: "Change Business Criticality",
  },
  { id: 3, icon: "user", iconFill: false, iconClassName: "theme_icon", title: "Change Owner" },
  {
    id: 4,
    icon: "viewfinder-circle",
    iconFill: true,
    iconClassName: "theme_icon",
    title: "Add To List",
  },
  { id: 5, icon: "user-plus", iconFill: true, iconClassName: "theme_icon", title: "Share" },
  {
    id: 6,
    icon: "trash-new",
    iconFill: true,
    iconClassName: "theme_icon",
    title: "Delete",
  },
];
export const show_all_objects = checkEnvVar("REACT_APP_ENABLE_OBJECT_MODAL") || false;

/* Notification Object Type Props  */

export const AGREEMENT = "agreements_agreement";
export const ARTICLE = "articles_article";
export const POLICY = "policies_policy";
export const DOCUMENT = "documents_document";

export const MITIGATION = "risks_mitigation";
export const INCIDENT = "risks_incident";
export const TASK = "tasks_task";
export const ARTICLETEAMACTION = "articles_articleteamaction";
export const VENDOR = "thirdparty_vendor";
export const VENDOR_COMMENT = "thirdparty_vendorcomment";
export const VENDOR_ASSESSMENT = "thirdparty_vendorassessment";
export const VENDOR_AGREEMENT = "thirdparty_vendoragreement";
export const TEAM = "users_team";
export const OTHER = "other";
export const COMPANY_INVITATION = "companies_companyinvitation";
export const ARTICLE_COMMENT = "articles_articlecomment";
export const ASSESSMENT_COMMENT = "thirdparty_assessmentcomment";
export const DASHBOARD = "reports_dashboard";
export const QUESTIONNAIRE = "questionnaires_questionnaire";
export const RISK_REGISTER = "risks_riskregister";
export const RISK = "risks_risk";
export const RISK_COMMENT = "risks_riskcomment";
export const QUESTIONNAIRE_COMMENT = "questionnaires_questionnairecomment";
export const DASHBOARD_COMMENT = "reports_dashboardcomment";

/* Debounce time delay */
export const DEBOUNCE_DELAY = 300;
