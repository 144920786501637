/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { PatchedQuestionnaireRequestQuestionnaireType } from './PatchedQuestionnaireRequestQuestionnaireType';
import {
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSON,
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSONTyped,
    PatchedQuestionnaireRequestQuestionnaireTypeToJSON,
} from './PatchedQuestionnaireRequestQuestionnaireType';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';
import type { DocumentTypeEnum } from './DocumentTypeEnum';
import {
    DocumentTypeEnumFromJSON,
    DocumentTypeEnumFromJSONTyped,
    DocumentTypeEnumToJSON,
} from './DocumentTypeEnum';

/**
 * 
 * @export
 * @interface QuestionnaireTemplate
 */
export interface QuestionnaireTemplate {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplate
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireTemplate
     */
    document?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireTemplate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplate
     */
    company: number;
    /**
     * 
     * @type {Company}
     * @memberof QuestionnaireTemplate
     */
    readonly companyDetail: Company;
    /**
     * 
     * @type {Document}
     * @memberof QuestionnaireTemplate
     */
    readonly documentDetail: Document;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireTemplate
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireTemplate
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireTemplate
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {PatchedQuestionnaireRequestQuestionnaireType}
     * @memberof QuestionnaireTemplate
     */
    category?: PatchedQuestionnaireRequestQuestionnaireType | null;
    /**
     * 
     * @type {DocumentTypeEnum}
     * @memberof QuestionnaireTemplate
     */
    documentType?: DocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplate
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplate
     */
    directory?: number | null;
}



/**
 * Check if a given object implements the QuestionnaireTemplate interface.
 */
export function instanceOfQuestionnaireTemplate(value: object): value is QuestionnaireTemplate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('companyDetail' in value) || value['companyDetail'] === undefined) return false;
    if (!('documentDetail' in value) || value['documentDetail'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function QuestionnaireTemplateFromJSON(json: any): QuestionnaireTemplate {
    return QuestionnaireTemplateFromJSONTyped(json, false);
}

export function QuestionnaireTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'document': json['document'] == null ? undefined : json['document'],
        'name': json['name'],
        'company': json['company'],
        'companyDetail': CompanyFromJSON(json['company_detail']),
        'documentDetail': DocumentFromJSON(json['document_detail']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'category': json['category'] == null ? undefined : PatchedQuestionnaireRequestQuestionnaireTypeFromJSON(json['category']),
        'documentType': json['document_type'] == null ? undefined : DocumentTypeEnumFromJSON(json['document_type']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
    };
}

export function QuestionnaireTemplateToJSON(value?: Omit<QuestionnaireTemplate, 'id'|'company_detail'|'document_detail'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'document': value['document'],
        'name': value['name'],
        'company': value['company'],
        'object_is_public': value['objectIsPublic'],
        'category': PatchedQuestionnaireRequestQuestionnaireTypeToJSON(value['category']),
        'document_type': DocumentTypeEnumToJSON(value['documentType']),
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
    };
}

