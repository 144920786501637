/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThemeEnum } from './ThemeEnum';
import {
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * interface compatible with meili
 * @export
 * @interface DashboardItemList
 */
export interface DashboardItemList {
    /**
     * 
     * @type {number}
     * @memberof DashboardItemList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemList
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemList
     */
    objectType?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemList
     */
    overview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemList
     */
    summary?: string | null;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof DashboardItemList
     */
    theme?: ThemeEnum;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemList
     */
    readonly companyId: number;
    /**
     * 
     * @type {Date}
     * @memberof DashboardItemList
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardItemList
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemList
     */
    readonly objectOwnerId: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardItemList
     */
    objectIsPublic?: boolean;
}



/**
 * Check if a given object implements the DashboardItemList interface.
 */
export function instanceOfDashboardItemList(value: object): value is DashboardItemList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('objectOwnerId' in value) || value['objectOwnerId'] === undefined) return false;
    return true;
}

export function DashboardItemListFromJSON(json: any): DashboardItemList {
    return DashboardItemListFromJSONTyped(json, false);
}

export function DashboardItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardItemList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'overview': json['overview'] == null ? undefined : json['overview'],
        'summary': json['summary'] == null ? undefined : json['summary'],
        'theme': json['theme'] == null ? undefined : ThemeEnumFromJSON(json['theme']),
        'companyId': json['company_id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectOwnerId': json['object_owner_id'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
    };
}

export function DashboardItemListToJSON(value?: Omit<DashboardItemList, 'id'|'company_id'|'created_at'|'updated_at'|'object_owner_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'object_type': value['objectType'],
        'overview': value['overview'],
        'summary': value['summary'],
        'theme': ThemeEnumToJSON(value['theme']),
        'object_is_public': value['objectIsPublic'],
    };
}

