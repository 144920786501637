/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardTemplateItemList
 */
export interface DashboardTemplateItemList {
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplateItemList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateItemList
     */
    objectType?: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplateItemList
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplateItemList
     */
    readonly updatedAt: Date;
}

/**
 * Check if a given object implements the DashboardTemplateItemList interface.
 */
export function instanceOfDashboardTemplateItemList(value: object): value is DashboardTemplateItemList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function DashboardTemplateItemListFromJSON(json: any): DashboardTemplateItemList {
    return DashboardTemplateItemListFromJSONTyped(json, false);
}

export function DashboardTemplateItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardTemplateItemList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function DashboardTemplateItemListToJSON(value?: Omit<DashboardTemplateItemList, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_type': value['objectType'],
    };
}

