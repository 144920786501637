/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentPriority } from './DocumentPriority';
import {
    DocumentPriorityFromJSON,
    DocumentPriorityFromJSONTyped,
    DocumentPriorityToJSON,
} from './DocumentPriority';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    readonly id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Document
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly commentsCount: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly filesize: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    file?: string;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {DocumentPriority}
     * @memberof Document
     */
    priority?: DocumentPriority | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    indexed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    indexedUid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    team?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Document
     */
    assignees?: Array<number>;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): value is Document {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('commentsCount' in value) || value['commentsCount'] === undefined) return false;
    if (!('filesize' in value) || value['filesize'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'commentsCount': json['comments_count'],
        'filesize': json['filesize'],
        'file': json['file'] == null ? undefined : json['file'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'priority': json['priority'] == null ? undefined : DocumentPriorityFromJSON(json['priority']),
        'name': json['name'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'indexedUid': json['indexed_uid'] == null ? undefined : json['indexed_uid'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'team': json['team'] == null ? undefined : json['team'],
        'assignees': json['assignees'] == null ? undefined : json['assignees'],
    };
}

export function DocumentToJSON(value?: Omit<Document, 'id'|'comments_count'|'filesize'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'file': value['file'],
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'priority': DocumentPriorityToJSON(value['priority']),
        'name': value['name'],
        'indexed': value['indexed'],
        'indexed_uid': value['indexedUid'],
        'owner': value['owner'],
        'directory': value['directory'],
        'team': value['team'],
        'assignees': value['assignees'],
    };
}

