/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `IN_ASSESSMENT` - In Assessment
 * * `ACTIVE` - Active
 * * `RETIRED` - Retired
 * @export
 */
export const RiskStatusEnum = {
    InAssessment: 'IN_ASSESSMENT',
    Active: 'ACTIVE',
    Retired: 'RETIRED'
} as const;
export type RiskStatusEnum = typeof RiskStatusEnum[keyof typeof RiskStatusEnum];


export function instanceOfRiskStatusEnum(value: any): boolean {
    for (const key in RiskStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(RiskStatusEnum, key)) {
            if (RiskStatusEnum[key as keyof typeof RiskStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RiskStatusEnumFromJSON(json: any): RiskStatusEnum {
    return RiskStatusEnumFromJSONTyped(json, false);
}

export function RiskStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskStatusEnum {
    return json as RiskStatusEnum;
}

export function RiskStatusEnumToJSON(value?: RiskStatusEnum | null): any {
    return value as any;
}

