import { useWindowSize } from "usehooks-ts";

import { NEW_MODULES_WITH_GROUPS } from "shared/helpers/ability";
import { useSidebar } from "shared/store/sidebar";

import Can from "../../components/can/Can";
import { SideBarItems } from "../../components/sidebar/types";
import RenderList from "./RenderList";

export default function SidebarComponentModule({
  sideBarItems,
  onClick,
}: {
  sideBarItems: Array<SideBarItems>;
  onClick: (index: number) => void;
}) {
  const { height } = useWindowSize();

  const setIsVisible = useSidebar((state) => state.setIsVisible);

  return (
    <aside
      className="gap-1.5 overflow-y-auto"
      aria-label="Sidebar"
      style={{ height: height - 214 }}
    >
      <div className="h-full">
        <ul className="md:space-y-2">
          {sideBarItems?.map((ele, index) =>
            ele?.action && ele?.subject ? (
              NEW_MODULES_WITH_GROUPS.includes(ele.subject) ? (
                <RenderList
                  key={index}
                  sidebarItem={ele}
                  index={index}
                  onClick={(index) => {
                    if (ele?.items?.length === 0 || !ele?.items) setIsVisible(false);
                    onClick(index);
                  }}
                />
              ) : (
                <Can key={index} I={ele.action} a={ele.subject}>
                  <RenderList
                    sidebarItem={ele}
                    index={index}
                    onClick={(index) => {
                      if (ele?.items?.length === 0 || !ele?.items) setIsVisible(false);
                      onClick(index);
                    }}
                  />
                </Can>
              )
            ) : ele.visibleToProd === true ? (
              <RenderList
                sidebarItem={ele}
                key={index}
                index={index}
                onClick={(index) => {
                  setIsVisible(false);
                  onClick(index);
                }}
              />
            ) : null
          )}
        </ul>
      </div>
    </aside>
  );
}
