/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `CORRECTIVE` - Corrective
 * * `DETECTIVE` - Detective
 * * `PREVENTIVE` - Preventive
 * @export
 */
export const ControlTypeEnum = {
    Corrective: 'CORRECTIVE',
    Detective: 'DETECTIVE',
    Preventive: 'PREVENTIVE'
} as const;
export type ControlTypeEnum = typeof ControlTypeEnum[keyof typeof ControlTypeEnum];


export function instanceOfControlTypeEnum(value: any): boolean {
    for (const key in ControlTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlTypeEnum, key)) {
            if (ControlTypeEnum[key as keyof typeof ControlTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlTypeEnumFromJSON(json: any): ControlTypeEnum {
    return ControlTypeEnumFromJSONTyped(json, false);
}

export function ControlTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTypeEnum {
    return json as ControlTypeEnum;
}

export function ControlTypeEnumToJSON(value?: ControlTypeEnum | null): any {
    return value as any;
}

