/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `MITIGATE` - Mitigate
 * * `TRANSFER` - Transfer
 * * `AVOID` - Avoid
 * * `ACCEPT` - Accept
 * @export
 */
export const TreatmentStrategyEnum = {
    Mitigate: 'MITIGATE',
    Transfer: 'TRANSFER',
    Avoid: 'AVOID',
    Accept: 'ACCEPT'
} as const;
export type TreatmentStrategyEnum = typeof TreatmentStrategyEnum[keyof typeof TreatmentStrategyEnum];


export function instanceOfTreatmentStrategyEnum(value: any): boolean {
    for (const key in TreatmentStrategyEnum) {
        if (Object.prototype.hasOwnProperty.call(TreatmentStrategyEnum, key)) {
            if (TreatmentStrategyEnum[key as keyof typeof TreatmentStrategyEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TreatmentStrategyEnumFromJSON(json: any): TreatmentStrategyEnum {
    return TreatmentStrategyEnumFromJSONTyped(json, false);
}

export function TreatmentStrategyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TreatmentStrategyEnum {
    return json as TreatmentStrategyEnum;
}

export function TreatmentStrategyEnumToJSON(value?: TreatmentStrategyEnum | null): any {
    return value as any;
}

