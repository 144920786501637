import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { TaskAttachmentResponse } from "../types";

export const getTaskFiles = async (
  taskId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PaginatedApiResponse<TaskAttachmentResponse>>> => {
  return api.get("/tasks/attachments/", { params: { ...permissions, task: taskId } });
};
