/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AssessmentTypeEnum } from './AssessmentTypeEnum';
import {
    instanceOfAssessmentTypeEnum,
    AssessmentTypeEnumFromJSON,
    AssessmentTypeEnumFromJSONTyped,
    AssessmentTypeEnumToJSON,
} from './AssessmentTypeEnum';
import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';

/**
 * @type PatchedQuestionnaireRequestQuestionnaireType
 * 
 * @export
 */
export type PatchedQuestionnaireRequestQuestionnaireType = AssessmentTypeEnum | BlankEnum;

export function PatchedQuestionnaireRequestQuestionnaireTypeFromJSON(json: any): PatchedQuestionnaireRequestQuestionnaireType {
    return PatchedQuestionnaireRequestQuestionnaireTypeFromJSONTyped(json, false);
}

export function PatchedQuestionnaireRequestQuestionnaireTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedQuestionnaireRequestQuestionnaireType {
    if (json == null) {
        return json;
    }
    if (instanceOfAssessmentTypeEnum(json)) {
        return AssessmentTypeEnumFromJSONTyped(json, true);
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function PatchedQuestionnaireRequestQuestionnaireTypeToJSON(value?: PatchedQuestionnaireRequestQuestionnaireType | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfAssessmentTypeEnum(value)) {
        return AssessmentTypeEnumToJSON(value as AssessmentTypeEnum);
    }
    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }

    return {};
}

