/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `excel` - Excel
 * @export
 */
export const DocumentTypeEnum = {
    Excel: 'excel'
} as const;
export type DocumentTypeEnum = typeof DocumentTypeEnum[keyof typeof DocumentTypeEnum];


export function instanceOfDocumentTypeEnum(value: any): boolean {
    for (const key in DocumentTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(DocumentTypeEnum, key)) {
            if (DocumentTypeEnum[key as keyof typeof DocumentTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DocumentTypeEnumFromJSON(json: any): DocumentTypeEnum {
    return DocumentTypeEnumFromJSONTyped(json, false);
}

export function DocumentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTypeEnum {
    return json as DocumentTypeEnum;
}

export function DocumentTypeEnumToJSON(value?: DocumentTypeEnum | null): any {
    return value as any;
}

