/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Questionnaire } from './Questionnaire';
import {
    QuestionnaireFromJSON,
    QuestionnaireFromJSONTyped,
    QuestionnaireToJSON,
} from './Questionnaire';
import type { Obligation } from './Obligation';
import {
    ObligationFromJSON,
    ObligationFromJSONTyped,
    ObligationToJSON,
} from './Obligation';
import type { Risk } from './Risk';
import {
    RiskFromJSON,
    RiskFromJSONTyped,
    RiskToJSON,
} from './Risk';
import type { ControlRequestExecutionType } from './ControlRequestExecutionType';
import {
    ControlRequestExecutionTypeFromJSON,
    ControlRequestExecutionTypeFromJSONTyped,
    ControlRequestExecutionTypeToJSON,
} from './ControlRequestExecutionType';
import type { ReviewFrequency } from './ReviewFrequency';
import {
    ReviewFrequencyFromJSON,
    ReviewFrequencyFromJSONTyped,
    ReviewFrequencyToJSON,
} from './ReviewFrequency';
import type { ControlRequestApprovalStatus } from './ControlRequestApprovalStatus';
import {
    ControlRequestApprovalStatusFromJSON,
    ControlRequestApprovalStatusFromJSONTyped,
    ControlRequestApprovalStatusToJSON,
} from './ControlRequestApprovalStatus';
import type { ControlRequestControlArea } from './ControlRequestControlArea';
import {
    ControlRequestControlAreaFromJSON,
    ControlRequestControlAreaFromJSONTyped,
    ControlRequestControlAreaToJSON,
} from './ControlRequestControlArea';
import type { EvidenceResponse } from './EvidenceResponse';
import {
    EvidenceResponseFromJSON,
    EvidenceResponseFromJSONTyped,
    EvidenceResponseToJSON,
} from './EvidenceResponse';
import type { ControlRequestApplicabilityTo } from './ControlRequestApplicabilityTo';
import {
    ControlRequestApplicabilityToFromJSON,
    ControlRequestApplicabilityToFromJSONTyped,
    ControlRequestApplicabilityToToJSON,
} from './ControlRequestApplicabilityTo';
import type { ControlStatusEnum } from './ControlStatusEnum';
import {
    ControlStatusEnumFromJSON,
    ControlStatusEnumFromJSONTyped,
    ControlStatusEnumToJSON,
} from './ControlStatusEnum';
import type { ControlControlType } from './ControlControlType';
import {
    ControlControlTypeFromJSON,
    ControlControlTypeFromJSONTyped,
    ControlControlTypeToJSON,
} from './ControlControlType';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { ControlRequestExecutionFrequency } from './ControlRequestExecutionFrequency';
import {
    ControlRequestExecutionFrequencyFromJSON,
    ControlRequestExecutionFrequencyFromJSONTyped,
    ControlRequestExecutionFrequencyToJSON,
} from './ControlRequestExecutionFrequency';

/**
 * 
 * @export
 * @interface ControlResponse
 */
export interface ControlResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ControlResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlResponse
     */
    description?: string | null;
    /**
     * 
     * @type {ControlRequestControlArea}
     * @memberof ControlResponse
     */
    controlArea?: ControlRequestControlArea | null;
    /**
     * 
     * @type {ControlControlType}
     * @memberof ControlResponse
     */
    controlType?: ControlControlType | null;
    /**
     * 
     * @type {ControlRequestExecutionType}
     * @memberof ControlResponse
     */
    executionType?: ControlRequestExecutionType | null;
    /**
     * 
     * @type {ControlRequestExecutionFrequency}
     * @memberof ControlResponse
     */
    executionFrequency?: ControlRequestExecutionFrequency | null;
    /**
     * 
     * @type {ReviewFrequency}
     * @memberof ControlResponse
     */
    reviewFrequency: ReviewFrequency;
    /**
     * 
     * @type {ControlRequestApplicabilityTo}
     * @memberof ControlResponse
     */
    applicabilityTo?: ControlRequestApplicabilityTo | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlResponse
     */
    implementedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ControlResponse
     */
    customId?: string | null;
    /**
     * 
     * @type {ControlRequestApprovalStatus}
     * @memberof ControlResponse
     */
    approvalStatus?: ControlRequestApprovalStatus | null;
    /**
     * 
     * @type {ControlStatusEnum}
     * @memberof ControlResponse
     */
    status?: ControlStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ControlResponse
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlResponse
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlResponse
     */
    approvalBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlResponse
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {Array<Risk>}
     * @memberof ControlResponse
     */
    readonly risks: Array<Risk>;
    /**
     * 
     * @type {Array<Obligation>}
     * @memberof ControlResponse
     */
    readonly obligations: Array<Obligation>;
    /**
     * 
     * @type {Array<Questionnaire>}
     * @memberof ControlResponse
     */
    readonly questionnaires: Array<Questionnaire>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ControlResponse
     */
    readonly tags: Array<Tag>;
    /**
     * 
     * @type {Array<EvidenceResponse>}
     * @memberof ControlResponse
     */
    readonly evidences: Array<EvidenceResponse>;
    /**
     * 
     * @type {Date}
     * @memberof ControlResponse
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ControlResponse
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ControlResponse
     */
    readonly createdBy: number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlResponse
     */
    readonly updatedBy: number | null;
}



/**
 * Check if a given object implements the ControlResponse interface.
 */
export function instanceOfControlResponse(value: object): value is ControlResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('reviewFrequency' in value) || value['reviewFrequency'] === undefined) return false;
    if (!('risks' in value) || value['risks'] === undefined) return false;
    if (!('obligations' in value) || value['obligations'] === undefined) return false;
    if (!('questionnaires' in value) || value['questionnaires'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('evidences' in value) || value['evidences'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('updatedBy' in value) || value['updatedBy'] === undefined) return false;
    return true;
}

export function ControlResponseFromJSON(json: any): ControlResponse {
    return ControlResponseFromJSONTyped(json, false);
}

export function ControlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'controlArea': json['control_area'] == null ? undefined : ControlRequestControlAreaFromJSON(json['control_area']),
        'controlType': json['control_type'] == null ? undefined : ControlControlTypeFromJSON(json['control_type']),
        'executionType': json['execution_type'] == null ? undefined : ControlRequestExecutionTypeFromJSON(json['execution_type']),
        'executionFrequency': json['execution_frequency'] == null ? undefined : ControlRequestExecutionFrequencyFromJSON(json['execution_frequency']),
        'reviewFrequency': ReviewFrequencyFromJSON(json['review_frequency']),
        'applicabilityTo': json['applicability_to'] == null ? undefined : ControlRequestApplicabilityToFromJSON(json['applicability_to']),
        'implementedAt': json['implemented_at'] == null ? undefined : (new Date(json['implemented_at'])),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlRequestApprovalStatusFromJSON(json['approval_status']),
        'status': json['status'] == null ? undefined : ControlStatusEnumFromJSON(json['status']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'approvalBy': json['approval_by'] == null ? undefined : json['approval_by'],
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'risks': ((json['risks'] as Array<any>).map(RiskFromJSON)),
        'obligations': ((json['obligations'] as Array<any>).map(ObligationFromJSON)),
        'questionnaires': ((json['questionnaires'] as Array<any>).map(QuestionnaireFromJSON)),
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'evidences': ((json['evidences'] as Array<any>).map(EvidenceResponseFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'createdBy': json['created_by'],
        'updatedBy': json['updated_by'],
    };
}

export function ControlResponseToJSON(value?: Omit<ControlResponse, 'id'|'risks'|'obligations'|'questionnaires'|'tags'|'evidences'|'created_at'|'updated_at'|'created_by'|'updated_by'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'control_area': ControlRequestControlAreaToJSON(value['controlArea']),
        'control_type': ControlControlTypeToJSON(value['controlType']),
        'execution_type': ControlRequestExecutionTypeToJSON(value['executionType']),
        'execution_frequency': ControlRequestExecutionFrequencyToJSON(value['executionFrequency']),
        'review_frequency': ReviewFrequencyToJSON(value['reviewFrequency']),
        'applicability_to': ControlRequestApplicabilityToToJSON(value['applicabilityTo']),
        'implemented_at': value['implementedAt'] == null ? undefined : ((value['implementedAt'] as any).toISOString().substring(0,10)),
        'custom_id': value['customId'],
        'approval_status': ControlRequestApprovalStatusToJSON(value['approvalStatus']),
        'status': ControlStatusEnumToJSON(value['status']),
        'object_owner': value['objectOwner'],
        'object_is_public': value['objectIsPublic'],
        'approval_by': value['approvalBy'],
        'decision_details': value['decisionDetails'],
    };
}

