/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface PatchedControlCommentRequest
 */
export interface PatchedControlCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedControlCommentRequest
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof PatchedControlCommentRequest
     */
    status?: CommentStatusEnum;
}



/**
 * Check if a given object implements the PatchedControlCommentRequest interface.
 */
export function instanceOfPatchedControlCommentRequest(value: object): value is PatchedControlCommentRequest {
    return true;
}

export function PatchedControlCommentRequestFromJSON(json: any): PatchedControlCommentRequest {
    return PatchedControlCommentRequestFromJSONTyped(json, false);
}

export function PatchedControlCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedControlCommentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
    };
}

export function PatchedControlCommentRequestToJSON(value?: PatchedControlCommentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
    };
}

