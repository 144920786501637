/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatingLevel
 */
export interface RatingLevel {
    /**
     * 
     * @type {number}
     * @memberof RatingLevel
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof RatingLevel
     */
    readonly matrix: number;
    /**
     * 
     * @type {string}
     * @memberof RatingLevel
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof RatingLevel
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RatingLevel
     */
    minimumScore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RatingLevel
     */
    color?: string | null;
}

/**
 * Check if a given object implements the RatingLevel interface.
 */
export function instanceOfRatingLevel(value: object): value is RatingLevel {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('matrix' in value) || value['matrix'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function RatingLevelFromJSON(json: any): RatingLevel {
    return RatingLevelFromJSONTyped(json, false);
}

export function RatingLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'matrix': json['matrix'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'minimumScore': json['minimum_score'] == null ? undefined : json['minimum_score'],
        'color': json['color'] == null ? undefined : json['color'],
    };
}

export function RatingLevelToJSON(value?: Omit<RatingLevel, 'id'|'matrix'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'description': value['description'],
        'minimum_score': value['minimumScore'],
        'color': value['color'],
    };
}

