import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedCountryList } from "../types";

export default async function getCountries(requestParams?: {
  ordering?: string;
  page?: number;
}): Promise<AxiosResponse<PaginatedCountryList>> {
  const response = await api.get<PaginatedCountryList>("core/countries/", {
    params: { ...hasNameOrdering(requestParams) },
  });

  return response;
}
