/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AutomationEnum } from './AutomationEnum';
import {
    instanceOfAutomationEnum,
    AutomationEnumFromJSON,
    AutomationEnumFromJSONTyped,
    AutomationEnumToJSON,
} from './AutomationEnum';
import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';

/**
 * @type ControlAutomation
 * 
 * @export
 */
export type ControlAutomation = AutomationEnum | BlankEnum;

export function ControlAutomationFromJSON(json: any): ControlAutomation {
    return ControlAutomationFromJSONTyped(json, false);
}

export function ControlAutomationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAutomation {
    if (json == null) {
        return json;
    }
    if (instanceOfAutomationEnum(json)) {
        return AutomationEnumFromJSONTyped(json, true);
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlAutomationToJSON(value?: ControlAutomation | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfAutomationEnum(value)) {
        return AutomationEnumToJSON(value as AutomationEnum);
    }
    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }

    return {};
}

