/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Artifact
 */
export interface Artifact {
    /**
     * 
     * @type {number}
     * @memberof Artifact
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Artifact
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Artifact
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Artifact
     */
    evidence: number;
    /**
     * 
     * @type {string}
     * @memberof Artifact
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Artifact
     */
    file?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Artifact
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Artifact
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Artifact
     */
    readonly objectOwner: number | null;
}

/**
 * Check if a given object implements the Artifact interface.
 */
export function instanceOfArtifact(value: object): value is Artifact {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('evidence' in value) || value['evidence'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('objectOwner' in value) || value['objectOwner'] === undefined) return false;
    return true;
}

export function ArtifactFromJSON(json: any): Artifact {
    return ArtifactFromJSONTyped(json, false);
}

export function ArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Artifact {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'evidence': json['evidence'],
        'url': json['url'] == null ? undefined : json['url'],
        'file': json['file'] == null ? undefined : json['file'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectOwner': json['object_owner'],
    };
}

export function ArtifactToJSON(value?: Omit<Artifact, 'id'|'created_at'|'updated_at'|'object_owner'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'evidence': value['evidence'],
        'url': value['url'],
        'file': value['file'],
    };
}

