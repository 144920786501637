/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskMatrixSettings } from './RiskMatrixSettings';
import {
    RiskMatrixSettingsFromJSON,
    RiskMatrixSettingsFromJSONTyped,
    RiskMatrixSettingsToJSON,
} from './RiskMatrixSettings';
import type { RiskRegisterRawRisk } from './RiskRegisterRawRisk';
import {
    RiskRegisterRawRiskFromJSON,
    RiskRegisterRawRiskFromJSONTyped,
    RiskRegisterRawRiskToJSON,
} from './RiskRegisterRawRisk';
import type { PatchedRiskRegisterRequestReviewSchedule } from './PatchedRiskRegisterRequestReviewSchedule';
import {
    PatchedRiskRegisterRequestReviewScheduleFromJSON,
    PatchedRiskRegisterRequestReviewScheduleFromJSONTyped,
    PatchedRiskRegisterRequestReviewScheduleToJSON,
} from './PatchedRiskRegisterRequestReviewSchedule';

/**
 * 
 * @export
 * @interface RiskRegister
 */
export interface RiskRegister {
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    readonly id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskRegister
     */
    risks?: Array<number>;
    /**
     * 
     * @type {RiskMatrixSettings}
     * @memberof RiskRegister
     */
    matrixSettings?: RiskMatrixSettings;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    target?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    riskType?: number | null;
    /**
     * 
     * @type {Array<RiskRegisterRawRisk>}
     * @memberof RiskRegister
     */
    rawRisks?: Array<RiskRegisterRawRisk>;
    /**
     * 
     * @type {Date}
     * @memberof RiskRegister
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RiskRegister
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RiskRegister
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskRegister
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RiskRegister
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    idCounter?: number;
    /**
     * 
     * @type {PatchedRiskRegisterRequestReviewSchedule}
     * @memberof RiskRegister
     */
    reviewSchedule?: PatchedRiskRegisterRequestReviewSchedule | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegister
     */
    company: number;
}

/**
 * Check if a given object implements the RiskRegister interface.
 */
export function instanceOfRiskRegister(value: object): value is RiskRegister {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function RiskRegisterFromJSON(json: any): RiskRegister {
    return RiskRegisterFromJSONTyped(json, false);
}

export function RiskRegisterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskRegister {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'risks': json['risks'] == null ? undefined : json['risks'],
        'matrixSettings': json['matrix_settings'] == null ? undefined : RiskMatrixSettingsFromJSON(json['matrix_settings']),
        'area': json['area'] == null ? undefined : json['area'],
        'target': json['target'] == null ? undefined : json['target'],
        'riskType': json['risk_type'] == null ? undefined : json['risk_type'],
        'rawRisks': json['raw_risks'] == null ? undefined : ((json['raw_risks'] as Array<any>).map(RiskRegisterRawRiskFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'idCounter': json['id_counter'] == null ? undefined : json['id_counter'],
        'reviewSchedule': json['review_schedule'] == null ? undefined : PatchedRiskRegisterRequestReviewScheduleFromJSON(json['review_schedule']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
    };
}

export function RiskRegisterToJSON(value?: Omit<RiskRegister, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risks': value['risks'],
        'matrix_settings': RiskMatrixSettingsToJSON(value['matrixSettings']),
        'area': value['area'],
        'target': value['target'],
        'risk_type': value['riskType'],
        'raw_risks': value['rawRisks'] == null ? undefined : ((value['rawRisks'] as Array<any>).map(RiskRegisterRawRiskToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'id_counter': value['idCounter'],
        'review_schedule': PatchedRiskRegisterRequestReviewScheduleToJSON(value['reviewSchedule']),
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

