/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskMatrixSettingsRequest } from './RiskMatrixSettingsRequest';
import {
    RiskMatrixSettingsRequestFromJSON,
    RiskMatrixSettingsRequestFromJSONTyped,
    RiskMatrixSettingsRequestToJSON,
} from './RiskMatrixSettingsRequest';

/**
 * 
 * @export
 * @interface PatchedRiskMatrixTemplateRequest
 */
export interface PatchedRiskMatrixTemplateRequest {
    /**
     * 
     * @type {RiskMatrixSettingsRequest}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    matrixSettings?: RiskMatrixSettingsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedRiskMatrixTemplateRequest
     */
    company?: number | null;
}

/**
 * Check if a given object implements the PatchedRiskMatrixTemplateRequest interface.
 */
export function instanceOfPatchedRiskMatrixTemplateRequest(value: object): value is PatchedRiskMatrixTemplateRequest {
    return true;
}

export function PatchedRiskMatrixTemplateRequestFromJSON(json: any): PatchedRiskMatrixTemplateRequest {
    return PatchedRiskMatrixTemplateRequestFromJSONTyped(json, false);
}

export function PatchedRiskMatrixTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedRiskMatrixTemplateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'matrixSettings': json['matrix_settings'] == null ? undefined : RiskMatrixSettingsRequestFromJSON(json['matrix_settings']),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function PatchedRiskMatrixTemplateRequestToJSON(value?: PatchedRiskMatrixTemplateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'matrix_settings': RiskMatrixSettingsRequestToJSON(value['matrixSettings']),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

