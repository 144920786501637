/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { IncidentStatusEnum } from './IncidentStatusEnum';
import {
    instanceOfIncidentStatusEnum,
    IncidentStatusEnumFromJSON,
    IncidentStatusEnumFromJSONTyped,
    IncidentStatusEnumToJSON,
} from './IncidentStatusEnum';

/**
 * @type IncidentStatus
 * 
 * @export
 */
export type IncidentStatus = BlankEnum | IncidentStatusEnum;

export function IncidentStatusFromJSON(json: any): IncidentStatus {
    return IncidentStatusFromJSONTyped(json, false);
}

export function IncidentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfIncidentStatusEnum(json)) {
        return IncidentStatusEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function IncidentStatusToJSON(value?: IncidentStatus | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfIncidentStatusEnum(value)) {
        return IncidentStatusEnumToJSON(value as IncidentStatusEnum);
    }

    return {};
}

