/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { LogBaseEnum } from './LogBaseEnum';
import {
    instanceOfLogBaseEnum,
    LogBaseEnumFromJSON,
    LogBaseEnumFromJSONTyped,
    LogBaseEnumToJSON,
} from './LogBaseEnum';

/**
 * @type RiskMatrixSettingsLogBase
 * 
 * @export
 */
export type RiskMatrixSettingsLogBase = BlankEnum | LogBaseEnum;

export function RiskMatrixSettingsLogBaseFromJSON(json: any): RiskMatrixSettingsLogBase {
    return RiskMatrixSettingsLogBaseFromJSONTyped(json, false);
}

export function RiskMatrixSettingsLogBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrixSettingsLogBase {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfLogBaseEnum(json)) {
        return LogBaseEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function RiskMatrixSettingsLogBaseToJSON(value?: RiskMatrixSettingsLogBase | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfLogBaseEnum(value)) {
        return LogBaseEnumToJSON(value as LogBaseEnum);
    }

    return {};
}

