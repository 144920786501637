/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedReviewFrequencyRequestFrequency } from './PatchedReviewFrequencyRequestFrequency';
import {
    PatchedReviewFrequencyRequestFrequencyFromJSON,
    PatchedReviewFrequencyRequestFrequencyFromJSONTyped,
    PatchedReviewFrequencyRequestFrequencyToJSON,
} from './PatchedReviewFrequencyRequestFrequency';

/**
 * 
 * @export
 * @interface ReviewFrequency
 */
export interface ReviewFrequency {
    /**
     * 
     * @type {number}
     * @memberof ReviewFrequency
     */
    readonly id: number;
    /**
     * 
     * @type {PatchedReviewFrequencyRequestFrequency}
     * @memberof ReviewFrequency
     */
    frequency?: PatchedReviewFrequencyRequestFrequency | null;
    /**
     * 
     * @type {Date}
     * @memberof ReviewFrequency
     */
    nextReviewAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReviewFrequency
     */
    readonly lastReviewAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ReviewFrequency
     */
    readonly reviewedBy: number | null;
}

/**
 * Check if a given object implements the ReviewFrequency interface.
 */
export function instanceOfReviewFrequency(value: object): value is ReviewFrequency {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lastReviewAt' in value) || value['lastReviewAt'] === undefined) return false;
    if (!('reviewedBy' in value) || value['reviewedBy'] === undefined) return false;
    return true;
}

export function ReviewFrequencyFromJSON(json: any): ReviewFrequency {
    return ReviewFrequencyFromJSONTyped(json, false);
}

export function ReviewFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewFrequency {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'frequency': json['frequency'] == null ? undefined : PatchedReviewFrequencyRequestFrequencyFromJSON(json['frequency']),
        'nextReviewAt': json['next_review_at'] == null ? undefined : (new Date(json['next_review_at'])),
        'lastReviewAt': (json['last_review_at'] == null ? null : new Date(json['last_review_at'])),
        'reviewedBy': json['reviewed_by'],
    };
}

export function ReviewFrequencyToJSON(value?: Omit<ReviewFrequency, 'id'|'last_review_at'|'reviewed_by'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frequency': PatchedReviewFrequencyRequestFrequencyToJSON(value['frequency']),
        'next_review_at': value['nextReviewAt'] == null ? undefined : ((value['nextReviewAt'] as any).toISOString().substring(0,10)),
    };
}

