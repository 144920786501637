/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskSubtype } from './RiskSubtype';
import {
    RiskSubtypeFromJSON,
    RiskSubtypeFromJSONTyped,
    RiskSubtypeToJSON,
} from './RiskSubtype';

/**
 * 
 * @export
 * @interface RiskType
 */
export interface RiskType {
    /**
     * 
     * @type {number}
     * @memberof RiskType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RiskType
     */
    name: string;
    /**
     * 
     * @type {Array<RiskSubtype>}
     * @memberof RiskType
     */
    riskSubtypes: Array<RiskSubtype>;
}

/**
 * Check if a given object implements the RiskType interface.
 */
export function instanceOfRiskType(value: object): value is RiskType {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('riskSubtypes' in value) || value['riskSubtypes'] === undefined) return false;
    return true;
}

export function RiskTypeFromJSON(json: any): RiskType {
    return RiskTypeFromJSONTyped(json, false);
}

export function RiskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'],
        'riskSubtypes': ((json['risk_subtypes'] as Array<any>).map(RiskSubtypeFromJSON)),
    };
}

export function RiskTypeToJSON(value?: RiskType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'risk_subtypes': ((value['riskSubtypes'] as Array<any>).map(RiskSubtypeToJSON)),
    };
}

