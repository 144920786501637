/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { RiskImpactEnum } from './RiskImpactEnum';
import {
    instanceOfRiskImpactEnum,
    RiskImpactEnumFromJSON,
    RiskImpactEnumFromJSONTyped,
    RiskImpactEnumToJSON,
} from './RiskImpactEnum';

/**
 * @type IncidentOverallImpact
 * 
 * @export
 */
export type IncidentOverallImpact = BlankEnum | RiskImpactEnum;

export function IncidentOverallImpactFromJSON(json: any): IncidentOverallImpact {
    return IncidentOverallImpactFromJSONTyped(json, false);
}

export function IncidentOverallImpactFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentOverallImpact {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfRiskImpactEnum(json)) {
        return RiskImpactEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function IncidentOverallImpactToJSON(value?: IncidentOverallImpact | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfRiskImpactEnum(value)) {
        return RiskImpactEnumToJSON(value as RiskImpactEnum);
    }

    return {};
}

