/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { ExecutionTypeEnum } from './ExecutionTypeEnum';
import {
    instanceOfExecutionTypeEnum,
    ExecutionTypeEnumFromJSON,
    ExecutionTypeEnumFromJSONTyped,
    ExecutionTypeEnumToJSON,
} from './ExecutionTypeEnum';

/**
 * @type ControlRequestExecutionType
 * 
 * @export
 */
export type ControlRequestExecutionType = BlankEnum | ExecutionTypeEnum;

export function ControlRequestExecutionTypeFromJSON(json: any): ControlRequestExecutionType {
    return ControlRequestExecutionTypeFromJSONTyped(json, false);
}

export function ControlRequestExecutionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequestExecutionType {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfExecutionTypeEnum(json)) {
        return ExecutionTypeEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlRequestExecutionTypeToJSON(value?: ControlRequestExecutionType | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfExecutionTypeEnum(value)) {
        return ExecutionTypeEnumToJSON(value as ExecutionTypeEnum);
    }

    return {};
}

