/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `change_management_process_npap` - Change Management Process/NPAP
 * * `onsite_visits` - Onsite Visits
 * * `resolvability_assessment` - Resolvability Assessment
 * * `offshore_assessment` - Offshore Assessment
 * * `outsourcing_arrangement_assessment` - Outsourcing Arrangement Assessment
 * * `cloud_security_assessment` - Cloud Security Assessment
 * * `data_privacy` - Data Privacy
 * * `information_security_cybersecurity` - Information Security/Cybersecurity
 * * `code_of_conduct` - Code of Conduct
 * * `corporate_social_responsibility_surveys` - Corporate Social Responsibility Surveys
 * * `reputational_assessment_adverse_media_screening` - Reputational Assessment/Adverse Media Screening
 * * `compliance_evaluation` - Compliance Evaluation
 * * `legal_evaluation` - Legal Evaluation
 * * `financial_assessment_sanction_screening` - Financial Assessment/Sanction Screening
 * * `business_continuity_plans` - Business Continuity Plans
 * * `disaster_recovery_plans` - Disaster Recovery Plans
 * * `contract_assessment` - Contract Assessment
 * * `insurance_assessment` - Insurance Assessment
 * * `environmental_social_governance` - Environmental Social Governance
 * * `strategic_alignment_evaluation` - Strategic Alignment Evaluation
 * * `market_position_assessment` - Market Position Assessment
 * * `know_your_vendor` - Know Your Vendor
 * @export
 */
export const AssessmentTypeEnum = {
    ChangeManagementProcessNpap: 'change_management_process_npap',
    OnsiteVisits: 'onsite_visits',
    ResolvabilityAssessment: 'resolvability_assessment',
    OffshoreAssessment: 'offshore_assessment',
    OutsourcingArrangementAssessment: 'outsourcing_arrangement_assessment',
    CloudSecurityAssessment: 'cloud_security_assessment',
    DataPrivacy: 'data_privacy',
    InformationSecurityCybersecurity: 'information_security_cybersecurity',
    CodeOfConduct: 'code_of_conduct',
    CorporateSocialResponsibilitySurveys: 'corporate_social_responsibility_surveys',
    ReputationalAssessmentAdverseMediaScreening: 'reputational_assessment_adverse_media_screening',
    ComplianceEvaluation: 'compliance_evaluation',
    LegalEvaluation: 'legal_evaluation',
    FinancialAssessmentSanctionScreening: 'financial_assessment_sanction_screening',
    BusinessContinuityPlans: 'business_continuity_plans',
    DisasterRecoveryPlans: 'disaster_recovery_plans',
    ContractAssessment: 'contract_assessment',
    InsuranceAssessment: 'insurance_assessment',
    EnvironmentalSocialGovernance: 'environmental_social_governance',
    StrategicAlignmentEvaluation: 'strategic_alignment_evaluation',
    MarketPositionAssessment: 'market_position_assessment',
    KnowYourVendor: 'know_your_vendor'
} as const;
export type AssessmentTypeEnum = typeof AssessmentTypeEnum[keyof typeof AssessmentTypeEnum];


export function instanceOfAssessmentTypeEnum(value: any): boolean {
    for (const key in AssessmentTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(AssessmentTypeEnum, key)) {
            if (AssessmentTypeEnum[key as keyof typeof AssessmentTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AssessmentTypeEnumFromJSON(json: any): AssessmentTypeEnum {
    return AssessmentTypeEnumFromJSONTyped(json, false);
}

export function AssessmentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentTypeEnum {
    return json as AssessmentTypeEnum;
}

export function AssessmentTypeEnumToJSON(value?: AssessmentTypeEnum | null): any {
    return value as any;
}

