import { Link } from "react-router-dom";

import Icon from "shared/components/icon/Icon";

const PaginationIcon = ({
  to,
  iconType,
  isDisabled,
}: {
  to: string;
  iconType: string;
  isDisabled: boolean;
}) =>
  isDisabled ? (
    <span className="pointer-events-none opacity-20">
      <Icon type={iconType} fill={true} size="icon-xs" className="pagination_arrow" />
    </span>
  ) : (
    <Link to={to}>
      <Icon type={iconType} fill={true} size="icon-xs" className="pagination_arrow" />
    </Link>
  );

export default PaginationIcon;
