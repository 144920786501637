/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { ReviewScheduleEnum } from './ReviewScheduleEnum';
import {
    instanceOfReviewScheduleEnum,
    ReviewScheduleEnumFromJSON,
    ReviewScheduleEnumFromJSONTyped,
    ReviewScheduleEnumToJSON,
} from './ReviewScheduleEnum';

/**
 * @type PatchedRiskRegisterRequestReviewSchedule
 * 
 * @export
 */
export type PatchedRiskRegisterRequestReviewSchedule = BlankEnum | ReviewScheduleEnum;

export function PatchedRiskRegisterRequestReviewScheduleFromJSON(json: any): PatchedRiskRegisterRequestReviewSchedule {
    return PatchedRiskRegisterRequestReviewScheduleFromJSONTyped(json, false);
}

export function PatchedRiskRegisterRequestReviewScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedRiskRegisterRequestReviewSchedule {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfReviewScheduleEnum(json)) {
        return ReviewScheduleEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function PatchedRiskRegisterRequestReviewScheduleToJSON(value?: PatchedRiskRegisterRequestReviewSchedule | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfReviewScheduleEnum(value)) {
        return ReviewScheduleEnumToJSON(value as ReviewScheduleEnum);
    }

    return {};
}

