/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedDashboardListRequest
 */
export interface PatchedDashboardListRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDashboardListRequest
     */
    dashboards?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDashboardListRequest
     */
    dashboardTemplates?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedDashboardListRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedDashboardListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedDashboardListRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedDashboardListRequest
     */
    company?: number;
}

/**
 * Check if a given object implements the PatchedDashboardListRequest interface.
 */
export function instanceOfPatchedDashboardListRequest(value: object): value is PatchedDashboardListRequest {
    return true;
}

export function PatchedDashboardListRequestFromJSON(json: any): PatchedDashboardListRequest {
    return PatchedDashboardListRequestFromJSONTyped(json, false);
}

export function PatchedDashboardListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDashboardListRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboards': json['dashboards'] == null ? undefined : json['dashboards'],
        'dashboardTemplates': json['dashboard_templates'] == null ? undefined : json['dashboard_templates'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function PatchedDashboardListRequestToJSON(value?: PatchedDashboardListRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboards': value['dashboards'],
        'dashboard_templates': value['dashboardTemplates'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

