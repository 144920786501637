/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskMatrixSettingsRequest } from './RiskMatrixSettingsRequest';
import {
    RiskMatrixSettingsRequestFromJSON,
    RiskMatrixSettingsRequestFromJSONTyped,
    RiskMatrixSettingsRequestToJSON,
} from './RiskMatrixSettingsRequest';
import type { RiskRegisterRawRiskRequest } from './RiskRegisterRawRiskRequest';
import {
    RiskRegisterRawRiskRequestFromJSON,
    RiskRegisterRawRiskRequestFromJSONTyped,
    RiskRegisterRawRiskRequestToJSON,
} from './RiskRegisterRawRiskRequest';
import type { PatchedRiskRegisterRequestReviewSchedule } from './PatchedRiskRegisterRequestReviewSchedule';
import {
    PatchedRiskRegisterRequestReviewScheduleFromJSON,
    PatchedRiskRegisterRequestReviewScheduleFromJSONTyped,
    PatchedRiskRegisterRequestReviewScheduleToJSON,
} from './PatchedRiskRegisterRequestReviewSchedule';

/**
 * 
 * @export
 * @interface RiskRegisterRequest
 */
export interface RiskRegisterRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskRegisterRequest
     */
    risks?: Array<number>;
    /**
     * 
     * @type {RiskMatrixSettingsRequest}
     * @memberof RiskRegisterRequest
     */
    matrixSettings?: RiskMatrixSettingsRequest;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    matrixTemplate?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskRegisterRequest
     */
    riskTemplates?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    target?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    riskType?: number | null;
    /**
     * 
     * @type {Array<RiskRegisterRawRiskRequest>}
     * @memberof RiskRegisterRequest
     */
    rawRisks?: Array<RiskRegisterRawRiskRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof RiskRegisterRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    idCounter?: number;
    /**
     * 
     * @type {PatchedRiskRegisterRequestReviewSchedule}
     * @memberof RiskRegisterRequest
     */
    reviewSchedule?: PatchedRiskRegisterRequestReviewSchedule | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRequest
     */
    company: number;
}

/**
 * Check if a given object implements the RiskRegisterRequest interface.
 */
export function instanceOfRiskRegisterRequest(value: object): value is RiskRegisterRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function RiskRegisterRequestFromJSON(json: any): RiskRegisterRequest {
    return RiskRegisterRequestFromJSONTyped(json, false);
}

export function RiskRegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskRegisterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'risks': json['risks'] == null ? undefined : json['risks'],
        'matrixSettings': json['matrix_settings'] == null ? undefined : RiskMatrixSettingsRequestFromJSON(json['matrix_settings']),
        'matrixTemplate': json['matrix_template'] == null ? undefined : json['matrix_template'],
        'riskTemplates': json['risk_templates'] == null ? undefined : json['risk_templates'],
        'area': json['area'] == null ? undefined : json['area'],
        'target': json['target'] == null ? undefined : json['target'],
        'riskType': json['risk_type'] == null ? undefined : json['risk_type'],
        'rawRisks': json['raw_risks'] == null ? undefined : ((json['raw_risks'] as Array<any>).map(RiskRegisterRawRiskRequestFromJSON)),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'idCounter': json['id_counter'] == null ? undefined : json['id_counter'],
        'reviewSchedule': json['review_schedule'] == null ? undefined : PatchedRiskRegisterRequestReviewScheduleFromJSON(json['review_schedule']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
    };
}

export function RiskRegisterRequestToJSON(value?: RiskRegisterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risks': value['risks'],
        'matrix_settings': RiskMatrixSettingsRequestToJSON(value['matrixSettings']),
        'matrix_template': value['matrixTemplate'],
        'risk_templates': value['riskTemplates'],
        'area': value['area'],
        'target': value['target'],
        'risk_type': value['riskType'],
        'raw_risks': value['rawRisks'] == null ? undefined : ((value['rawRisks'] as Array<any>).map(RiskRegisterRawRiskRequestToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'id_counter': value['idCounter'],
        'review_schedule': PatchedRiskRegisterRequestReviewScheduleToJSON(value['reviewSchedule']),
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

