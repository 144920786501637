import { create } from "zustand";

interface MultiYearPlannerStore {
  startYear: Date;
  setStartYear: (startYear: Date) => void;

  endYear: Date;
  setEndYear: (startYear: Date) => void;

  defaultYear: Date | null;
  setDefaultYear: (defaultYear: Date | null) => void;

  prevSelectedEndYear: Date | null;
  setPrevSelectedEndYear: (prevSelectedEndYear: Date | null) => void;

  setDefaultYears: () => void;
}

export const useMultiYearPlanner = create<MultiYearPlannerStore>((set) => ({
  startYear: new Date(),
  setStartYear: (startYear: Date) => {
    set({ startYear });
  },

  endYear: new Date(),
  setEndYear: (endYear: Date) => {
    set({ endYear });
  },

  defaultYear: new Date(),
  setDefaultYear: (defaultYear: Date | null) => set({ defaultYear }),

  prevSelectedEndYear: new Date(),
  setPrevSelectedEndYear: (prevSelectedEndYear: Date | null) => set({ prevSelectedEndYear }),

  setDefaultYears: () => {
    const defaultStartYear = new Date();
    const defaultEndYear = new Date(defaultStartYear.getFullYear() + 5, 0, 1);

    set({
      startYear: defaultStartYear,
      endYear: defaultEndYear,
      defaultYear: defaultEndYear,
      prevSelectedEndYear: null,
    });
  },
}));
