/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `critical` - Critical
 * * `medium` - Medium
 * * `low` - Low
 * @export
 */
export const BusinessCriticalityEnum = {
    Critical: 'critical',
    Medium: 'medium',
    Low: 'low'
} as const;
export type BusinessCriticalityEnum = typeof BusinessCriticalityEnum[keyof typeof BusinessCriticalityEnum];


export function instanceOfBusinessCriticalityEnum(value: any): boolean {
    for (const key in BusinessCriticalityEnum) {
        if (Object.prototype.hasOwnProperty.call(BusinessCriticalityEnum, key)) {
            if (BusinessCriticalityEnum[key as keyof typeof BusinessCriticalityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BusinessCriticalityEnumFromJSON(json: any): BusinessCriticalityEnum {
    return BusinessCriticalityEnumFromJSONTyped(json, false);
}

export function BusinessCriticalityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessCriticalityEnum {
    return json as BusinessCriticalityEnum;
}

export function BusinessCriticalityEnumToJSON(value?: BusinessCriticalityEnum | null): any {
    return value as any;
}

