/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `MANUAL` - Manual
 * * `SEMI_AUTOMATIC` - Semi Automatic
 * * `AUTOMATIC` - Automatic
 * @export
 */
export const ExecutionTypeEnum = {
    Manual: 'MANUAL',
    SemiAutomatic: 'SEMI_AUTOMATIC',
    Automatic: 'AUTOMATIC'
} as const;
export type ExecutionTypeEnum = typeof ExecutionTypeEnum[keyof typeof ExecutionTypeEnum];


export function instanceOfExecutionTypeEnum(value: any): boolean {
    for (const key in ExecutionTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ExecutionTypeEnum, key)) {
            if (ExecutionTypeEnum[key as keyof typeof ExecutionTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ExecutionTypeEnumFromJSON(json: any): ExecutionTypeEnum {
    return ExecutionTypeEnumFromJSONTyped(json, false);
}

export function ExecutionTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionTypeEnum {
    return json as ExecutionTypeEnum;
}

export function ExecutionTypeEnumToJSON(value?: ExecutionTypeEnum | null): any {
    return value as any;
}

