/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuestionnaireStatusEnum } from './QuestionnaireStatusEnum';
import {
    QuestionnaireStatusEnumFromJSON,
    QuestionnaireStatusEnumFromJSONTyped,
    QuestionnaireStatusEnumToJSON,
} from './QuestionnaireStatusEnum';
import type { PatchedQuestionnaireRequestQuestionnaireType } from './PatchedQuestionnaireRequestQuestionnaireType';
import {
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSON,
    PatchedQuestionnaireRequestQuestionnaireTypeFromJSONTyped,
    PatchedQuestionnaireRequestQuestionnaireTypeToJSON,
} from './PatchedQuestionnaireRequestQuestionnaireType';
import type { DocumentTypeEnum } from './DocumentTypeEnum';
import {
    DocumentTypeEnumFromJSON,
    DocumentTypeEnumFromJSONTyped,
    DocumentTypeEnumToJSON,
} from './DocumentTypeEnum';

/**
 * 
 * @export
 * @interface QuestionnaireRequest
 */
export interface QuestionnaireRequest {
    /**
     * 
     * @type {Blob}
     * @memberof QuestionnaireRequest
     */
    document?: Blob;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireRequest
     */
    description?: string | null;
    /**
     * 
     * @type {QuestionnaireStatusEnum}
     * @memberof QuestionnaireRequest
     */
    status?: QuestionnaireStatusEnum;
    /**
     * 
     * @type {PatchedQuestionnaireRequestQuestionnaireType}
     * @memberof QuestionnaireRequest
     */
    questionnaireType?: PatchedQuestionnaireRequestQuestionnaireType | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireRequest
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {DocumentTypeEnum}
     * @memberof QuestionnaireRequest
     */
    documentType?: DocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    associatedObjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    baseTemplate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    associatedObjectContentType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireRequest
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof QuestionnaireRequest
     */
    owners?: Array<number>;
}



/**
 * Check if a given object implements the QuestionnaireRequest interface.
 */
export function instanceOfQuestionnaireRequest(value: object): value is QuestionnaireRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function QuestionnaireRequestFromJSON(json: any): QuestionnaireRequest {
    return QuestionnaireRequestFromJSONTyped(json, false);
}

export function QuestionnaireRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'document': json['document'] == null ? undefined : json['document'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : QuestionnaireStatusEnumFromJSON(json['status']),
        'questionnaireType': json['questionnaire_type'] == null ? undefined : PatchedQuestionnaireRequestQuestionnaireTypeFromJSON(json['questionnaire_type']),
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'documentType': json['document_type'] == null ? undefined : DocumentTypeEnumFromJSON(json['document_type']),
        'associatedObjectId': json['associated_object_id'] == null ? undefined : json['associated_object_id'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'company': json['company'],
        'baseTemplate': json['base_template'] == null ? undefined : json['base_template'],
        'associatedObjectContentType': json['associated_object_content_type'] == null ? undefined : json['associated_object_content_type'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'owners': json['owners'] == null ? undefined : json['owners'],
    };
}

export function QuestionnaireRequestToJSON(value?: QuestionnaireRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'document': value['document'],
        'tags': value['tags'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'status': QuestionnaireStatusEnumToJSON(value['status']),
        'questionnaire_type': PatchedQuestionnaireRequestQuestionnaireTypeToJSON(value['questionnaireType']),
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'document_type': DocumentTypeEnumToJSON(value['documentType']),
        'associated_object_id': value['associatedObjectId'],
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
        'company': value['company'],
        'base_template': value['baseTemplate'],
        'associated_object_content_type': value['associatedObjectContentType'],
        'updated_by': value['updatedBy'],
        'owners': value['owners'],
    };
}

