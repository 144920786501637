/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedRiskRequestStatus } from './PatchedRiskRequestStatus';
import {
    PatchedRiskRequestStatusFromJSON,
    PatchedRiskRequestStatusFromJSONTyped,
    PatchedRiskRequestStatusToJSON,
} from './PatchedRiskRequestStatus';
import type { ControlApprovalStatus } from './ControlApprovalStatus';
import {
    ControlApprovalStatusFromJSON,
    ControlApprovalStatusFromJSONTyped,
    ControlApprovalStatusToJSON,
} from './ControlApprovalStatus';

/**
 * used to return the same schema in list view and meili
 * @export
 * @interface RiskList
 */
export interface RiskList {
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    riskId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    company: number;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    customId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    description?: string | null;
    /**
     * 
     * @type {PatchedRiskRequestStatus}
     * @memberof RiskList
     */
    status?: PatchedRiskRequestStatus | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly riskType: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly target: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly area: string;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    readonly areaId: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly containingList: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly objectIsEditable: string;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    owner?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly ownerName: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    objectType?: string;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    readonly objectOwnerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    readonly approvalById: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    readonly approvalByName: string;
    /**
     * 
     * @type {ControlApprovalStatus}
     * @memberof RiskList
     */
    approvalStatus?: ControlApprovalStatus | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RiskList
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RiskList
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    inherentRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    inherentRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    residualRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    residualRiskLikelihood?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    inherentSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    inherentAnnualLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    inherentAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualSingleLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    residualSingleLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualAnnualLossExpectancyValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    residualAnnualLossExpectancyCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskList
     */
    residualAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentLikelihoodRaw?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentImpactRaw?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentLikelihood?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentImpact?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentLikelihoodLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentImpactLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentRiskScore?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentRiskLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    inherentRiskLabelDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualLikelihoodRaw?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualImpactRaw?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualLikelihood?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualImpact?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualLikelihoodLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualImpactLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualRiskScore?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualRiskLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskList
     */
    residualRiskLabelDescription?: string;
}

/**
 * Check if a given object implements the RiskList interface.
 */
export function instanceOfRiskList(value: object): value is RiskList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('riskType' in value) || value['riskType'] === undefined) return false;
    if (!('target' in value) || value['target'] === undefined) return false;
    if (!('area' in value) || value['area'] === undefined) return false;
    if (!('areaId' in value) || value['areaId'] === undefined) return false;
    if (!('containingList' in value) || value['containingList'] === undefined) return false;
    if (!('objectIsEditable' in value) || value['objectIsEditable'] === undefined) return false;
    if (!('ownerName' in value) || value['ownerName'] === undefined) return false;
    if (!('objectOwnerId' in value) || value['objectOwnerId'] === undefined) return false;
    if (!('approvalById' in value) || value['approvalById'] === undefined) return false;
    if (!('approvalByName' in value) || value['approvalByName'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function RiskListFromJSON(json: any): RiskList {
    return RiskListFromJSONTyped(json, false);
}

export function RiskListFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'riskId': json['risk_id'] == null ? undefined : json['risk_id'],
        'name': json['name'],
        'company': json['company'],
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : PatchedRiskRequestStatusFromJSON(json['status']),
        'riskType': json['risk_type'],
        'target': json['target'],
        'area': json['area'],
        'areaId': json['area_id'],
        'containingList': json['containing_list'],
        'objectIsEditable': json['object_is_editable'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'ownerName': json['owner_name'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'objectOwnerId': json['object_owner_id'],
        'approvalById': json['approval_by_id'],
        'approvalByName': json['approval_by_name'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlApprovalStatusFromJSON(json['approval_status']),
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'inherentRiskImpact': json['inherent_risk_impact'] == null ? undefined : json['inherent_risk_impact'],
        'inherentRiskLikelihood': json['inherent_risk_likelihood'] == null ? undefined : json['inherent_risk_likelihood'],
        'residualRiskImpact': json['residual_risk_impact'] == null ? undefined : json['residual_risk_impact'],
        'residualRiskLikelihood': json['residual_risk_likelihood'] == null ? undefined : json['residual_risk_likelihood'],
        'inherentSingleLossExpectancyValue': json['inherent_single_loss_expectancy_value'] == null ? undefined : json['inherent_single_loss_expectancy_value'],
        'inherentSingleLossExpectancyCurrency': json['inherent_single_loss_expectancy_currency'] == null ? undefined : json['inherent_single_loss_expectancy_currency'],
        'inherentAnnualLossExpectancyValue': json['inherent_annual_loss_expectancy_value'] == null ? undefined : json['inherent_annual_loss_expectancy_value'],
        'inherentAnnualLossExpectancyCurrency': json['inherent_annual_loss_expectancy_currency'] == null ? undefined : json['inherent_annual_loss_expectancy_currency'],
        'inherentAnnualizedRateOfOccurrence': json['inherent_annualized_rate_of_occurrence'] == null ? undefined : json['inherent_annualized_rate_of_occurrence'],
        'residualSingleLossExpectancyValue': json['residual_single_loss_expectancy_value'] == null ? undefined : json['residual_single_loss_expectancy_value'],
        'residualSingleLossExpectancyCurrency': json['residual_single_loss_expectancy_currency'] == null ? undefined : json['residual_single_loss_expectancy_currency'],
        'residualAnnualLossExpectancyValue': json['residual_annual_loss_expectancy_value'] == null ? undefined : json['residual_annual_loss_expectancy_value'],
        'residualAnnualLossExpectancyCurrency': json['residual_annual_loss_expectancy_currency'] == null ? undefined : json['residual_annual_loss_expectancy_currency'],
        'residualAnnualizedRateOfOccurrence': json['residual_annualized_rate_of_occurrence'] == null ? undefined : json['residual_annualized_rate_of_occurrence'],
        'inherentLikelihoodRaw': json['inherent_likelihood_raw'] == null ? undefined : json['inherent_likelihood_raw'],
        'inherentImpactRaw': json['inherent_impact_raw'] == null ? undefined : json['inherent_impact_raw'],
        'inherentLikelihood': json['inherent_likelihood'] == null ? undefined : json['inherent_likelihood'],
        'inherentImpact': json['inherent_impact'] == null ? undefined : json['inherent_impact'],
        'inherentLikelihoodLabel': json['inherent_likelihood_label'] == null ? undefined : json['inherent_likelihood_label'],
        'inherentImpactLabel': json['inherent_impact_label'] == null ? undefined : json['inherent_impact_label'],
        'inherentRiskScore': json['inherent_risk_score'] == null ? undefined : json['inherent_risk_score'],
        'inherentRiskLabel': json['inherent_risk_label'] == null ? undefined : json['inherent_risk_label'],
        'inherentRiskLabelDescription': json['inherent_risk_label_description'] == null ? undefined : json['inherent_risk_label_description'],
        'residualLikelihoodRaw': json['residual_likelihood_raw'] == null ? undefined : json['residual_likelihood_raw'],
        'residualImpactRaw': json['residual_impact_raw'] == null ? undefined : json['residual_impact_raw'],
        'residualLikelihood': json['residual_likelihood'] == null ? undefined : json['residual_likelihood'],
        'residualImpact': json['residual_impact'] == null ? undefined : json['residual_impact'],
        'residualLikelihoodLabel': json['residual_likelihood_label'] == null ? undefined : json['residual_likelihood_label'],
        'residualImpactLabel': json['residual_impact_label'] == null ? undefined : json['residual_impact_label'],
        'residualRiskScore': json['residual_risk_score'] == null ? undefined : json['residual_risk_score'],
        'residualRiskLabel': json['residual_risk_label'] == null ? undefined : json['residual_risk_label'],
        'residualRiskLabelDescription': json['residual_risk_label_description'] == null ? undefined : json['residual_risk_label_description'],
    };
}

export function RiskListToJSON(value?: Omit<RiskList, 'id'|'risk_type'|'target'|'area'|'area_id'|'containing_list'|'object_is_editable'|'owner_name'|'object_owner_id'|'approval_by_id'|'approval_by_name'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risk_id': value['riskId'],
        'name': value['name'],
        'company': value['company'],
        'custom_id': value['customId'],
        'description': value['description'],
        'status': PatchedRiskRequestStatusToJSON(value['status']),
        'owner': value['owner'],
        'object_type': value['objectType'],
        'object_owner': value['objectOwner'],
        'approval_status': ControlApprovalStatusToJSON(value['approvalStatus']),
        'decision_details': value['decisionDetails'],
        'inherent_risk_impact': value['inherentRiskImpact'],
        'inherent_risk_likelihood': value['inherentRiskLikelihood'],
        'residual_risk_impact': value['residualRiskImpact'],
        'residual_risk_likelihood': value['residualRiskLikelihood'],
        'inherent_single_loss_expectancy_value': value['inherentSingleLossExpectancyValue'],
        'inherent_single_loss_expectancy_currency': value['inherentSingleLossExpectancyCurrency'],
        'inherent_annual_loss_expectancy_value': value['inherentAnnualLossExpectancyValue'],
        'inherent_annual_loss_expectancy_currency': value['inherentAnnualLossExpectancyCurrency'],
        'inherent_annualized_rate_of_occurrence': value['inherentAnnualizedRateOfOccurrence'],
        'residual_single_loss_expectancy_value': value['residualSingleLossExpectancyValue'],
        'residual_single_loss_expectancy_currency': value['residualSingleLossExpectancyCurrency'],
        'residual_annual_loss_expectancy_value': value['residualAnnualLossExpectancyValue'],
        'residual_annual_loss_expectancy_currency': value['residualAnnualLossExpectancyCurrency'],
        'residual_annualized_rate_of_occurrence': value['residualAnnualizedRateOfOccurrence'],
        'inherent_likelihood_raw': value['inherentLikelihoodRaw'],
        'inherent_impact_raw': value['inherentImpactRaw'],
        'inherent_likelihood': value['inherentLikelihood'],
        'inherent_impact': value['inherentImpact'],
        'inherent_likelihood_label': value['inherentLikelihoodLabel'],
        'inherent_impact_label': value['inherentImpactLabel'],
        'inherent_risk_score': value['inherentRiskScore'],
        'inherent_risk_label': value['inherentRiskLabel'],
        'inherent_risk_label_description': value['inherentRiskLabelDescription'],
        'residual_likelihood_raw': value['residualLikelihoodRaw'],
        'residual_impact_raw': value['residualImpactRaw'],
        'residual_likelihood': value['residualLikelihood'],
        'residual_impact': value['residualImpact'],
        'residual_likelihood_label': value['residualLikelihoodLabel'],
        'residual_impact_label': value['residualImpactLabel'],
        'residual_risk_score': value['residualRiskScore'],
        'residual_risk_label': value['residualRiskLabel'],
        'residual_risk_label_description': value['residualRiskLabelDescription'],
    };
}

