/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { BusinessCriticalityEnum } from './BusinessCriticalityEnum';
import {
    instanceOfBusinessCriticalityEnum,
    BusinessCriticalityEnumFromJSON,
    BusinessCriticalityEnumFromJSONTyped,
    BusinessCriticalityEnumToJSON,
} from './BusinessCriticalityEnum';

/**
 * @type VendorBusinessCriticality
 * 
 * @export
 */
export type VendorBusinessCriticality = BlankEnum | BusinessCriticalityEnum;

export function VendorBusinessCriticalityFromJSON(json: any): VendorBusinessCriticality {
    return VendorBusinessCriticalityFromJSONTyped(json, false);
}

export function VendorBusinessCriticalityFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorBusinessCriticality {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfBusinessCriticalityEnum(json)) {
        return BusinessCriticalityEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function VendorBusinessCriticalityToJSON(value?: VendorBusinessCriticality | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfBusinessCriticalityEnum(value)) {
        return BusinessCriticalityEnumToJSON(value as BusinessCriticalityEnum);
    }

    return {};
}

