/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `open` - Open
 * * `resolved` - Resolved
 * @export
 */
export const CommentStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type CommentStatusEnum = typeof CommentStatusEnum[keyof typeof CommentStatusEnum];


export function instanceOfCommentStatusEnum(value: any): boolean {
    for (const key in CommentStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(CommentStatusEnum, key)) {
            if (CommentStatusEnum[key as keyof typeof CommentStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CommentStatusEnumFromJSON(json: any): CommentStatusEnum {
    return CommentStatusEnumFromJSONTyped(json, false);
}

export function CommentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentStatusEnum {
    return json as CommentStatusEnum;
}

export function CommentStatusEnumToJSON(value?: CommentStatusEnum | null): any {
    return value as any;
}

