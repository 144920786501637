/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReverseRelation } from './ReverseRelation';
import {
    ReverseRelationFromJSON,
    ReverseRelationFromJSONTyped,
    ReverseRelationToJSON,
} from './ReverseRelation';
import type { ThemeEnum } from './ThemeEnum';
import {
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    readonly id: number;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Dashboard
     */
    tasks?: Array<ReverseRelation>;
    /**
     * 
     * @type {Array<ReverseRelation>}
     * @memberof Dashboard
     */
    vendors?: Array<ReverseRelation>;
    /**
     * 
     * @type {Date}
     * @memberof Dashboard
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Dashboard
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Dashboard
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    overview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    summary?: string | null;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof Dashboard
     */
    theme?: ThemeEnum;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the Dashboard interface.
 */
export function instanceOfDashboard(value: object): value is Dashboard {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function DashboardFromJSON(json: any): Dashboard {
    return DashboardFromJSONTyped(json, false);
}

export function DashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dashboard {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tasks': json['tasks'] == null ? undefined : ((json['tasks'] as Array<any>).map(ReverseRelationFromJSON)),
        'vendors': json['vendors'] == null ? undefined : ((json['vendors'] as Array<any>).map(ReverseRelationFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'overview': json['overview'] == null ? undefined : json['overview'],
        'summary': json['summary'] == null ? undefined : json['summary'],
        'theme': json['theme'] == null ? undefined : ThemeEnumFromJSON(json['theme']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function DashboardToJSON(value?: Omit<Dashboard, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tasks': value['tasks'] == null ? undefined : ((value['tasks'] as Array<any>).map(ReverseRelationToJSON)),
        'vendors': value['vendors'] == null ? undefined : ((value['vendors'] as Array<any>).map(ReverseRelationToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'overview': value['overview'],
        'summary': value['summary'],
        'theme': ThemeEnumToJSON(value['theme']),
        'object_owner': value['objectOwner'],
        'company': value['company'],
        'updated_by': value['updatedBy'],
    };
}

