/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvidenceRecordRequest
 */
export interface EvidenceRecordRequest {
    /**
     * 
     * @type {number}
     * @memberof EvidenceRecordRequest
     */
    evidence: number;
    /**
     * 
     * @type {string}
     * @memberof EvidenceRecordRequest
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EvidenceRecordRequest
     */
    reportedBy?: number | null;
}

/**
 * Check if a given object implements the EvidenceRecordRequest interface.
 */
export function instanceOfEvidenceRecordRequest(value: object): value is EvidenceRecordRequest {
    if (!('evidence' in value) || value['evidence'] === undefined) return false;
    return true;
}

export function EvidenceRecordRequestFromJSON(json: any): EvidenceRecordRequest {
    return EvidenceRecordRequestFromJSONTyped(json, false);
}

export function EvidenceRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceRecordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'evidence': json['evidence'],
        'name': json['name'] == null ? undefined : json['name'],
        'reportedBy': json['reported_by'] == null ? undefined : json['reported_by'],
    };
}

export function EvidenceRecordRequestToJSON(value?: EvidenceRecordRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'evidence': value['evidence'],
        'name': value['name'],
        'reported_by': value['reportedBy'],
    };
}

