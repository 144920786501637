/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArtifactRecord } from './ArtifactRecord';
import {
    ArtifactRecordFromJSON,
    ArtifactRecordFromJSONTyped,
    ArtifactRecordToJSON,
} from './ArtifactRecord';

/**
 * 
 * @export
 * @interface EvidenceRecord
 */
export interface EvidenceRecord {
    /**
     * 
     * @type {number}
     * @memberof EvidenceRecord
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof EvidenceRecord
     */
    evidence: number;
    /**
     * 
     * @type {string}
     * @memberof EvidenceRecord
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EvidenceRecord
     */
    reportedBy?: number | null;
    /**
     * 
     * @type {Array<ArtifactRecord>}
     * @memberof EvidenceRecord
     */
    readonly artifacts: Array<ArtifactRecord>;
    /**
     * 
     * @type {Date}
     * @memberof EvidenceRecord
     */
    readonly createdAt: Date;
}

/**
 * Check if a given object implements the EvidenceRecord interface.
 */
export function instanceOfEvidenceRecord(value: object): value is EvidenceRecord {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('evidence' in value) || value['evidence'] === undefined) return false;
    if (!('artifacts' in value) || value['artifacts'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function EvidenceRecordFromJSON(json: any): EvidenceRecord {
    return EvidenceRecordFromJSONTyped(json, false);
}

export function EvidenceRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceRecord {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'evidence': json['evidence'],
        'name': json['name'] == null ? undefined : json['name'],
        'reportedBy': json['reported_by'] == null ? undefined : json['reported_by'],
        'artifacts': ((json['artifacts'] as Array<any>).map(ArtifactRecordFromJSON)),
        'createdAt': (new Date(json['created_at'])),
    };
}

export function EvidenceRecordToJSON(value?: Omit<EvidenceRecord, 'id'|'artifacts'|'created_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'evidence': value['evidence'],
        'name': value['name'],
        'reported_by': value['reportedBy'],
    };
}

