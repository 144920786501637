/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PEOPLE` - People
 * * `PROCESS` - Process
 * * `TECHNOLOGY` - Technology
 * * `DATA` - Data
 * * `FACILITY` - Facility
 * @export
 */
export const ApplicabilityToEnum = {
    People: 'PEOPLE',
    Process: 'PROCESS',
    Technology: 'TECHNOLOGY',
    Data: 'DATA',
    Facility: 'FACILITY'
} as const;
export type ApplicabilityToEnum = typeof ApplicabilityToEnum[keyof typeof ApplicabilityToEnum];


export function instanceOfApplicabilityToEnum(value: any): boolean {
    for (const key in ApplicabilityToEnum) {
        if (Object.prototype.hasOwnProperty.call(ApplicabilityToEnum, key)) {
            if (ApplicabilityToEnum[key as keyof typeof ApplicabilityToEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ApplicabilityToEnumFromJSON(json: any): ApplicabilityToEnum {
    return ApplicabilityToEnumFromJSONTyped(json, false);
}

export function ApplicabilityToEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicabilityToEnum {
    return json as ApplicabilityToEnum;
}

export function ApplicabilityToEnumToJSON(value?: ApplicabilityToEnum | null): any {
    return value as any;
}

