/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ControlStatus } from './ControlStatus';
import {
    ControlStatusFromJSON,
    ControlStatusFromJSONTyped,
    ControlStatusToJSON,
} from './ControlStatus';
import type { ControlApprovalStatus } from './ControlApprovalStatus';
import {
    ControlApprovalStatusFromJSON,
    ControlApprovalStatusFromJSONTyped,
    ControlApprovalStatusToJSON,
} from './ControlApprovalStatus';
import type { ControlAutomation } from './ControlAutomation';
import {
    ControlAutomationFromJSON,
    ControlAutomationFromJSONTyped,
    ControlAutomationToJSON,
} from './ControlAutomation';
import type { ControlControlType } from './ControlControlType';
import {
    ControlControlTypeFromJSON,
    ControlControlTypeFromJSONTyped,
    ControlControlTypeToJSON,
} from './ControlControlType';
import type { ControlFrequency } from './ControlFrequency';
import {
    ControlFrequencyFromJSON,
    ControlFrequencyFromJSONTyped,
    ControlFrequencyToJSON,
} from './ControlFrequency';
import type { ControlMaturity } from './ControlMaturity';
import {
    ControlMaturityFromJSON,
    ControlMaturityFromJSONTyped,
    ControlMaturityToJSON,
} from './ControlMaturity';

/**
 * 
 * @export
 * @interface Control
 */
export interface Control {
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    readonly id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Control
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Control
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Control
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {ControlApprovalStatus}
     * @memberof Control
     */
    approvalStatus?: ControlApprovalStatus | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    description?: string | null;
    /**
     * 
     * @type {ControlStatus}
     * @memberof Control
     */
    status?: ControlStatus | null;
    /**
     * 
     * @type {ControlControlType}
     * @memberof Control
     */
    controlType?: ControlControlType | null;
    /**
     * 
     * @type {ControlFrequency}
     * @memberof Control
     */
    frequency?: ControlFrequency | null;
    /**
     * 
     * @type {Date}
     * @memberof Control
     */
    implementedAt?: Date | null;
    /**
     * 
     * @type {ControlAutomation}
     * @memberof Control
     */
    automation?: ControlAutomation | null;
    /**
     * 
     * @type {ControlMaturity}
     * @memberof Control
     */
    maturity?: ControlMaturity | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    customId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    approvalBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    owner?: number | null;
}

/**
 * Check if a given object implements the Control interface.
 */
export function instanceOfControl(value: object): value is Control {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function ControlFromJSON(json: any): Control {
    return ControlFromJSONTyped(json, false);
}

export function ControlFromJSONTyped(json: any, ignoreDiscriminator: boolean): Control {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlApprovalStatusFromJSON(json['approval_status']),
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : ControlStatusFromJSON(json['status']),
        'controlType': json['control_type'] == null ? undefined : ControlControlTypeFromJSON(json['control_type']),
        'frequency': json['frequency'] == null ? undefined : ControlFrequencyFromJSON(json['frequency']),
        'implementedAt': json['implemented_at'] == null ? undefined : (new Date(json['implemented_at'])),
        'automation': json['automation'] == null ? undefined : ControlAutomationFromJSON(json['automation']),
        'maturity': json['maturity'] == null ? undefined : ControlMaturityFromJSON(json['maturity']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'approvalBy': json['approval_by'] == null ? undefined : json['approval_by'],
        'company': json['company'],
        'owner': json['owner'] == null ? undefined : json['owner'],
    };
}

export function ControlToJSON(value?: Omit<Control, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'object_is_public': value['objectIsPublic'],
        'approval_status': ControlApprovalStatusToJSON(value['approvalStatus']),
        'decision_details': value['decisionDetails'],
        'name': value['name'],
        'description': value['description'],
        'status': ControlStatusToJSON(value['status']),
        'control_type': ControlControlTypeToJSON(value['controlType']),
        'frequency': ControlFrequencyToJSON(value['frequency']),
        'implemented_at': value['implementedAt'] == null ? undefined : ((value['implementedAt'] as any).toISOString().substring(0,10)),
        'automation': ControlAutomationToJSON(value['automation']),
        'maturity': ControlMaturityToJSON(value['maturity']),
        'custom_id': value['customId'],
        'object_owner': value['objectOwner'],
        'approval_by': value['approvalBy'],
        'company': value['company'],
        'owner': value['owner'],
    };
}

