/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionnaireUploadDocumentRequest
 */
export interface QuestionnaireUploadDocumentRequest {
    /**
     * 
     * @type {Blob}
     * @memberof QuestionnaireUploadDocumentRequest
     */
    file: Blob;
}

/**
 * Check if a given object implements the QuestionnaireUploadDocumentRequest interface.
 */
export function instanceOfQuestionnaireUploadDocumentRequest(value: object): value is QuestionnaireUploadDocumentRequest {
    if (!('file' in value) || value['file'] === undefined) return false;
    return true;
}

export function QuestionnaireUploadDocumentRequestFromJSON(json: any): QuestionnaireUploadDocumentRequest {
    return QuestionnaireUploadDocumentRequestFromJSONTyped(json, false);
}

export function QuestionnaireUploadDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireUploadDocumentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function QuestionnaireUploadDocumentRequestToJSON(value?: QuestionnaireUploadDocumentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': value['file'],
    };
}

