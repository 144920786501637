import { AGREEMENT_TABLE, POLICY_TABLE } from "shared/helpers/constant";

import { PermissionLevel, PermissionModule } from "./types";

export const NO_ACCESS = 0;
export const CAN_VIEW = 1;
export const CAN_COMMENT = 2;
export const CAN_EDIT = 3;
export const FULL_ACCESS = 4;

export const PERMISSION_LEVELS: PermissionLevel[] = [
  NO_ACCESS,
  CAN_VIEW,
  CAN_COMMENT,
  CAN_EDIT,
  FULL_ACCESS,
];

export const PERMISSION_LABEL: Record<PermissionLevel, string> = {
  [NO_ACCESS]: "No Access",
  [CAN_VIEW]: "Can View",
  [CAN_COMMENT]: "Can Comment",
  [CAN_EDIT]: "Can Edit",
  [FULL_ACCESS]: "Full Access",
};

export const ModuleToUrlName: Record<PermissionModule, string> = {
  [AGREEMENT_TABLE]: "agreements",
  [POLICY_TABLE]: "policies",
};

export const ModuleToParamName: Record<PermissionModule, string> = {
  [AGREEMENT_TABLE]: "agreement",
  [POLICY_TABLE]: "policy",
};
