/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpactLevelRequest
 */
export interface ImpactLevelRequest {
    /**
     * 
     * @type {number}
     * @memberof ImpactLevelRequest
     */
    maximumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevelRequest
     */
    minimumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevelRequest
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevelRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevelRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevelRequest
     */
    minimumFinancialImpact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevelRequest
     */
    maximumFinancialImpact?: string | null;
}

/**
 * Check if a given object implements the ImpactLevelRequest interface.
 */
export function instanceOfImpactLevelRequest(value: object): value is ImpactLevelRequest {
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function ImpactLevelRequestFromJSON(json: any): ImpactLevelRequest {
    return ImpactLevelRequestFromJSONTyped(json, false);
}

export function ImpactLevelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpactLevelRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'maximumFinancialImpactCurrency': json['maximum_financial_impact_currency'] == null ? undefined : json['maximum_financial_impact_currency'],
        'minimumFinancialImpactCurrency': json['minimum_financial_impact_currency'] == null ? undefined : json['minimum_financial_impact_currency'],
        'score': json['score'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'minimumFinancialImpact': json['minimum_financial_impact'] == null ? undefined : json['minimum_financial_impact'],
        'maximumFinancialImpact': json['maximum_financial_impact'] == null ? undefined : json['maximum_financial_impact'],
    };
}

export function ImpactLevelRequestToJSON(value?: ImpactLevelRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maximum_financial_impact_currency': value['maximumFinancialImpactCurrency'],
        'minimum_financial_impact_currency': value['minimumFinancialImpactCurrency'],
        'score': value['score'],
        'label': value['label'],
        'description': value['description'],
        'minimum_financial_impact': value['minimumFinancialImpact'],
        'maximum_financial_impact': value['maximumFinancialImpact'],
    };
}

