import { ReactNode, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import clsx from "clsx";
import { Spinner as FlowbiteSpinner, Tooltip } from "flowbite-react";
import { cn, formatDate } from "lib/utils";
import moment from "moment";
import { Article } from "openapi";

import Icon from "shared/components/icon/Icon";
import Badge from "shared/componentsV2/form-controls/badge";
import Button from "shared/componentsV2/form-controls/button";
import Checkbox from "shared/componentsV2/form-controls/checkbox";
import { useCompany } from "shared/context/CompanyProvider";

import { useUpdateArticle } from "modules/horizonScanning/hooks/useArticles";
import {
  useArchivedFeedArticles,
  useFeedItemActionState,
} from "modules/horizonScanning/hooks/useFeedActionState";
import { getImpactColor, getImpactLabel } from "modules/horizonScanning/utils";

import ShareArticleModal from "../../shared/components/ShareArticleModal";
import { ArticleAction } from "../../types";

const FeedArticleItems = ({
  articles,
  selectedItems,
  onCheckboxChange,
  onLoadMore,
  hasNextPage,
  hideActions,
  isFetchingNextPage,
  EmptyStateComponent,
}: {
  articles: Article[];
  selectedItems: ID[];
  onCheckboxChange: (id: ID) => void;
  onLoadMore: () => void;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  hideActions?: boolean;
  EmptyStateComponent?: JSX.Element;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const { feedId } = useParams();
  const archivedArticles = useArchivedFeedArticles(Number(feedId));
  const groupedArticles = articles.reduce((acc, article) => {
    const timestamp = Number(article.publicationDate);
    const date = !isNaN(timestamp) && article.publicationDate ? formatDate(timestamp) : "No Date";

    if (!acc[date]) {
      acc[date] = [];
    }
    if (!archivedArticles.includes(`${article.id}`)) {
      acc[date].push(article);
    }
    return acc;
  }, {} as Record<string, Article[]>);

  const sortedDates = Object.keys(groupedArticles).sort((a, b) => {
    if (a === "No Date") return 1; // Move "No Date" to the end
    if (b === "No Date") return -1; // Keep other dates above "No Date"
    return moment(b).diff(moment(a)); // Descending order for valid dates
  });

  const handleAction = (article: Article, action: ArticleAction) => {
    if (action === "share") {
      setSelectedArticle(article);
      setShareModalOpen(true);
    }
  };

  return sortedDates.length ? (
    <div className="grid gap-2 rounded-lg border-y border-antiflashwhite px-8 py-3 dark:border-thunders">
      {sortedDates.map((date) => (
        <div key={date} className="grid gap-2">
          {groupedArticles[date].map((item, itemIndex) => (
            <FeedArticleItem
              selected={selectedItems.includes(item.id)}
              key={itemIndex}
              item={item}
              onToggle={onCheckboxChange}
              onAction={handleAction}
              hideActions={hideActions}
            />
          ))}
          {groupedArticles[date].length > 0 && (
            <div className="my-4 flex items-center">
              <div className="flex-grow border-t border-dashed border-gray-300 dark:border-jet"></div>
              <span className="mx-4 flex-shrink text-sm font-medium text-gray-700 dark:text-gray-400">
                {date}
              </span>
              <div className="flex-grow border-t border-dashed border-gray-300 dark:border-jet"></div>
            </div>
          )}
        </div>
      ))}
      {isFetchingNextPage ? (
        <div className="flex items-center justify-center">
          <FlowbiteSpinner size="md" />
        </div>
      ) : null}
      {hasNextPage && !isFetchingNextPage ? (
        <div className="flex items-center justify-center">
          <Button
            btnType="secondary"
            btnSize="sm"
            btnTitle="Load 10 more articles"
            onClick={onLoadMore}
            className="w-fit"
          />
        </div>
      ) : null}
      {selectedArticle && (
        <ShareArticleModal
          article={selectedArticle}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
        />
      )}
    </div>
  ) : (
    EmptyStateComponent ?? <></>
  );
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "bg-gray-400";
    case "in_progress":
      return "bg-yellow-300";
    case "completed":
      return "bg-green-400";
    default:
      return "bg-orange-400";
  }
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "in_progress":
      return "In Progress";
    case "completed":
      return "Completed";
    default:
      return "";
  }
};

export const FeedArticleItem = ({
  item: article,
  selected,
  hideActions,
  onToggle,
  onAction,
}: {
  item: Article;
  hideActions?: boolean;
  selected?: boolean;
  onToggle?: (id: number) => void;
  onAction: (article: Article, action: ArticleAction) => void;
}) => {
  const navigate = useNavigate();
  const { currentCompany } = useCompany();
  const { feedId } = useParams();

  const stateAction = useFeedItemActionState({ feedId: Number(feedId), articleId: article.id });

  const isRead = stateAction.isRead ?? article.isRead;
  const isSaved = stateAction.isSaved ?? article.isSaved;
  const isArchived = stateAction.isArchived ?? article.isArchived;

  const updateArticle = useUpdateArticle({
    feedId: Number(feedId),
    companyId: currentCompany.id,
    id: article.id,
  });

  const getPublicationDate = useCallback(() => {
    const timestamp = Number(article.publicationDate);
    return !isNaN(timestamp) && article.publicationDate ? formatDate(timestamp) : "";
  }, [article.publicationDate]);

  const goToArticle = (item: Article) => {
    if (hideActions) {
      return;
    }
    navigate(`/workspace/${currentCompany.id}/horizon-scanning/feeds/${feedId}/article/${item.id}`);
  };

  const onOptimisticSuccessCallback = (str: string) => {
    toast(str, { type: "success" });
  };
  if (isArchived) return null;

  return (
    <div className="rounded-lg border border-gray-200 bg-white px-3 py-4 shadow-sm dark:border-jet dark:bg-mirage">
      <div className="flex items-start gap-3">
        <div
          className={clsx("hover:opacity-100", selected ? "opacity-100" : "opacity-0", {
            "invisible pointer-events-none": !onToggle,
          })}
        >
          <Checkbox
            checked={selected}
            wrapperClassName="!pr-0 !pt-0"
            onChange={() => {
              onToggle?.(article.id);
            }}
          />
        </div>
        <div className="grid w-full">
          <div className="flex items-center justify-between">
            <h5 className="text-xs font-medium text-gray-500">{article.publisher.title}</h5>
            <div className="flex items-center gap-2">
              <h5 className="text-xs font-medium text-gray-500">{getPublicationDate()}</h5>
            </div>
          </div>
          <div className="h-2" />
          <h2
            className="cursor-pointer text-lg font-semibold text-gray-900 dark:text-gray-400"
            onClick={() => goToArticle(article)}
          >
            {article.title}
          </h2>
          <div className="h-3" />
          <h4 className="text-sm font-normal text-gray-600 dark:text-gray-400">
            <span className="font-medium text-gray-900 dark:text-gray-500">Original Title: </span>
            {article.originalTitle}
          </h4>
          <div className="h-3" />
          <p className="break-words text-sm font-normal text-gray-600 dark:text-gray-400">
            <span className="font-medium text-gray-900 dark:text-gray-500">Summary: </span>
            {article.description}
          </p>
          <div className="h-4" />
          {!hideActions && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                {article.tags?.map((tag) => (
                  <Badge
                    key={tag.id}
                    badgeTitle={tag.name}
                    badgeColor="#1C64F2"
                    className="!bg-lavender"
                  />
                ))}
              </div>
              <div className="flex items-center gap-3">
                {article.status && (
                  <ItemBadge
                    label={getStatusLabel(article.status)}
                    icon={
                      <span
                        className={clsx("h-2 w-2 rounded-xl", getStatusColor(article.status))}
                      />
                    }
                    className="capitalize"
                  />
                )}
                {article.impact && article.impact !== "not_set" && (
                  <ItemBadge
                    label={getImpactLabel(article.impact)}
                    icon={
                      <Icon
                        type="arrows-pointing-in"
                        fill
                        size="icon-xs"
                        badgeColor={getImpactColor(article.impact)}
                      />
                    }
                  />
                )}
                {article.hasClassification && (
                  <Badge
                    badgeTitle="Classified"
                    icon={<Icon type="squares-check" fill={true} size="icon-xs" />}
                    badgeColor="#4B5563"
                  />
                )}
                <div className="flex items-center gap-1">
                  <Icon
                    type="chat-bubble-oval-left-ellipsis"
                    fill={true}
                    size="icon-xs"
                    className={article.commentsCount > 0 ? "icon-blue-600" : "three_dots_icon"}
                  />
                  <span
                    className={cn(
                      "text-xs font-medium",
                      article.commentsCount > 0 ? "text-blue-600" : "text-gray-500"
                    )}
                  >
                    {article.commentsCount}
                  </span>
                </div>
                <Tooltip
                  content={isSaved ? "Saved" : "Save"}
                  arrow={false}
                  placement="top"
                  className="metadata_tooltip"
                >
                  <button
                    type="button"
                    className="block"
                    onClick={() => (isSaved ? updateArticle.unsave() : updateArticle.save())}
                  >
                    <Icon
                      type="bookmark"
                      fill={true}
                      size="icon-xs"
                      className={isSaved ? "icon-blue-600" : ""}
                    />
                  </button>
                </Tooltip>
                <Tooltip
                  content={isRead ? `This article was marked as read` : "Mark as read"}
                  className="metadata_tooltip"
                  arrow={false}
                  placement="top"
                >
                  <button
                    type="button"
                    className="block"
                    onClick={() => (isRead ? updateArticle.unread() : updateArticle.read())}
                  >
                    <Icon
                      type="book-open"
                      fill={true}
                      size="icon-xs"
                      className={cn(isRead && "icon-blue-600")}
                    />
                  </button>
                </Tooltip>
                <Tooltip
                  content={isArchived ? "Archived" : "Archive"}
                  arrow={false}
                  placement="top"
                  className="metadata_tooltip"
                >
                  <button
                    type="button"
                    className="block"
                    onClick={() =>
                      isArchived
                        ? updateArticle.unarchive(() =>
                            onOptimisticSuccessCallback("Article unarchived")
                          )
                        : updateArticle.archive(() =>
                            onOptimisticSuccessCallback("Article archived")
                          )
                    }
                  >
                    <Icon
                      type="archive-box-fill"
                      fill={!isArchived}
                      size="icon-xs"
                      className={isArchived ? "icon-blue-600-no-stroke" : ""}
                    />
                  </button>
                </Tooltip>
                <Tooltip content="Share" arrow={false} placement="top" className="metadata_tooltip">
                  <button type="button" onClick={() => onAction(article, "share")}>
                    <Icon type="share-2" fill={true} size="icon-xs" />
                  </button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ItemBadge = ({
  label,
  icon,
  onClick,
  className,
}: {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <span
      className={clsx(
        "inline-flex cursor-pointer items-center gap-1 rounded-md border border-gray-200 px-1.5 py-0.5 text-xs text-gray-600 dark:border-thunders dark:text-gray-500",
        className
      )}
      onClick={onClick}
    >
      {icon}
      {label}
    </span>
  );
};

export default FeedArticleItems;
