/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThemeEnum } from './ThemeEnum';
import {
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface PatchedDashboardRequest
 */
export interface PatchedDashboardRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedDashboardRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedDashboardRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDashboardRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedDashboardRequest
     */
    overview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedDashboardRequest
     */
    summary?: string | null;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof PatchedDashboardRequest
     */
    theme?: ThemeEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedDashboardRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedDashboardRequest
     */
    company?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedDashboardRequest
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the PatchedDashboardRequest interface.
 */
export function instanceOfPatchedDashboardRequest(value: object): value is PatchedDashboardRequest {
    return true;
}

export function PatchedDashboardRequestFromJSON(json: any): PatchedDashboardRequest {
    return PatchedDashboardRequestFromJSONTyped(json, false);
}

export function PatchedDashboardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDashboardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'overview': json['overview'] == null ? undefined : json['overview'],
        'summary': json['summary'] == null ? undefined : json['summary'],
        'theme': json['theme'] == null ? undefined : ThemeEnumFromJSON(json['theme']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function PatchedDashboardRequestToJSON(value?: PatchedDashboardRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'overview': value['overview'],
        'summary': value['summary'],
        'theme': ThemeEnumToJSON(value['theme']),
        'object_owner': value['objectOwner'],
        'company': value['company'],
        'updated_by': value['updatedBy'],
    };
}

