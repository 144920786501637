/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskRegisterRawRiskRequest
 */
export interface RiskRegisterRawRiskRequest {
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    riskArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    riskTarget?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    riskType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    riskSubType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    inherentRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    inherentRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    residualRiskImpact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    residualRiskLikelihood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    inherentAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    inherentSingleLossExpectancyCurrency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    inherentSingleLossExpectancyValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    residualAnnualizedRateOfOccurrence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskRegisterRawRiskRequest
     */
    residualSingleLossExpectancyCurrency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskRegisterRawRiskRequest
     */
    residualSingleLossExpectancyValue?: number | null;
}

/**
 * Check if a given object implements the RiskRegisterRawRiskRequest interface.
 */
export function instanceOfRiskRegisterRawRiskRequest(value: object): value is RiskRegisterRawRiskRequest {
    return true;
}

export function RiskRegisterRawRiskRequestFromJSON(json: any): RiskRegisterRawRiskRequest {
    return RiskRegisterRawRiskRequestFromJSONTyped(json, false);
}

export function RiskRegisterRawRiskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskRegisterRawRiskRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'riskArea': json['risk_area'] == null ? undefined : json['risk_area'],
        'riskTarget': json['risk_target'] == null ? undefined : json['risk_target'],
        'riskType': json['risk_type'] == null ? undefined : json['risk_type'],
        'riskSubType': json['risk_sub_type'] == null ? undefined : json['risk_sub_type'],
        'inherentRiskImpact': json['inherent_risk_impact'] == null ? undefined : json['inherent_risk_impact'],
        'inherentRiskLikelihood': json['inherent_risk_likelihood'] == null ? undefined : json['inherent_risk_likelihood'],
        'residualRiskImpact': json['residual_risk_impact'] == null ? undefined : json['residual_risk_impact'],
        'residualRiskLikelihood': json['residual_risk_likelihood'] == null ? undefined : json['residual_risk_likelihood'],
        'inherentAnnualizedRateOfOccurrence': json['inherent_annualized_rate_of_occurrence'] == null ? undefined : json['inherent_annualized_rate_of_occurrence'],
        'inherentSingleLossExpectancyCurrency': json['inherent_single_loss_expectancy_currency'] == null ? undefined : json['inherent_single_loss_expectancy_currency'],
        'inherentSingleLossExpectancyValue': json['inherent_single_loss_expectancy_value'] == null ? undefined : json['inherent_single_loss_expectancy_value'],
        'residualAnnualizedRateOfOccurrence': json['residual_annualized_rate_of_occurrence'] == null ? undefined : json['residual_annualized_rate_of_occurrence'],
        'residualSingleLossExpectancyCurrency': json['residual_single_loss_expectancy_currency'] == null ? undefined : json['residual_single_loss_expectancy_currency'],
        'residualSingleLossExpectancyValue': json['residual_single_loss_expectancy_value'] == null ? undefined : json['residual_single_loss_expectancy_value'],
    };
}

export function RiskRegisterRawRiskRequestToJSON(value?: RiskRegisterRawRiskRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'custom_id': value['customId'],
        'risk_area': value['riskArea'],
        'risk_target': value['riskTarget'],
        'risk_type': value['riskType'],
        'risk_sub_type': value['riskSubType'],
        'inherent_risk_impact': value['inherentRiskImpact'],
        'inherent_risk_likelihood': value['inherentRiskLikelihood'],
        'residual_risk_impact': value['residualRiskImpact'],
        'residual_risk_likelihood': value['residualRiskLikelihood'],
        'inherent_annualized_rate_of_occurrence': value['inherentAnnualizedRateOfOccurrence'],
        'inherent_single_loss_expectancy_currency': value['inherentSingleLossExpectancyCurrency'],
        'inherent_single_loss_expectancy_value': value['inherentSingleLossExpectancyValue'],
        'residual_annualized_rate_of_occurrence': value['residualAnnualizedRateOfOccurrence'],
        'residual_single_loss_expectancy_currency': value['residualSingleLossExpectancyCurrency'],
        'residual_single_loss_expectancy_value': value['residualSingleLossExpectancyValue'],
    };
}

