/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedQuestionnaireCommentList,
  PaginatedQuestionnaireDetailList,
  PaginatedQuestionnaireListList,
  PaginatedQuestionnaireTemplateList,
  PatchedQuestionnaireCommentRequest,
  PatchedQuestionnaireListModifyRequest,
  PatchedQuestionnaireListRequest,
  PatchedQuestionnaireRequest,
  PatchedQuestionnaireTemplateRequest,
  QuestionnaireComment,
  QuestionnaireCommentRequest,
  QuestionnaireDetail,
  QuestionnaireFromTemplateRequest,
  QuestionnaireList,
  QuestionnaireListModify,
  QuestionnaireListRequest,
  QuestionnaireRequest,
  QuestionnaireTemplate,
  QuestionnaireTemplateRequest,
  QuestionnaireUploadDocumentRequest,
} from '../models/index';
import {
    PaginatedQuestionnaireCommentListFromJSON,
    PaginatedQuestionnaireCommentListToJSON,
    PaginatedQuestionnaireDetailListFromJSON,
    PaginatedQuestionnaireDetailListToJSON,
    PaginatedQuestionnaireListListFromJSON,
    PaginatedQuestionnaireListListToJSON,
    PaginatedQuestionnaireTemplateListFromJSON,
    PaginatedQuestionnaireTemplateListToJSON,
    PatchedQuestionnaireCommentRequestFromJSON,
    PatchedQuestionnaireCommentRequestToJSON,
    PatchedQuestionnaireListModifyRequestFromJSON,
    PatchedQuestionnaireListModifyRequestToJSON,
    PatchedQuestionnaireListRequestFromJSON,
    PatchedQuestionnaireListRequestToJSON,
    PatchedQuestionnaireRequestFromJSON,
    PatchedQuestionnaireRequestToJSON,
    PatchedQuestionnaireTemplateRequestFromJSON,
    PatchedQuestionnaireTemplateRequestToJSON,
    QuestionnaireCommentFromJSON,
    QuestionnaireCommentToJSON,
    QuestionnaireCommentRequestFromJSON,
    QuestionnaireCommentRequestToJSON,
    QuestionnaireDetailFromJSON,
    QuestionnaireDetailToJSON,
    QuestionnaireFromTemplateRequestFromJSON,
    QuestionnaireFromTemplateRequestToJSON,
    QuestionnaireListFromJSON,
    QuestionnaireListToJSON,
    QuestionnaireListModifyFromJSON,
    QuestionnaireListModifyToJSON,
    QuestionnaireListRequestFromJSON,
    QuestionnaireListRequestToJSON,
    QuestionnaireRequestFromJSON,
    QuestionnaireRequestToJSON,
    QuestionnaireTemplateFromJSON,
    QuestionnaireTemplateToJSON,
    QuestionnaireTemplateRequestFromJSON,
    QuestionnaireTemplateRequestToJSON,
    QuestionnaireUploadDocumentRequestFromJSON,
    QuestionnaireUploadDocumentRequestToJSON,
} from '../models/index';

export interface QuestionnairesQuestionnaireCommentsCreateRequest {
    company: number;
    questionnaireCommentRequest: QuestionnaireCommentRequest;
}

export interface QuestionnairesQuestionnaireCommentsDestroyRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireCommentsListRequest {
    company: number;
    page?: number;
    pageSize?: number;
    status?: QuestionnairesQuestionnaireCommentsListStatusEnum;
}

export interface QuestionnairesQuestionnaireCommentsPartialUpdateRequest {
    company: number;
    id: number;
    patchedQuestionnaireCommentRequest?: PatchedQuestionnaireCommentRequest;
}

export interface QuestionnairesQuestionnaireCommentsRetrieveRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireCommentsUpdateRequest {
    company: number;
    id: number;
    questionnaireCommentRequest: QuestionnaireCommentRequest;
}

export interface QuestionnairesQuestionnaireListsAddQuestionnairesPartialUpdateRequest {
    id: number;
    patchedQuestionnaireListModifyRequest?: PatchedQuestionnaireListModifyRequest;
}

export interface QuestionnairesQuestionnaireListsCreateRequest {
    company: number;
    questionnaireListRequest: QuestionnaireListRequest;
}

export interface QuestionnairesQuestionnaireListsDestroyRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireListsListRequest {
    company: number;
    name?: string;
    nameIcontains?: string;
    nameSearch?: string;
    page?: number;
    pageSize?: number;
}

export interface QuestionnairesQuestionnaireListsPartialUpdateRequest {
    company: number;
    id: number;
    patchedQuestionnaireListRequest?: PatchedQuestionnaireListRequest;
}

export interface QuestionnairesQuestionnaireListsRetrieveRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireListsUpdateRequest {
    company: number;
    id: number;
    questionnaireListRequest: QuestionnaireListRequest;
}

export interface QuestionnairesQuestionnaireTemplatesCreateRequest {
    company: number;
    questionnaireTemplateRequest: QuestionnaireTemplateRequest;
}

export interface QuestionnairesQuestionnaireTemplatesDestroyRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireTemplatesListRequest {
    company: number;
    category?: QuestionnairesQuestionnaireTemplatesListCategoryEnum;
    categoryIn?: Array<string>;
    documentType?: QuestionnairesQuestionnaireTemplatesListDocumentTypeEnum;
    documentTypeIn?: Array<string>;
    name?: string;
    nameIcontains?: string;
    nameSearch?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface QuestionnairesQuestionnaireTemplatesPartialUpdateRequest {
    company: number;
    id: number;
    patchedQuestionnaireTemplateRequest?: PatchedQuestionnaireTemplateRequest;
}

export interface QuestionnairesQuestionnaireTemplatesRetrieveRequest {
    company: number;
    id: number;
}

export interface QuestionnairesQuestionnaireTemplatesUpdateRequest {
    company: number;
    id: number;
    questionnaireTemplateRequest: QuestionnaireTemplateRequest;
}

export interface QuestionnairesQuestionnairesCreateRequest {
    company: number;
    questionnaireRequest: QuestionnaireRequest;
    list?: number;
}

export interface QuestionnairesQuestionnairesCreateFromTemplateCreateRequest {
    questionnaireFromTemplateRequest: QuestionnaireFromTemplateRequest;
}

export interface QuestionnairesQuestionnairesDestroyRequest {
    company: number;
    id: number;
    list?: number;
}

export interface QuestionnairesQuestionnairesDocumentCreateRequest {
    id: number;
    questionnaireUploadDocumentRequest: QuestionnaireUploadDocumentRequest;
}

export interface QuestionnairesQuestionnairesDocumentDestroyRequest {
    id: number;
}

export interface QuestionnairesQuestionnairesListRequest {
    company: number;
    documentType?: QuestionnairesQuestionnairesListDocumentTypeEnum;
    documentTypeIn?: Array<string>;
    dueDate?: Date;
    dueDateGt?: Date;
    dueDateIn?: Array<Date>;
    dueDateLt?: Date;
    dueDateRange?: Array<Date>;
    list?: number;
    name?: string;
    nameIcontains?: string;
    nameSearch?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
    questionnaireType?: QuestionnairesQuestionnairesListQuestionnaireTypeEnum;
    questionnaireTypeIn?: Array<string>;
    status?: QuestionnairesQuestionnairesListStatusEnum;
    statusIn?: Array<string>;
    tagsIn?: Array<string>;
}

export interface QuestionnairesQuestionnairesPartialUpdateRequest {
    company: number;
    id: number;
    list?: number;
    patchedQuestionnaireRequest?: PatchedQuestionnaireRequest;
}

export interface QuestionnairesQuestionnairesRetrieveRequest {
    company: number;
    id: number;
    list?: number;
}

export interface QuestionnairesQuestionnairesUpdateRequest {
    company: number;
    id: number;
    questionnaireRequest: QuestionnaireRequest;
    list?: number;
}

/**
 * 
 */
export class QuestionnairesApi extends runtime.BaseAPI {

    /**
     */
    async questionnairesQuestionnaireCommentsCreateRaw(requestParameters: QuestionnairesQuestionnaireCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireComment>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsCreate().'
            );
        }

        if (requestParameters['questionnaireCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireCommentRequest',
                'Required parameter "questionnaireCommentRequest" was null or undefined when calling questionnairesQuestionnaireCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireCommentRequestToJSON(requestParameters['questionnaireCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireCommentFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireCommentsCreate(requestParameters: QuestionnairesQuestionnaireCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireComment> {
        const response = await this.questionnairesQuestionnaireCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireCommentsDestroyRaw(requestParameters: QuestionnairesQuestionnaireCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async questionnairesQuestionnaireCommentsDestroy(requestParameters: QuestionnairesQuestionnaireCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.questionnairesQuestionnaireCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async questionnairesQuestionnaireCommentsListRaw(requestParameters: QuestionnairesQuestionnaireCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedQuestionnaireCommentList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedQuestionnaireCommentListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireCommentsList(requestParameters: QuestionnairesQuestionnaireCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedQuestionnaireCommentList> {
        const response = await this.questionnairesQuestionnaireCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireCommentsPartialUpdateRaw(requestParameters: QuestionnairesQuestionnaireCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireComment>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedQuestionnaireCommentRequestToJSON(requestParameters['patchedQuestionnaireCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireCommentFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireCommentsPartialUpdate(requestParameters: QuestionnairesQuestionnaireCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireComment> {
        const response = await this.questionnairesQuestionnaireCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireCommentsRetrieveRaw(requestParameters: QuestionnairesQuestionnaireCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireComment>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireCommentFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireCommentsRetrieve(requestParameters: QuestionnairesQuestionnaireCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireComment> {
        const response = await this.questionnairesQuestionnaireCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireCommentsUpdateRaw(requestParameters: QuestionnairesQuestionnaireCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireComment>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireCommentsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireCommentsUpdate().'
            );
        }

        if (requestParameters['questionnaireCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireCommentRequest',
                'Required parameter "questionnaireCommentRequest" was null or undefined when calling questionnairesQuestionnaireCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireCommentRequestToJSON(requestParameters['questionnaireCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireCommentFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireCommentsUpdate(requestParameters: QuestionnairesQuestionnaireCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireComment> {
        const response = await this.questionnairesQuestionnaireCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsAddQuestionnairesPartialUpdateRaw(requestParameters: QuestionnairesQuestionnaireListsAddQuestionnairesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireListModify>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireListsAddQuestionnairesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/{id}/add_questionnaires/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedQuestionnaireListModifyRequestToJSON(requestParameters['patchedQuestionnaireListModifyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListModifyFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsAddQuestionnairesPartialUpdate(requestParameters: QuestionnairesQuestionnaireListsAddQuestionnairesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireListModify> {
        const response = await this.questionnairesQuestionnaireListsAddQuestionnairesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsCreateRaw(requestParameters: QuestionnairesQuestionnaireListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsCreate().'
            );
        }

        if (requestParameters['questionnaireListRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireListRequest',
                'Required parameter "questionnaireListRequest" was null or undefined when calling questionnairesQuestionnaireListsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireListRequestToJSON(requestParameters['questionnaireListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsCreate(requestParameters: QuestionnairesQuestionnaireListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireList> {
        const response = await this.questionnairesQuestionnaireListsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsDestroyRaw(requestParameters: QuestionnairesQuestionnaireListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireListsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async questionnairesQuestionnaireListsDestroy(requestParameters: QuestionnairesQuestionnaireListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.questionnairesQuestionnaireListsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async questionnairesQuestionnaireListsListRaw(requestParameters: QuestionnairesQuestionnaireListsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedQuestionnaireListList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['nameSearch'] != null) {
            queryParameters['name__search'] = requestParameters['nameSearch'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedQuestionnaireListListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsList(requestParameters: QuestionnairesQuestionnaireListsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedQuestionnaireListList> {
        const response = await this.questionnairesQuestionnaireListsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsPartialUpdateRaw(requestParameters: QuestionnairesQuestionnaireListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireListsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedQuestionnaireListRequestToJSON(requestParameters['patchedQuestionnaireListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsPartialUpdate(requestParameters: QuestionnairesQuestionnaireListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireList> {
        const response = await this.questionnairesQuestionnaireListsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsRetrieveRaw(requestParameters: QuestionnairesQuestionnaireListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireListsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsRetrieve(requestParameters: QuestionnairesQuestionnaireListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireList> {
        const response = await this.questionnairesQuestionnaireListsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireListsUpdateRaw(requestParameters: QuestionnairesQuestionnaireListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireListsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireListsUpdate().'
            );
        }

        if (requestParameters['questionnaireListRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireListRequest',
                'Required parameter "questionnaireListRequest" was null or undefined when calling questionnairesQuestionnaireListsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireListRequestToJSON(requestParameters['questionnaireListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireListsUpdate(requestParameters: QuestionnairesQuestionnaireListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireList> {
        const response = await this.questionnairesQuestionnaireListsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesCreateRaw(requestParameters: QuestionnairesQuestionnaireTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireTemplate>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesCreate().'
            );
        }

        if (requestParameters['questionnaireTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireTemplateRequest',
                'Required parameter "questionnaireTemplateRequest" was null or undefined when calling questionnairesQuestionnaireTemplatesCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateRequestToJSON(requestParameters['questionnaireTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesCreate(requestParameters: QuestionnairesQuestionnaireTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireTemplate> {
        const response = await this.questionnairesQuestionnaireTemplatesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesDestroyRaw(requestParameters: QuestionnairesQuestionnaireTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireTemplatesDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesDestroy(requestParameters: QuestionnairesQuestionnaireTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.questionnairesQuestionnaireTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesListRaw(requestParameters: QuestionnairesQuestionnaireTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedQuestionnaireTemplateList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        if (requestParameters['categoryIn'] != null) {
            queryParameters['category__in'] = requestParameters['categoryIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['document_type'] = requestParameters['documentType'];
        }

        if (requestParameters['documentTypeIn'] != null) {
            queryParameters['document_type__in'] = requestParameters['documentTypeIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['nameSearch'] != null) {
            queryParameters['name__search'] = requestParameters['nameSearch'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedQuestionnaireTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesList(requestParameters: QuestionnairesQuestionnaireTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedQuestionnaireTemplateList> {
        const response = await this.questionnairesQuestionnaireTemplatesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesPartialUpdateRaw(requestParameters: QuestionnairesQuestionnaireTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireTemplate>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireTemplatesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedQuestionnaireTemplateRequestToJSON(requestParameters['patchedQuestionnaireTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesPartialUpdate(requestParameters: QuestionnairesQuestionnaireTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireTemplate> {
        const response = await this.questionnairesQuestionnaireTemplatesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesRetrieveRaw(requestParameters: QuestionnairesQuestionnaireTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireTemplate>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireTemplatesRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesRetrieve(requestParameters: QuestionnairesQuestionnaireTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireTemplate> {
        const response = await this.questionnairesQuestionnaireTemplatesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesUpdateRaw(requestParameters: QuestionnairesQuestionnaireTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireTemplate>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnaireTemplatesUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnaireTemplatesUpdate().'
            );
        }

        if (requestParameters['questionnaireTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireTemplateRequest',
                'Required parameter "questionnaireTemplateRequest" was null or undefined when calling questionnairesQuestionnaireTemplatesUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaire-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateRequestToJSON(requestParameters['questionnaireTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnaireTemplatesUpdate(requestParameters: QuestionnairesQuestionnaireTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireTemplate> {
        const response = await this.questionnairesQuestionnaireTemplatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesCreateRaw(requestParameters: QuestionnairesQuestionnairesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesCreate().'
            );
        }

        if (requestParameters['questionnaireRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireRequest',
                'Required parameter "questionnaireRequest" was null or undefined when calling questionnairesQuestionnairesCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireRequestToJSON(requestParameters['questionnaireRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesCreate(requestParameters: QuestionnairesQuestionnairesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesCreateFromTemplateCreateRaw(requestParameters: QuestionnairesQuestionnairesCreateFromTemplateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['questionnaireFromTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireFromTemplateRequest',
                'Required parameter "questionnaireFromTemplateRequest" was null or undefined when calling questionnairesQuestionnairesCreateFromTemplateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/create_from_template/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireFromTemplateRequestToJSON(requestParameters['questionnaireFromTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesCreateFromTemplateCreate(requestParameters: QuestionnairesQuestionnairesCreateFromTemplateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesCreateFromTemplateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesDestroyRaw(requestParameters: QuestionnairesQuestionnairesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async questionnairesQuestionnairesDestroy(requestParameters: QuestionnairesQuestionnairesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.questionnairesQuestionnairesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async questionnairesQuestionnairesDocumentCreateRaw(requestParameters: QuestionnairesQuestionnairesDocumentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesDocumentCreate().'
            );
        }

        if (requestParameters['questionnaireUploadDocumentRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireUploadDocumentRequest',
                'Required parameter "questionnaireUploadDocumentRequest" was null or undefined when calling questionnairesQuestionnairesDocumentCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/document/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireUploadDocumentRequestToJSON(requestParameters['questionnaireUploadDocumentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesDocumentCreate(requestParameters: QuestionnairesQuestionnairesDocumentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesDocumentCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesDocumentDestroyRaw(requestParameters: QuestionnairesQuestionnairesDocumentDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesDocumentDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/document/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesDocumentDestroy(requestParameters: QuestionnairesQuestionnairesDocumentDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesDocumentDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesListRaw(requestParameters: QuestionnairesQuestionnairesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedQuestionnaireDetailList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['document_type'] = requestParameters['documentType'];
        }

        if (requestParameters['documentTypeIn'] != null) {
            queryParameters['document_type__in'] = requestParameters['documentTypeIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['dueDate'] != null) {
            queryParameters['due_date'] = (requestParameters['dueDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dueDateGt'] != null) {
            queryParameters['due_date__gt'] = (requestParameters['dueDateGt'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dueDateIn'] != null) {
            queryParameters['due_date__in'] = requestParameters['dueDateIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['dueDateLt'] != null) {
            queryParameters['due_date__lt'] = (requestParameters['dueDateLt'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dueDateRange'] != null) {
            queryParameters['due_date__range'] = requestParameters['dueDateRange']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['nameSearch'] != null) {
            queryParameters['name__search'] = requestParameters['nameSearch'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['questionnaireType'] != null) {
            queryParameters['questionnaire_type'] = requestParameters['questionnaireType'];
        }

        if (requestParameters['questionnaireTypeIn'] != null) {
            queryParameters['questionnaire_type__in'] = requestParameters['questionnaireTypeIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusIn'] != null) {
            queryParameters['status__in'] = requestParameters['statusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['tagsIn'] != null) {
            queryParameters['tags__in'] = requestParameters['tagsIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedQuestionnaireDetailListFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesList(requestParameters: QuestionnairesQuestionnairesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedQuestionnaireDetailList> {
        const response = await this.questionnairesQuestionnairesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesPartialUpdateRaw(requestParameters: QuestionnairesQuestionnairesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedQuestionnaireRequestToJSON(requestParameters['patchedQuestionnaireRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesPartialUpdate(requestParameters: QuestionnairesQuestionnairesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesRetrieveRaw(requestParameters: QuestionnairesQuestionnairesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesRetrieve(requestParameters: QuestionnairesQuestionnairesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async questionnairesQuestionnairesUpdateRaw(requestParameters: QuestionnairesQuestionnairesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionnaireDetail>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling questionnairesQuestionnairesUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling questionnairesQuestionnairesUpdate().'
            );
        }

        if (requestParameters['questionnaireRequest'] == null) {
            throw new runtime.RequiredError(
                'questionnaireRequest',
                'Required parameter "questionnaireRequest" was null or undefined when calling questionnairesQuestionnairesUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['list'] != null) {
            queryParameters['list'] = requestParameters['list'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/questionnaires/questionnaires/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireRequestToJSON(requestParameters['questionnaireRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireDetailFromJSON(jsonValue));
    }

    /**
     */
    async questionnairesQuestionnairesUpdate(requestParameters: QuestionnairesQuestionnairesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionnaireDetail> {
        const response = await this.questionnairesQuestionnairesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const QuestionnairesQuestionnaireCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type QuestionnairesQuestionnaireCommentsListStatusEnum = typeof QuestionnairesQuestionnaireCommentsListStatusEnum[keyof typeof QuestionnairesQuestionnaireCommentsListStatusEnum];
/**
 * @export
 */
export const QuestionnairesQuestionnaireTemplatesListCategoryEnum = {
    BusinessContinuityPlans: 'business_continuity_plans',
    ChangeManagementProcessNpap: 'change_management_process_npap',
    CloudSecurityAssessment: 'cloud_security_assessment',
    CodeOfConduct: 'code_of_conduct',
    ComplianceEvaluation: 'compliance_evaluation',
    ContractAssessment: 'contract_assessment',
    CorporateSocialResponsibilitySurveys: 'corporate_social_responsibility_surveys',
    DataPrivacy: 'data_privacy',
    DisasterRecoveryPlans: 'disaster_recovery_plans',
    EnvironmentalSocialGovernance: 'environmental_social_governance',
    FinancialAssessmentSanctionScreening: 'financial_assessment_sanction_screening',
    InformationSecurityCybersecurity: 'information_security_cybersecurity',
    InsuranceAssessment: 'insurance_assessment',
    KnowYourVendor: 'know_your_vendor',
    LegalEvaluation: 'legal_evaluation',
    MarketPositionAssessment: 'market_position_assessment',
    OffshoreAssessment: 'offshore_assessment',
    OnsiteVisits: 'onsite_visits',
    OutsourcingArrangementAssessment: 'outsourcing_arrangement_assessment',
    ReputationalAssessmentAdverseMediaScreening: 'reputational_assessment_adverse_media_screening',
    ResolvabilityAssessment: 'resolvability_assessment',
    StrategicAlignmentEvaluation: 'strategic_alignment_evaluation'
} as const;
export type QuestionnairesQuestionnaireTemplatesListCategoryEnum = typeof QuestionnairesQuestionnaireTemplatesListCategoryEnum[keyof typeof QuestionnairesQuestionnaireTemplatesListCategoryEnum];
/**
 * @export
 */
export const QuestionnairesQuestionnaireTemplatesListDocumentTypeEnum = {
    Excel: 'excel'
} as const;
export type QuestionnairesQuestionnaireTemplatesListDocumentTypeEnum = typeof QuestionnairesQuestionnaireTemplatesListDocumentTypeEnum[keyof typeof QuestionnairesQuestionnaireTemplatesListDocumentTypeEnum];
/**
 * @export
 */
export const QuestionnairesQuestionnairesListDocumentTypeEnum = {
    Excel: 'excel'
} as const;
export type QuestionnairesQuestionnairesListDocumentTypeEnum = typeof QuestionnairesQuestionnairesListDocumentTypeEnum[keyof typeof QuestionnairesQuestionnairesListDocumentTypeEnum];
/**
 * @export
 */
export const QuestionnairesQuestionnairesListQuestionnaireTypeEnum = {
    BusinessContinuityPlans: 'business_continuity_plans',
    ChangeManagementProcessNpap: 'change_management_process_npap',
    CloudSecurityAssessment: 'cloud_security_assessment',
    CodeOfConduct: 'code_of_conduct',
    ComplianceEvaluation: 'compliance_evaluation',
    ContractAssessment: 'contract_assessment',
    CorporateSocialResponsibilitySurveys: 'corporate_social_responsibility_surveys',
    DataPrivacy: 'data_privacy',
    DisasterRecoveryPlans: 'disaster_recovery_plans',
    EnvironmentalSocialGovernance: 'environmental_social_governance',
    FinancialAssessmentSanctionScreening: 'financial_assessment_sanction_screening',
    InformationSecurityCybersecurity: 'information_security_cybersecurity',
    InsuranceAssessment: 'insurance_assessment',
    KnowYourVendor: 'know_your_vendor',
    LegalEvaluation: 'legal_evaluation',
    MarketPositionAssessment: 'market_position_assessment',
    OffshoreAssessment: 'offshore_assessment',
    OnsiteVisits: 'onsite_visits',
    OutsourcingArrangementAssessment: 'outsourcing_arrangement_assessment',
    ReputationalAssessmentAdverseMediaScreening: 'reputational_assessment_adverse_media_screening',
    ResolvabilityAssessment: 'resolvability_assessment',
    StrategicAlignmentEvaluation: 'strategic_alignment_evaluation'
} as const;
export type QuestionnairesQuestionnairesListQuestionnaireTypeEnum = typeof QuestionnairesQuestionnairesListQuestionnaireTypeEnum[keyof typeof QuestionnairesQuestionnairesListQuestionnaireTypeEnum];
/**
 * @export
 */
export const QuestionnairesQuestionnairesListStatusEnum = {
    Completed: 'completed',
    InProgress: 'in_progress'
} as const;
export type QuestionnairesQuestionnairesListStatusEnum = typeof QuestionnairesQuestionnairesListStatusEnum[keyof typeof QuestionnairesQuestionnairesListStatusEnum];
