/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `AUTOMATED` - Automated
 * * `SEMI_AUTOMATED` - Semi-automated
 * * `MANUAL` - Manual
 * @export
 */
export const AutomationEnum = {
    Automated: 'AUTOMATED',
    SemiAutomated: 'SEMI_AUTOMATED',
    Manual: 'MANUAL'
} as const;
export type AutomationEnum = typeof AutomationEnum[keyof typeof AutomationEnum];


export function instanceOfAutomationEnum(value: any): boolean {
    for (const key in AutomationEnum) {
        if (Object.prototype.hasOwnProperty.call(AutomationEnum, key)) {
            if (AutomationEnum[key as keyof typeof AutomationEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AutomationEnumFromJSON(json: any): AutomationEnum {
    return AutomationEnumFromJSONTyped(json, false);
}

export function AutomationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationEnum {
    return json as AutomationEnum;
}

export function AutomationEnumToJSON(value?: AutomationEnum | null): any {
    return value as any;
}

