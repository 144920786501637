/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentRegisterRequest
 */
export interface IncidentRegisterRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof IncidentRegisterRequest
     */
    incidents?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentRegisterRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentRegisterRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentRegisterRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IncidentRegisterRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IncidentRegisterRequest
     */
    company: number;
}

/**
 * Check if a given object implements the IncidentRegisterRequest interface.
 */
export function instanceOfIncidentRegisterRequest(value: object): value is IncidentRegisterRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    return true;
}

export function IncidentRegisterRequestFromJSON(json: any): IncidentRegisterRequest {
    return IncidentRegisterRequestFromJSONTyped(json, false);
}

export function IncidentRegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRegisterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'incidents': json['incidents'] == null ? undefined : json['incidents'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'],
    };
}

export function IncidentRegisterRequestToJSON(value?: IncidentRegisterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'incidents': value['incidents'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

