/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetTypeEnum } from './WidgetTypeEnum';
import {
    WidgetTypeEnumFromJSON,
    WidgetTypeEnumFromJSONTyped,
    WidgetTypeEnumToJSON,
} from './WidgetTypeEnum';

/**
 * 
 * @export
 * @interface Widget
 */
export interface Widget {
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    dashboard?: number;
    /**
     * 
     * @type {Date}
     * @memberof Widget
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Widget
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Widget
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {any}
     * @memberof Widget
     */
    data: any | null;
    /**
     * 
     * @type {WidgetTypeEnum}
     * @memberof Widget
     */
    widgetType: WidgetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    xIndex: number;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    yIndex: number;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the Widget interface.
 */
export function instanceOfWidget(value: object): value is Widget {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('widgetType' in value) || value['widgetType'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('xIndex' in value) || value['xIndex'] === undefined) return false;
    if (!('yIndex' in value) || value['yIndex'] === undefined) return false;
    if (!('width' in value) || value['width'] === undefined) return false;
    if (!('height' in value) || value['height'] === undefined) return false;
    return true;
}

export function WidgetFromJSON(json: any): Widget {
    return WidgetFromJSONTyped(json, false);
}

export function WidgetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Widget {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dashboard': json['dashboard'] == null ? undefined : json['dashboard'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'data': json['data'],
        'widgetType': WidgetTypeEnumFromJSON(json['widget_type']),
        'name': json['name'],
        'xIndex': json['x_index'],
        'yIndex': json['y_index'],
        'width': json['width'],
        'height': json['height'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function WidgetToJSON(value?: Omit<Widget, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboard': value['dashboard'],
        'object_is_public': value['objectIsPublic'],
        'data': value['data'],
        'widget_type': WidgetTypeEnumToJSON(value['widgetType']),
        'name': value['name'],
        'x_index': value['xIndex'],
        'y_index': value['yIndex'],
        'width': value['width'],
        'height': value['height'],
        'object_owner': value['objectOwner'],
        'updated_by': value['updatedBy'],
    };
}

