/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedReportThemeRequest
 */
export interface PatchedReportThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedReportThemeRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedReportThemeRequest
     */
    description?: string | null;
    /**
     * 
     * @type {any}
     * @memberof PatchedReportThemeRequest
     */
    data?: any | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedReportThemeRequest
     */
    company?: number;
}

/**
 * Check if a given object implements the PatchedReportThemeRequest interface.
 */
export function instanceOfPatchedReportThemeRequest(value: object): value is PatchedReportThemeRequest {
    return true;
}

export function PatchedReportThemeRequestFromJSON(json: any): PatchedReportThemeRequest {
    return PatchedReportThemeRequestFromJSONTyped(json, false);
}

export function PatchedReportThemeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedReportThemeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'data': json['data'] == null ? undefined : json['data'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function PatchedReportThemeRequestToJSON(value?: PatchedReportThemeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'data': value['data'],
        'company': value['company'],
    };
}

