/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyUser } from './CompanyUser';
import {
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import type { CompanyInvitation } from './CompanyInvitation';
import {
    CompanyInvitationFromJSON,
    CompanyInvitationFromJSONTyped,
    CompanyInvitationToJSON,
} from './CompanyInvitation';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    readonly slug: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    readonly seats: number;
    /**
     * 
     * @type {Array<CompanyUser>}
     * @memberof Company
     */
    readonly organizationUsers: Array<CompanyUser>;
    /**
     * 
     * @type {Array<CompanyInvitation>}
     * @memberof Company
     */
    readonly organizationInvites: Array<CompanyInvitation>;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    readonly teams: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    readonly trialExpired: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    readonly modules: string;
    /**
     * The name of the organization
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    readonly modified: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    image?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    isEnterprise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    betaFeatures?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    regeyeId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    oasysMigrated?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    interactMigrated?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    reportiqId?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    industries?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    countries?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    readonly users: Array<number>;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): value is Company {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    if (!('seats' in value) || value['seats'] === undefined) return false;
    if (!('organizationUsers' in value) || value['organizationUsers'] === undefined) return false;
    if (!('organizationInvites' in value) || value['organizationInvites'] === undefined) return false;
    if (!('teams' in value) || value['teams'] === undefined) return false;
    if (!('trialExpired' in value) || value['trialExpired'] === undefined) return false;
    if (!('modules' in value) || value['modules'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('modified' in value) || value['modified'] === undefined) return false;
    if (!('users' in value) || value['users'] === undefined) return false;
    return true;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'seats': json['seats'],
        'organizationUsers': ((json['organization_users'] as Array<any>).map(CompanyUserFromJSON)),
        'organizationInvites': ((json['organization_invites'] as Array<any>).map(CompanyInvitationFromJSON)),
        'teams': json['teams'],
        'trialExpired': json['trial_expired'],
        'modules': json['modules'],
        'name': json['name'],
        'isActive': json['is_active'] == null ? undefined : json['is_active'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'image': json['image'] == null ? undefined : json['image'],
        'isEnterprise': json['is_enterprise'] == null ? undefined : json['is_enterprise'],
        'betaFeatures': json['beta_features'] == null ? undefined : json['beta_features'],
        'regeyeId': json['_regeye_id'] == null ? undefined : json['_regeye_id'],
        'oasysMigrated': json['_oasys_migrated'] == null ? undefined : json['_oasys_migrated'],
        'interactMigrated': json['_interact_migrated'] == null ? undefined : json['_interact_migrated'],
        'reportiqId': json['_reportiq_id'] == null ? undefined : json['_reportiq_id'],
        'industries': json['industries'] == null ? undefined : json['industries'],
        'countries': json['countries'] == null ? undefined : json['countries'],
        'users': json['users'],
    };
}

export function CompanyToJSON(value?: Omit<Company, 'id'|'slug'|'seats'|'organization_users'|'organization_invites'|'teams'|'trial_expired'|'modules'|'created'|'modified'|'users'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'is_active': value['isActive'],
        'image': value['image'],
        'is_enterprise': value['isEnterprise'],
        'beta_features': value['betaFeatures'],
        '_regeye_id': value['regeyeId'],
        '_oasys_migrated': value['oasysMigrated'],
        '_interact_migrated': value['interactMigrated'],
        '_reportiq_id': value['reportiqId'],
        'industries': value['industries'],
        'countries': value['countries'],
    };
}

