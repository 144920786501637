/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { FrequencyEnum } from './FrequencyEnum';
import {
    instanceOfFrequencyEnum,
    FrequencyEnumFromJSON,
    FrequencyEnumFromJSONTyped,
    FrequencyEnumToJSON,
} from './FrequencyEnum';

/**
 * @type ControlRequestExecutionFrequency
 * 
 * @export
 */
export type ControlRequestExecutionFrequency = BlankEnum | FrequencyEnum;

export function ControlRequestExecutionFrequencyFromJSON(json: any): ControlRequestExecutionFrequency {
    return ControlRequestExecutionFrequencyFromJSONTyped(json, false);
}

export function ControlRequestExecutionFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequestExecutionFrequency {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfFrequencyEnum(json)) {
        return FrequencyEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlRequestExecutionFrequencyToJSON(value?: ControlRequestExecutionFrequency | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfFrequencyEnum(value)) {
        return FrequencyEnumToJSON(value as FrequencyEnum);
    }

    return {};
}

