/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface DashboardComment
 */
export interface DashboardComment {
    /**
     * 
     * @type {number}
     * @memberof DashboardComment
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof DashboardComment
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardComment
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof DashboardComment
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof DashboardComment
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DashboardComment
     */
    dashboard: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardComment
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the DashboardComment interface.
 */
export function instanceOfDashboardComment(value: object): value is DashboardComment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('dashboard' in value) || value['dashboard'] === undefined) return false;
    return true;
}

export function DashboardCommentFromJSON(json: any): DashboardComment {
    return DashboardCommentFromJSONTyped(json, false);
}

export function DashboardCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'dashboard': json['dashboard'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function DashboardCommentToJSON(value?: Omit<DashboardComment, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'dashboard': value['dashboard'],
        'mentions': value['mentions'],
    };
}

