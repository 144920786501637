/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LikelihoodLevel } from './LikelihoodLevel';
import {
    LikelihoodLevelFromJSON,
    LikelihoodLevelFromJSONTyped,
    LikelihoodLevelToJSON,
} from './LikelihoodLevel';
import type { RiskMatrixSettingsLogBase } from './RiskMatrixSettingsLogBase';
import {
    RiskMatrixSettingsLogBaseFromJSON,
    RiskMatrixSettingsLogBaseFromJSONTyped,
    RiskMatrixSettingsLogBaseToJSON,
} from './RiskMatrixSettingsLogBase';
import type { RiskArea } from './RiskArea';
import {
    RiskAreaFromJSON,
    RiskAreaFromJSONTyped,
    RiskAreaToJSON,
} from './RiskArea';
import type { MatrixTypeEnum } from './MatrixTypeEnum';
import {
    MatrixTypeEnumFromJSON,
    MatrixTypeEnumFromJSONTyped,
    MatrixTypeEnumToJSON,
} from './MatrixTypeEnum';
import type { RatingLevel } from './RatingLevel';
import {
    RatingLevelFromJSON,
    RatingLevelFromJSONTyped,
    RatingLevelToJSON,
} from './RatingLevel';
import type { ImpactLevel } from './ImpactLevel';
import {
    ImpactLevelFromJSON,
    ImpactLevelFromJSONTyped,
    ImpactLevelToJSON,
} from './ImpactLevel';
import type { RiskTarget } from './RiskTarget';
import {
    RiskTargetFromJSON,
    RiskTargetFromJSONTyped,
    RiskTargetToJSON,
} from './RiskTarget';
import type { RiskType } from './RiskType';
import {
    RiskTypeFromJSON,
    RiskTypeFromJSONTyped,
    RiskTypeToJSON,
} from './RiskType';

/**
 * 
 * @export
 * @interface RiskMatrixSettings
 */
export interface RiskMatrixSettings {
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    readonly id: number;
    /**
     * 
     * @type {MatrixTypeEnum}
     * @memberof RiskMatrixSettings
     */
    matrixType?: MatrixTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettings
     */
    ratingLevelsAutoCalculate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettings
     */
    ratingLevelsAutoColor?: boolean;
    /**
     * 
     * @type {Array<ImpactLevel>}
     * @memberof RiskMatrixSettings
     */
    impactLevels?: Array<ImpactLevel>;
    /**
     * 
     * @type {Array<LikelihoodLevel>}
     * @memberof RiskMatrixSettings
     */
    likelihoodLevels?: Array<LikelihoodLevel>;
    /**
     * 
     * @type {Array<RatingLevel>}
     * @memberof RiskMatrixSettings
     */
    ratingLevels?: Array<RatingLevel>;
    /**
     * 
     * @type {Array<RiskTarget>}
     * @memberof RiskMatrixSettings
     */
    riskTargets?: Array<RiskTarget>;
    /**
     * 
     * @type {Array<RiskType>}
     * @memberof RiskMatrixSettings
     */
    riskTypes?: Array<RiskType>;
    /**
     * 
     * @type {Array<RiskArea>}
     * @memberof RiskMatrixSettings
     */
    riskAreas?: Array<RiskArea>;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixSettings
     */
    automaticallyCalculate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    numberOfRiskLevels?: number;
    /**
     * 
     * @type {RiskMatrixSettingsLogBase}
     * @memberof RiskMatrixSettings
     */
    logBase?: RiskMatrixSettingsLogBase | null;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixSettings
     */
    minimumFinancialImpact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixSettings
     */
    maximumFinancialImpact?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    minimumProbability?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    maximumProbability?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    minimumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixSettings
     */
    maximumFinancialImpactCurrency?: number | null;
}



/**
 * Check if a given object implements the RiskMatrixSettings interface.
 */
export function instanceOfRiskMatrixSettings(value: object): value is RiskMatrixSettings {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function RiskMatrixSettingsFromJSON(json: any): RiskMatrixSettings {
    return RiskMatrixSettingsFromJSONTyped(json, false);
}

export function RiskMatrixSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrixSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'matrixType': json['matrix_type'] == null ? undefined : MatrixTypeEnumFromJSON(json['matrix_type']),
        'ratingLevelsAutoCalculate': json['rating_levels_auto_calculate'] == null ? undefined : json['rating_levels_auto_calculate'],
        'ratingLevelsAutoColor': json['rating_levels_auto_color'] == null ? undefined : json['rating_levels_auto_color'],
        'impactLevels': json['impact_levels'] == null ? undefined : ((json['impact_levels'] as Array<any>).map(ImpactLevelFromJSON)),
        'likelihoodLevels': json['likelihood_levels'] == null ? undefined : ((json['likelihood_levels'] as Array<any>).map(LikelihoodLevelFromJSON)),
        'ratingLevels': json['rating_levels'] == null ? undefined : ((json['rating_levels'] as Array<any>).map(RatingLevelFromJSON)),
        'riskTargets': json['risk_targets'] == null ? undefined : ((json['risk_targets'] as Array<any>).map(RiskTargetFromJSON)),
        'riskTypes': json['risk_types'] == null ? undefined : ((json['risk_types'] as Array<any>).map(RiskTypeFromJSON)),
        'riskAreas': json['risk_areas'] == null ? undefined : ((json['risk_areas'] as Array<any>).map(RiskAreaFromJSON)),
        'automaticallyCalculate': json['automatically_calculate'] == null ? undefined : json['automatically_calculate'],
        'numberOfRiskLevels': json['number_of_risk_levels'] == null ? undefined : json['number_of_risk_levels'],
        'logBase': json['log_base'] == null ? undefined : RiskMatrixSettingsLogBaseFromJSON(json['log_base']),
        'minimumFinancialImpact': json['minimum_financial_impact'] == null ? undefined : json['minimum_financial_impact'],
        'maximumFinancialImpact': json['maximum_financial_impact'] == null ? undefined : json['maximum_financial_impact'],
        'minimumProbability': json['minimum_probability'] == null ? undefined : json['minimum_probability'],
        'maximumProbability': json['maximum_probability'] == null ? undefined : json['maximum_probability'],
        'minimumFinancialImpactCurrency': json['minimum_financial_impact_currency'] == null ? undefined : json['minimum_financial_impact_currency'],
        'maximumFinancialImpactCurrency': json['maximum_financial_impact_currency'] == null ? undefined : json['maximum_financial_impact_currency'],
    };
}

export function RiskMatrixSettingsToJSON(value?: Omit<RiskMatrixSettings, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'matrix_type': MatrixTypeEnumToJSON(value['matrixType']),
        'rating_levels_auto_calculate': value['ratingLevelsAutoCalculate'],
        'rating_levels_auto_color': value['ratingLevelsAutoColor'],
        'impact_levels': value['impactLevels'] == null ? undefined : ((value['impactLevels'] as Array<any>).map(ImpactLevelToJSON)),
        'likelihood_levels': value['likelihoodLevels'] == null ? undefined : ((value['likelihoodLevels'] as Array<any>).map(LikelihoodLevelToJSON)),
        'rating_levels': value['ratingLevels'] == null ? undefined : ((value['ratingLevels'] as Array<any>).map(RatingLevelToJSON)),
        'risk_targets': value['riskTargets'] == null ? undefined : ((value['riskTargets'] as Array<any>).map(RiskTargetToJSON)),
        'risk_types': value['riskTypes'] == null ? undefined : ((value['riskTypes'] as Array<any>).map(RiskTypeToJSON)),
        'risk_areas': value['riskAreas'] == null ? undefined : ((value['riskAreas'] as Array<any>).map(RiskAreaToJSON)),
        'automatically_calculate': value['automaticallyCalculate'],
        'number_of_risk_levels': value['numberOfRiskLevels'],
        'log_base': RiskMatrixSettingsLogBaseToJSON(value['logBase']),
        'minimum_financial_impact': value['minimumFinancialImpact'],
        'maximum_financial_impact': value['maximumFinancialImpact'],
        'minimum_probability': value['minimumProbability'],
        'maximum_probability': value['maximumProbability'],
        'minimum_financial_impact_currency': value['minimumFinancialImpactCurrency'],
        'maximum_financial_impact_currency': value['maximumFinancialImpactCurrency'],
    };
}

