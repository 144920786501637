export type PermissionType = {
  name: string;
  value: string[];
};
export type ModuleProps = {
  title: string;
  key: string;
};
export const Permissions: Array<PermissionType> = [
  {
    name: "Full Access",
    value: ["add", "change", "delete", "view", "comment"],
  },
  { name: "Edit", value: ["add", "change", "view", "comment"] },
  { name: "Comment", value: ["view", "comment"] },
  { name: "View", value: ["view"] },
  { name: "No Access", value: [] },
];

export const initialPermissions = {
  article: [],
  agreement: [],
  policy: [],
  document: [],
  task: [],
  search: [],
  Note: [],
};

export const modules: ModuleProps[] = [
  {
    title: "Dashboard",
    key: "dashboard",
  },
  {
    title: "Calendar",
    key: "calendar",
  },
  {
    title: "Article",
    key: "article",
  },
  {
    title: "Agreement",
    key: "agreement",
  },
  {
    title: "Policy",
    key: "policy",
  },
  {
    title: "Document",
    key: "document",
  },
  {
    title: "Task",
    key: "task",
  },
  { title: "Search", key: "search" },
  {
    title: "Risk",
    key: "risk",
  },
  {
    title: "Regulatory Library",
    key: "roi",
  },
  {
    title: "Controls",
    key: "controls",
  },
];
