/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedQuestionnaireListRequest
 */
export interface PatchedQuestionnaireListRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedQuestionnaireListRequest
     */
    questionnaires?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedQuestionnaireListRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedQuestionnaireListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedQuestionnaireListRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedQuestionnaireListRequest
     */
    company?: number;
}

/**
 * Check if a given object implements the PatchedQuestionnaireListRequest interface.
 */
export function instanceOfPatchedQuestionnaireListRequest(value: object): value is PatchedQuestionnaireListRequest {
    return true;
}

export function PatchedQuestionnaireListRequestFromJSON(json: any): PatchedQuestionnaireListRequest {
    return PatchedQuestionnaireListRequestFromJSONTyped(json, false);
}

export function PatchedQuestionnaireListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedQuestionnaireListRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'questionnaires': json['questionnaires'] == null ? undefined : json['questionnaires'],
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function PatchedQuestionnaireListRequestToJSON(value?: PatchedQuestionnaireListRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'questionnaires': value['questionnaires'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

