/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { MaturityEnum } from './MaturityEnum';
import {
    instanceOfMaturityEnum,
    MaturityEnumFromJSON,
    MaturityEnumFromJSONTyped,
    MaturityEnumToJSON,
} from './MaturityEnum';

/**
 * @type ControlMaturity
 * 
 * @export
 */
export type ControlMaturity = BlankEnum | MaturityEnum;

export function ControlMaturityFromJSON(json: any): ControlMaturity {
    return ControlMaturityFromJSONTyped(json, false);
}

export function ControlMaturityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMaturity {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfMaturityEnum(json)) {
        return MaturityEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ControlMaturityToJSON(value?: ControlMaturity | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfMaturityEnum(value)) {
        return MaturityEnumToJSON(value as MaturityEnum);
    }

    return {};
}

