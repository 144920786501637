/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedQuestionnaireListModifyRequest
 */
export interface PatchedQuestionnaireListModifyRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof PatchedQuestionnaireListModifyRequest
     */
    questionnaires?: Array<any>;
}

/**
 * Check if a given object implements the PatchedQuestionnaireListModifyRequest interface.
 */
export function instanceOfPatchedQuestionnaireListModifyRequest(value: object): value is PatchedQuestionnaireListModifyRequest {
    return true;
}

export function PatchedQuestionnaireListModifyRequestFromJSON(json: any): PatchedQuestionnaireListModifyRequest {
    return PatchedQuestionnaireListModifyRequestFromJSONTyped(json, false);
}

export function PatchedQuestionnaireListModifyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedQuestionnaireListModifyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'questionnaires': json['questionnaires'] == null ? undefined : json['questionnaires'],
    };
}

export function PatchedQuestionnaireListModifyRequestToJSON(value?: PatchedQuestionnaireListModifyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'questionnaires': value['questionnaires'],
    };
}

