/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpactLevel
 */
export interface ImpactLevel {
    /**
     * 
     * @type {number}
     * @memberof ImpactLevel
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevel
     */
    readonly matrix: number;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevel
     */
    maximumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevel
     */
    minimumFinancialImpactCurrency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImpactLevel
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevel
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevel
     */
    minimumFinancialImpact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpactLevel
     */
    maximumFinancialImpact?: string | null;
}

/**
 * Check if a given object implements the ImpactLevel interface.
 */
export function instanceOfImpactLevel(value: object): value is ImpactLevel {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('matrix' in value) || value['matrix'] === undefined) return false;
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function ImpactLevelFromJSON(json: any): ImpactLevel {
    return ImpactLevelFromJSONTyped(json, false);
}

export function ImpactLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpactLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'matrix': json['matrix'],
        'maximumFinancialImpactCurrency': json['maximum_financial_impact_currency'] == null ? undefined : json['maximum_financial_impact_currency'],
        'minimumFinancialImpactCurrency': json['minimum_financial_impact_currency'] == null ? undefined : json['minimum_financial_impact_currency'],
        'score': json['score'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'minimumFinancialImpact': json['minimum_financial_impact'] == null ? undefined : json['minimum_financial_impact'],
        'maximumFinancialImpact': json['maximum_financial_impact'] == null ? undefined : json['maximum_financial_impact'],
    };
}

export function ImpactLevelToJSON(value?: Omit<ImpactLevel, 'id'|'matrix'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maximum_financial_impact_currency': value['maximumFinancialImpactCurrency'],
        'minimum_financial_impact_currency': value['minimumFinancialImpactCurrency'],
        'score': value['score'],
        'label': value['label'],
        'description': value['description'],
        'minimum_financial_impact': value['minimumFinancialImpact'],
        'maximum_financial_impact': value['maximumFinancialImpact'],
    };
}

