/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThemeEnum } from './ThemeEnum';
import {
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface DashboardTemplate
 */
export interface DashboardTemplate {
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplate
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplate
     */
    company: number;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplate
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardTemplate
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardTemplate
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplate
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplate
     */
    overview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplate
     */
    summary?: string | null;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof DashboardTemplate
     */
    theme?: ThemeEnum;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplate
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplate
     */
    updatedBy?: number | null;
}



/**
 * Check if a given object implements the DashboardTemplate interface.
 */
export function instanceOfDashboardTemplate(value: object): value is DashboardTemplate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DashboardTemplateFromJSON(json: any): DashboardTemplate {
    return DashboardTemplateFromJSONTyped(json, false);
}

export function DashboardTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'company': json['company'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'overview': json['overview'] == null ? undefined : json['overview'],
        'summary': json['summary'] == null ? undefined : json['summary'],
        'theme': json['theme'] == null ? undefined : ThemeEnumFromJSON(json['theme']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
    };
}

export function DashboardTemplateToJSON(value?: Omit<DashboardTemplate, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': value['company'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'overview': value['overview'],
        'summary': value['summary'],
        'theme': ThemeEnumToJSON(value['theme']),
        'object_owner': value['objectOwner'],
        'updated_by': value['updatedBy'],
    };
}

