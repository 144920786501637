/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SEMI_QUANTITATIVE` - Semi-Quantitative
 * * `QUANTITATIVE` - Quantitative
 * @export
 */
export const MatrixTypeEnum = {
    SemiQuantitative: 'SEMI_QUANTITATIVE',
    Quantitative: 'QUANTITATIVE'
} as const;
export type MatrixTypeEnum = typeof MatrixTypeEnum[keyof typeof MatrixTypeEnum];


export function instanceOfMatrixTypeEnum(value: any): boolean {
    for (const key in MatrixTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(MatrixTypeEnum, key)) {
            if (MatrixTypeEnum[key as keyof typeof MatrixTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MatrixTypeEnumFromJSON(json: any): MatrixTypeEnum {
    return MatrixTypeEnumFromJSONTyped(json, false);
}

export function MatrixTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatrixTypeEnum {
    return json as MatrixTypeEnum;
}

export function MatrixTypeEnumToJSON(value?: MatrixTypeEnum | null): any {
    return value as any;
}

