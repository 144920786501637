/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `OWNER` - Owner
 * * `ADMIN` - Admin
 * * `MEMBER` - Member
 * * `GUEST` - Guest
 * @export
 */
export const RoleEnum = {
    Owner: 'OWNER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Guest: 'GUEST'
} as const;
export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


export function instanceOfRoleEnum(value: any): boolean {
    for (const key in RoleEnum) {
        if (Object.prototype.hasOwnProperty.call(RoleEnum, key)) {
            if (RoleEnum[key as keyof typeof RoleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoleEnumFromJSON(json: any): RoleEnum {
    return RoleEnumFromJSONTyped(json, false);
}

export function RoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleEnum {
    return json as RoleEnum;
}

export function RoleEnumToJSON(value?: RoleEnum | null): any {
    return value as any;
}

