/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `grand` - Grand
 * * `advisense` - Advisense
 * @export
 */
export const ThemeEnum = {
    Grand: 'grand',
    Advisense: 'advisense'
} as const;
export type ThemeEnum = typeof ThemeEnum[keyof typeof ThemeEnum];


export function instanceOfThemeEnum(value: any): boolean {
    for (const key in ThemeEnum) {
        if (Object.prototype.hasOwnProperty.call(ThemeEnum, key)) {
            if (ThemeEnum[key as keyof typeof ThemeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ThemeEnumFromJSON(json: any): ThemeEnum {
    return ThemeEnumFromJSONTyped(json, false);
}

export function ThemeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeEnum {
    return json as ThemeEnum;
}

export function ThemeEnumToJSON(value?: ThemeEnum | null): any {
    return value as any;
}

