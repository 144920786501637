/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface CompanyInvitation
 */
export interface CompanyInvitation {
    /**
     * 
     * @type {number}
     * @memberof CompanyInvitation
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitation
     */
    readonly guid: string;
    /**
     * The contact identifier for the invitee, email, phone number, social media handle, etc.
     * @type {string}
     * @memberof CompanyInvitation
     */
    inviteeIdentifier: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyInvitation
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyInvitation
     */
    readonly modified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInvitation
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {RoleEnum}
     * @memberof CompanyInvitation
     */
    role?: RoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyInvitation
     */
    readonly invitedBy: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyInvitation
     */
    invitee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInvitation
     */
    organization: number;
}



/**
 * Check if a given object implements the CompanyInvitation interface.
 */
export function instanceOfCompanyInvitation(value: object): value is CompanyInvitation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('guid' in value) || value['guid'] === undefined) return false;
    if (!('inviteeIdentifier' in value) || value['inviteeIdentifier'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('modified' in value) || value['modified'] === undefined) return false;
    if (!('invitedBy' in value) || value['invitedBy'] === undefined) return false;
    if (!('organization' in value) || value['organization'] === undefined) return false;
    return true;
}

export function CompanyInvitationFromJSON(json: any): CompanyInvitation {
    return CompanyInvitationFromJSONTyped(json, false);
}

export function CompanyInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInvitation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'inviteeIdentifier': json['invitee_identifier'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'isAdmin': json['is_admin'] == null ? undefined : json['is_admin'],
        'role': json['role'] == null ? undefined : RoleEnumFromJSON(json['role']),
        'invitedBy': json['invited_by'],
        'invitee': json['invitee'] == null ? undefined : json['invitee'],
        'organization': json['organization'],
    };
}

export function CompanyInvitationToJSON(value?: Omit<CompanyInvitation, 'id'|'guid'|'created'|'modified'|'invited_by'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invitee_identifier': value['inviteeIdentifier'],
        'is_admin': value['isAdmin'],
        'role': RoleEnumToJSON(value['role']),
        'invitee': value['invitee'],
        'organization': value['organization'],
    };
}

