/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EvidenceRequestRequestAreaOfFocus } from './EvidenceRequestRequestAreaOfFocus';
import {
    EvidenceRequestRequestAreaOfFocusFromJSON,
    EvidenceRequestRequestAreaOfFocusFromJSONTyped,
    EvidenceRequestRequestAreaOfFocusToJSON,
} from './EvidenceRequestRequestAreaOfFocus';
import type { ReviewFrequency } from './ReviewFrequency';
import {
    ReviewFrequencyFromJSON,
    ReviewFrequencyFromJSONTyped,
    ReviewFrequencyToJSON,
} from './ReviewFrequency';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { EvidenceStatusEnum } from './EvidenceStatusEnum';
import {
    EvidenceStatusEnumFromJSON,
    EvidenceStatusEnumFromJSONTyped,
    EvidenceStatusEnumToJSON,
} from './EvidenceStatusEnum';
import type { Artifact } from './Artifact';
import {
    ArtifactFromJSON,
    ArtifactFromJSONTyped,
    ArtifactToJSON,
} from './Artifact';

/**
 * 
 * @export
 * @interface EvidenceResponse
 */
export interface EvidenceResponse {
    /**
     * 
     * @type {number}
     * @memberof EvidenceResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponse
     */
    description?: string | null;
    /**
     * 
     * @type {EvidenceRequestRequestAreaOfFocus}
     * @memberof EvidenceResponse
     */
    areaOfFocus?: EvidenceRequestRequestAreaOfFocus | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof EvidenceResponse
     */
    readonly tags: Array<Tag>;
    /**
     * 
     * @type {ReviewFrequency}
     * @memberof EvidenceResponse
     */
    reviewFrequency: ReviewFrequency;
    /**
     * 
     * @type {string}
     * @memberof EvidenceResponse
     */
    customId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EvidenceResponse
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {EvidenceStatusEnum}
     * @memberof EvidenceResponse
     */
    status?: EvidenceStatusEnum;
    /**
     * 
     * @type {Array<Artifact>}
     * @memberof EvidenceResponse
     */
    readonly artifacts: Array<Artifact>;
    /**
     * 
     * @type {Date}
     * @memberof EvidenceResponse
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvidenceResponse
     */
    readonly updatedAt: Date;
}



/**
 * Check if a given object implements the EvidenceResponse interface.
 */
export function instanceOfEvidenceResponse(value: object): value is EvidenceResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('reviewFrequency' in value) || value['reviewFrequency'] === undefined) return false;
    if (!('artifacts' in value) || value['artifacts'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function EvidenceResponseFromJSON(json: any): EvidenceResponse {
    return EvidenceResponseFromJSONTyped(json, false);
}

export function EvidenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'areaOfFocus': json['area_of_focus'] == null ? undefined : EvidenceRequestRequestAreaOfFocusFromJSON(json['area_of_focus']),
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'reviewFrequency': ReviewFrequencyFromJSON(json['review_frequency']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'status': json['status'] == null ? undefined : EvidenceStatusEnumFromJSON(json['status']),
        'artifacts': ((json['artifacts'] as Array<any>).map(ArtifactFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function EvidenceResponseToJSON(value?: Omit<EvidenceResponse, 'id'|'tags'|'artifacts'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'area_of_focus': EvidenceRequestRequestAreaOfFocusToJSON(value['areaOfFocus']),
        'review_frequency': ReviewFrequencyToJSON(value['reviewFrequency']),
        'custom_id': value['customId'],
        'object_owner': value['objectOwner'],
        'status': EvidenceStatusEnumToJSON(value['status']),
    };
}

