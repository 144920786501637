/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IncidentOverallImpact } from './IncidentOverallImpact';
import {
    IncidentOverallImpactFromJSON,
    IncidentOverallImpactFromJSONTyped,
    IncidentOverallImpactToJSON,
} from './IncidentOverallImpact';
import type { IncidentStatus } from './IncidentStatus';
import {
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface Incident
 */
export interface Incident {
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof Incident
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Incident
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Incident
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Incident
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Incident
     */
    description?: string | null;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof Incident
     */
    status?: IncidentStatus | null;
    /**
     * 
     * @type {string}
     * @memberof Incident
     */
    customId?: string | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof Incident
     */
    overallImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof Incident
     */
    operationImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof Incident
     */
    financialImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {string}
     * @memberof Incident
     */
    grossLossImpact?: string | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof Incident
     */
    reputationalImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {IncidentOverallImpact}
     * @memberof Incident
     */
    legalComplianceImpact?: IncidentOverallImpact | null;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    company: number;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    reportedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Incident
     */
    grossLossImpactCurrency?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Incident
     */
    readonly vendors: Array<number>;
}

/**
 * Check if a given object implements the Incident interface.
 */
export function instanceOfIncident(value: object): value is Incident {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('vendors' in value) || value['vendors'] === undefined) return false;
    return true;
}

export function IncidentFromJSON(json: any): Incident {
    return IncidentFromJSONTyped(json, false);
}

export function IncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Incident {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : IncidentStatusFromJSON(json['status']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'overallImpact': json['overall_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['overall_impact']),
        'operationImpact': json['operation_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['operation_impact']),
        'financialImpact': json['financial_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['financial_impact']),
        'grossLossImpact': json['gross_loss_impact'] == null ? undefined : json['gross_loss_impact'],
        'reputationalImpact': json['reputational_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['reputational_impact']),
        'legalComplianceImpact': json['legal_compliance_impact'] == null ? undefined : IncidentOverallImpactFromJSON(json['legal_compliance_impact']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'company': json['company'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'reportedBy': json['reported_by'] == null ? undefined : json['reported_by'],
        'grossLossImpactCurrency': json['gross_loss_impact_currency'] == null ? undefined : json['gross_loss_impact_currency'],
        'vendors': json['vendors'],
    };
}

export function IncidentToJSON(value?: Omit<Incident, 'id'|'created_at'|'updated_at'|'vendors'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'status': IncidentStatusToJSON(value['status']),
        'custom_id': value['customId'],
        'overall_impact': IncidentOverallImpactToJSON(value['overallImpact']),
        'operation_impact': IncidentOverallImpactToJSON(value['operationImpact']),
        'financial_impact': IncidentOverallImpactToJSON(value['financialImpact']),
        'gross_loss_impact': value['grossLossImpact'],
        'reputational_impact': IncidentOverallImpactToJSON(value['reputationalImpact']),
        'legal_compliance_impact': IncidentOverallImpactToJSON(value['legalComplianceImpact']),
        'object_owner': value['objectOwner'],
        'directory': value['directory'],
        'company': value['company'],
        'owner': value['owner'],
        'reported_by': value['reportedBy'],
        'gross_loss_impact_currency': value['grossLossImpactCurrency'],
    };
}

