/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `IN_PROGRESS` - In Progress
 * * `ACTIVE` - Active
 * * `NEEDS_ATTENTION` - Needs Attention
 * * `INACTIVE` - Inactive
 * @export
 */
export const ControlStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Active: 'ACTIVE',
    NeedsAttention: 'NEEDS_ATTENTION',
    Inactive: 'INACTIVE'
} as const;
export type ControlStatusEnum = typeof ControlStatusEnum[keyof typeof ControlStatusEnum];


export function instanceOfControlStatusEnum(value: any): boolean {
    for (const key in ControlStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlStatusEnum, key)) {
            if (ControlStatusEnum[key as keyof typeof ControlStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlStatusEnumFromJSON(json: any): ControlStatusEnum {
    return ControlStatusEnumFromJSONTyped(json, false);
}

export function ControlStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlStatusEnum {
    return json as ControlStatusEnum;
}

export function ControlStatusEnumToJSON(value?: ControlStatusEnum | null): any {
    return value as any;
}

