export const agreementDownloadFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "agreement_type", label: "Agreement Type" },
  { value: "status", label: "Status" },
  { value: "start_date", label: "Start Date" },
  { value: "has_end_date", label: "Has End Date" },
  { value: "end_date", label: "End Date" },
  { value: "renewal_date", label: "Renewal Date" },
  {
    value: "notice_period_supplier_value",
    label: "Notice Period Supplier Value",
  },
  {
    value: "notice_period_supplier_unit",
    label: "Notice Period Supplier Unit",
  },
  {
    value: "notice_period_internal_entity_value",
    label: "Notice Period Internal Entity Value",
  },
  {
    value: "notice_period_internal_entity_unit",
    label: "Notice Period Internal Entity Unit",
  },
  { value: "supplier_type", label: "Supplier Type" },
  { value: "external_supplier", label: "External Supplier" },
  { value: "internal_supplier", label: "Internal Supplier" },
  { value: "party_to_agreement", label: "Party To Agreement" },
  {
    value: "receivers_provided_service",
    label: "Receivers Provided Service",
  },
  { value: "internal_reference", label: "Internal Reference" },
  { value: "latest_review", label: "Latest Review" },
  { value: "next_review", label: "Next Review" },
  { value: "governing_law", label: "Governing Law" },
  { value: "archiving_reference", label: "Archiving Reference" },
  { value: "description", label: "Description" },
  { value: "estimated_budget_amount", label: "Estimated Budget Amount" },
  { value: "estimated_budget_currency", label: "Estimated Budget Currency" },
  { value: "total_value_amount", label: "Total Value Amount" },
  { value: "total_value_currency", label: "Total Value Currency" },
  { value: "has_parent_agreement", label: "Has Parent Agreement" },
  { value: "parent", label: "Parent" },
  { value: "resolution_agreement", label: "Resolution Agreement" },
  { value: "relevant_entity", label: "Relevant Entity" },
  {
    value: "resolution_resilient_agreement",
    label: "Resolution Resilient Agreement",
  },
  {
    value: "critical_business_function",
    label: "Critical Business Function",
  },
  {
    value: "critical_business_function_description",
    label: "Critical Business Function Description",
  },
  { value: "core_business_lines", label: "Core Business Lines" },
  { value: "degree_of_criticality", label: "Degree Of Criticality" },
  { value: "jurisdictions", label: "Jurisdictions" },
  {
    value: "existence_of_automatic_renewal_clauses",
    label: "Existence Of Automatic Renewal Clauses",
  },
  {
    value: "predictable_pricing_structure",
    label: "Predictable Pricing Structure",
  },
  { value: "conditions_of_payment", label: "Conditions Of Payment" },
  {
    value: "quantitative_and_qualitative_performance_targets",
    label: "Settings And Qualitative Performance Targets",
  },
  { value: "effects_of_breaches", label: "Effects Of Breaches" },
  {
    value: "triggers_early_termination",
    label: "Triggers Early Termination",
  },
  {
    value: "parties_allowed_to_terminate",
    label: "Parties Allowed To Terminate",
  },
  {
    value: "time_for_substitutability_value",
    label: "Time For Substitutability Value",
  },
  {
    value: "time_for_substitutability_unit",
    label: "Time For Substitutability Unit",
  },
  {
    value: "post_termination_assist_value",
    label: "Post Termination Assist Value",
  },
  {
    value: "post_termination_assist_unit",
    label: "Post Termination Assist Unit",
  },
  {
    value: "resolvability_alternative_service_providers",
    label: "Resolvability Alternative Service Providers",
  },
  { value: "personal_data_transferred", label: "Personal Data Transferred" },
  {
    value: "locations_personal_data_transferred",
    label: "Locations Personal Data Transferred",
  },
  { value: "personal_data_processed", label: "Personal Data Processed" },
  { value: "role", label: "Role" },
  {
    value: "data_protection_documentation_included",
    label: "Data Protection Documentation Included",
  },
  { value: "cloud_service", label: "Cloud Service" },
  { value: "deployment_model", label: "Deployment Model" },
  { value: "nature_of_data", label: "Nature Of Data" },
  {
    value: "countries_service_performed",
    label: "Countries Service Performed",
  },
  { value: "locations_data_stored", label: "Locations Data Stored" },
  { value: "subcontractors", label: "Subcontractors" },
  { value: "product_approval_required", label: "Product Approval Required" },
  {
    value: "product_approval_completed",
    label: "Product Approval Completed",
  },
  { value: "product_approval_summary", label: "Product Approval Summary" },
  {
    value: "change_management_required",
    label: "Change Management Required",
  },
  {
    value: "change_management_completed",
    label: "Change Management Completed",
  },
  { value: "change_management_summary", label: "Change Management Summary" },
  { value: "outsourcing_agreement", label: "Outsourcing Agreement" },
  { value: "category", label: "Category" },
  {
    value: "critical_or_important_assessment_complete",
    label: "Critical Or Important Assessment Complete",
  },
  {
    value: "latest_critical_or_important_assessment",
    label: "Latest Critical Or Important Assessment",
  },
  { value: "assessment_summary", label: "Assessment Summary" },
  { value: "authority_notified", label: "Authority Notified" },
  { value: "authority_date_notified", label: "Authority Date Notified" },
  { value: "authority_summary", label: "Authority Summary" },
  { value: "critical_or_important", label: "Critical Or Important" },
  { value: "final_approval", label: "Final Approval" },
  { value: "latest_audit", label: "Latest Audit" },
  { value: "next_audit", label: "Next Audit" },
  { value: "audit_notes", label: "Audit Notes" },
  { value: "exit_strategy", label: "Exit Strategy" },
  {
    value: "alternative_service_providers",
    label: "Alternative Service Providers",
  },
  { value: "tags", label: "Tags" },
  { value: "time_critical_operation", label: "Time_critical_operation" },
  { value: "risk_assessment_level", label: "Risk Assessment Level" },
  { value: "latest_risk_assessment", label: "Latest Risk Assessment" },
  { value: "next_risk_assessment", label: "Next Risk Assessment" },
  { value: "risk_assessment_summary", label: "Risk Assessment Summary" },
  { value: "aml_related_activity", label: "Aml Related Activity" },
  { value: "owner", label: "Owner" },
  { value: "due_date", label: "Due Date" },
  { value: "priority", label: "Priority" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const tasksDownloadFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "status", label: "Status" },
  { value: "description", label: "Description" },
  { value: "tags", label: "Tags" },
  { value: "owner", label: "Owner" },
  { value: "due_date", label: "Due Date" },
  { value: "priority", label: "Priority" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const policyDownloadFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "status", label: "Status" },
  { value: "tags", label: "Tags" },
  { value: "legal_basis", label: "Legal Basis" },
  { value: "applies_to", label: "Applies To" },
  { value: "classification", label: "Classification" },
  { value: "accountable", label: "Accountable" },
  { value: "revision", label: "Revision" },
  { value: "link_url", label: "Link Url" },
  { value: "link_title", label: "Link Title" },
  { value: "adopted_by", label: "Adopted By" },
  { value: "approved_date", label: "Approved Date" },
  { value: "pub_date", label: "PUB Date" },
  { value: "extra_info", label: "Extra Info" },
  { value: "parent", label: "Parent" },
  { value: "owner", label: "Owner" },
  { value: "due_date", label: "Due Date" },
  { value: "priority", label: "Priority" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const risksMitigationFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "description", label: "Description" },
  { value: "frequency", label: "Frequency" },
  { value: "deadline", label: "Deadline" },
  { value: "status", label: "Status" },
  { value: "owner", label: "Owner" },
  { value: "person_responsible", label: "Person Responsible" },
  { value: "tags", label: "Tags" },
  { value: "assignees", label: "Assignees" },
  { value: "due_date", label: "Due Date" },
  { value: "priority", label: "Priority" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const riskregistersFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "description", label: "Description" },
  { value: "probability", label: "Probability" },
  { value: "risk_type", label: "Risk Type" },
  { value: "cause_type", label: "Cause Type" },
  { value: "impact_types", label: "Impact Types" },
  { value: "incidents", label: "Incidents" },
  { value: "mitigations", label: "Mitigations" },
  { value: "owner", label: "Owner" },
  { value: "tags", label: "Tags" },
  { value: "assignees", label: "Assignees" },
  { value: "priority", label: "Priority" },
  { value: "due_date", label: "Due Date" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const articlesDownloadFields: Array<{ value: string; label: string }> = [
  { value: "title", label: "Title" },
  { value: "url", label: "URL" },
  { value: "summary", label: "Summary" },
  { value: "publication_date", label: "Publication Date" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];

export const incidentsDownloadFields: Array<{ value: string; label: string }> = [
  { value: "name", label: "Name" },
  { value: "description", label: "Description" },
  { value: "status", label: "Status" },
  { value: "incident_reporter", label: "Reporter" },
  { value: "date_reported", label: "Date Reported" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];
