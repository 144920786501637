/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    readonly id: number;
    /**
     * 
     * @type {User}
     * @memberof CompanyUser
     */
    readonly user: User;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    readonly modified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyUser
     */
    customPermissions?: any | null;
    /**
     * 
     * @type {RoleEnum}
     * @memberof CompanyUser
     */
    role?: RoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    organization: number;
}



/**
 * Check if a given object implements the CompanyUser interface.
 */
export function instanceOfCompanyUser(value: object): value is CompanyUser {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('modified' in value) || value['modified'] === undefined) return false;
    if (!('organization' in value) || value['organization'] === undefined) return false;
    return true;
}

export function CompanyUserFromJSON(json: any): CompanyUser {
    return CompanyUserFromJSONTyped(json, false);
}

export function CompanyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'isAdmin': json['is_admin'] == null ? undefined : json['is_admin'],
        'customPermissions': json['custom_permissions'] == null ? undefined : json['custom_permissions'],
        'role': json['role'] == null ? undefined : RoleEnumFromJSON(json['role']),
        'organization': json['organization'],
    };
}

export function CompanyUserToJSON(value?: Omit<CompanyUser, 'id'|'user'|'created'|'modified'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'is_admin': value['isAdmin'],
        'custom_permissions': value['customPermissions'],
        'role': RoleEnumToJSON(value['role']),
        'organization': value['organization'],
    };
}

