/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SEVERE` - Severe
 * * `INTERMEDIATE` - Intermediate
 * * `MINOR` - Minor
 * @export
 */
export const RiskImpactEnum = {
    Severe: 'SEVERE',
    Intermediate: 'INTERMEDIATE',
    Minor: 'MINOR'
} as const;
export type RiskImpactEnum = typeof RiskImpactEnum[keyof typeof RiskImpactEnum];


export function instanceOfRiskImpactEnum(value: any): boolean {
    for (const key in RiskImpactEnum) {
        if (Object.prototype.hasOwnProperty.call(RiskImpactEnum, key)) {
            if (RiskImpactEnum[key as keyof typeof RiskImpactEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RiskImpactEnumFromJSON(json: any): RiskImpactEnum {
    return RiskImpactEnumFromJSONTyped(json, false);
}

export function RiskImpactEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskImpactEnum {
    return json as RiskImpactEnum;
}

export function RiskImpactEnumToJSON(value?: RiskImpactEnum | null): any {
    return value as any;
}

