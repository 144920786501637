/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthorityDocumentView } from './AuthorityDocumentView';
import {
    AuthorityDocumentViewFromJSON,
    AuthorityDocumentViewFromJSONTyped,
    AuthorityDocumentViewToJSON,
} from './AuthorityDocumentView';

/**
 * 
 * @export
 * @interface PaginatedAuthorityDocumentViewList
 */
export interface PaginatedAuthorityDocumentViewList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    nextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    previousPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    pageSize: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AuthorityDocumentView>}
     * @memberof PaginatedAuthorityDocumentViewList
     */
    results: Array<AuthorityDocumentView>;
}

/**
 * Check if a given object implements the PaginatedAuthorityDocumentViewList interface.
 */
export function instanceOfPaginatedAuthorityDocumentViewList(value: object): value is PaginatedAuthorityDocumentViewList {
    if (!('totalPages' in value) || value['totalPages'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('pageSize' in value) || value['pageSize'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedAuthorityDocumentViewListFromJSON(json: any): PaginatedAuthorityDocumentViewList {
    return PaginatedAuthorityDocumentViewListFromJSONTyped(json, false);
}

export function PaginatedAuthorityDocumentViewListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAuthorityDocumentViewList {
    if (json == null) {
        return json;
    }
    return {
        
        'totalPages': json['total_pages'],
        'page': json['page'],
        'nextPage': json['next_page'] == null ? undefined : json['next_page'],
        'previousPage': json['previous_page'] == null ? undefined : json['previous_page'],
        'count': json['count'],
        'pageSize': json['page_size'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(AuthorityDocumentViewFromJSON)),
    };
}

export function PaginatedAuthorityDocumentViewListToJSON(value?: PaginatedAuthorityDocumentViewList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total_pages': value['totalPages'],
        'page': value['page'],
        'next_page': value['nextPage'],
        'previous_page': value['previousPage'],
        'count': value['count'],
        'page_size': value['pageSize'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(AuthorityDocumentViewToJSON)),
    };
}

