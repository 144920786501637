/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `AI_AUTONOMOUS_TECHNOLOGIES` - Artificial Intelligence (AI) & Autonomous Technologies
 * * `ASSET_MANAGEMENT` - Asset Management
 * * `BUSINESS_CONTINUITY` - Business Continuity
 * * `CHANGE_MANAGEMENT` - Change Management
 * * `COMPLIANCE` - Compliance
 * * `CRYPTOGRAPHIC_PROTECTIONS` - Cryptographic Protections
 * * `CYBERSECURITY_DATA_PROTECTION` - Cybersecurity & Data Protection Management
 * * `DATA_PROTECTION_EDUCATION` - Data Protection Education
 * * `EVENT_LOG_MONITORING` - Event Log Monitoring
 * * `HUMAN_RESOURCES` - Human Resources
 * * `IDENTITY_ACCESS_MANAGEMENT` - Identity & Access Management
 * * `INCIDENT_RESPONSE` - Incident Response
 * * `INFORMATION_ASSURANCE` - Information Assurance
 * * `MAINTENANCE` - Maintenance
 * * `NETWORK_SECURITY` - Network Security
 * * `PHYSICAL_SECURITY` - Physical Security
 * * `PRIVACY` - Privacy
 * * `RESOURCE_MANAGEMENT` - Resource Management
 * * `RISK_MANAGEMENT` - Risk Management
 * * `TECHNOLOGY_DESIGN_ACQUISITION` - Technology Design & Acquisition
 * * `THIRD_PARTY_MANAGEMENT` - Third-Party Management
 * * `THREAT_MANAGEMENT` - Threat Management
 * * `VULNERABILITY_PATCH_MANAGEMENT` - Vulnerability & Patch Management
 * * `VULNERABILITY_MANAGEMENT` - Vulnerability Management
 * @export
 */
export const AreaOfFocusEnum = {
    AiAutonomousTechnologies: 'AI_AUTONOMOUS_TECHNOLOGIES',
    AssetManagement: 'ASSET_MANAGEMENT',
    BusinessContinuity: 'BUSINESS_CONTINUITY',
    ChangeManagement: 'CHANGE_MANAGEMENT',
    Compliance: 'COMPLIANCE',
    CryptographicProtections: 'CRYPTOGRAPHIC_PROTECTIONS',
    CybersecurityDataProtection: 'CYBERSECURITY_DATA_PROTECTION',
    DataProtectionEducation: 'DATA_PROTECTION_EDUCATION',
    EventLogMonitoring: 'EVENT_LOG_MONITORING',
    HumanResources: 'HUMAN_RESOURCES',
    IdentityAccessManagement: 'IDENTITY_ACCESS_MANAGEMENT',
    IncidentResponse: 'INCIDENT_RESPONSE',
    InformationAssurance: 'INFORMATION_ASSURANCE',
    Maintenance: 'MAINTENANCE',
    NetworkSecurity: 'NETWORK_SECURITY',
    PhysicalSecurity: 'PHYSICAL_SECURITY',
    Privacy: 'PRIVACY',
    ResourceManagement: 'RESOURCE_MANAGEMENT',
    RiskManagement: 'RISK_MANAGEMENT',
    TechnologyDesignAcquisition: 'TECHNOLOGY_DESIGN_ACQUISITION',
    ThirdPartyManagement: 'THIRD_PARTY_MANAGEMENT',
    ThreatManagement: 'THREAT_MANAGEMENT',
    VulnerabilityPatchManagement: 'VULNERABILITY_PATCH_MANAGEMENT',
    VulnerabilityManagement: 'VULNERABILITY_MANAGEMENT'
} as const;
export type AreaOfFocusEnum = typeof AreaOfFocusEnum[keyof typeof AreaOfFocusEnum];


export function instanceOfAreaOfFocusEnum(value: any): boolean {
    for (const key in AreaOfFocusEnum) {
        if (Object.prototype.hasOwnProperty.call(AreaOfFocusEnum, key)) {
            if (AreaOfFocusEnum[key as keyof typeof AreaOfFocusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AreaOfFocusEnumFromJSON(json: any): AreaOfFocusEnum {
    return AreaOfFocusEnumFromJSONTyped(json, false);
}

export function AreaOfFocusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaOfFocusEnum {
    return json as AreaOfFocusEnum;
}

export function AreaOfFocusEnumToJSON(value?: AreaOfFocusEnum | null): any {
    return value as any;
}

