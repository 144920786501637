/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Dashboard,
  DashboardComment,
  DashboardCommentRequest,
  DashboardItemList,
  DashboardList,
  DashboardListItemList,
  DashboardListRequest,
  DashboardRequest,
  DashboardTemplate,
  DashboardTemplateItemList,
  DashboardTemplateList,
  DashboardTemplateListItemList,
  DashboardTemplateListRequest,
  DashboardTemplateRequest,
  PaginatedDashboardCommentList,
  PaginatedDashboardItemListList,
  PaginatedDashboardListItemListList,
  PaginatedDashboardTemplateItemListList,
  PaginatedDashboardTemplateListItemListList,
  PaginatedReportThemeList,
  PaginatedWidgetList,
  PatchedDashboardCommentRequest,
  PatchedDashboardListRequest,
  PatchedDashboardRequest,
  PatchedDashboardTemplateListRequest,
  PatchedDashboardTemplateRequest,
  PatchedReportThemeRequest,
  PatchedWidgetRequest,
  ReportTheme,
  ReportThemeRequest,
  Widget,
  WidgetRequest,
} from '../models/index';
import {
    DashboardFromJSON,
    DashboardToJSON,
    DashboardCommentFromJSON,
    DashboardCommentToJSON,
    DashboardCommentRequestFromJSON,
    DashboardCommentRequestToJSON,
    DashboardItemListFromJSON,
    DashboardItemListToJSON,
    DashboardListFromJSON,
    DashboardListToJSON,
    DashboardListItemListFromJSON,
    DashboardListItemListToJSON,
    DashboardListRequestFromJSON,
    DashboardListRequestToJSON,
    DashboardRequestFromJSON,
    DashboardRequestToJSON,
    DashboardTemplateFromJSON,
    DashboardTemplateToJSON,
    DashboardTemplateItemListFromJSON,
    DashboardTemplateItemListToJSON,
    DashboardTemplateListFromJSON,
    DashboardTemplateListToJSON,
    DashboardTemplateListItemListFromJSON,
    DashboardTemplateListItemListToJSON,
    DashboardTemplateListRequestFromJSON,
    DashboardTemplateListRequestToJSON,
    DashboardTemplateRequestFromJSON,
    DashboardTemplateRequestToJSON,
    PaginatedDashboardCommentListFromJSON,
    PaginatedDashboardCommentListToJSON,
    PaginatedDashboardItemListListFromJSON,
    PaginatedDashboardItemListListToJSON,
    PaginatedDashboardListItemListListFromJSON,
    PaginatedDashboardListItemListListToJSON,
    PaginatedDashboardTemplateItemListListFromJSON,
    PaginatedDashboardTemplateItemListListToJSON,
    PaginatedDashboardTemplateListItemListListFromJSON,
    PaginatedDashboardTemplateListItemListListToJSON,
    PaginatedReportThemeListFromJSON,
    PaginatedReportThemeListToJSON,
    PaginatedWidgetListFromJSON,
    PaginatedWidgetListToJSON,
    PatchedDashboardCommentRequestFromJSON,
    PatchedDashboardCommentRequestToJSON,
    PatchedDashboardListRequestFromJSON,
    PatchedDashboardListRequestToJSON,
    PatchedDashboardRequestFromJSON,
    PatchedDashboardRequestToJSON,
    PatchedDashboardTemplateListRequestFromJSON,
    PatchedDashboardTemplateListRequestToJSON,
    PatchedDashboardTemplateRequestFromJSON,
    PatchedDashboardTemplateRequestToJSON,
    PatchedReportThemeRequestFromJSON,
    PatchedReportThemeRequestToJSON,
    PatchedWidgetRequestFromJSON,
    PatchedWidgetRequestToJSON,
    ReportThemeFromJSON,
    ReportThemeToJSON,
    ReportThemeRequestFromJSON,
    ReportThemeRequestToJSON,
    WidgetFromJSON,
    WidgetToJSON,
    WidgetRequestFromJSON,
    WidgetRequestToJSON,
} from '../models/index';

export interface ReportsDashboardCommentsCreateRequest {
    dashboardCommentRequest: DashboardCommentRequest;
}

export interface ReportsDashboardCommentsDestroyRequest {
    id: number;
}

export interface ReportsDashboardCommentsListRequest {
    page?: number;
    pageSize?: number;
    status?: ReportsDashboardCommentsListStatusEnum;
}

export interface ReportsDashboardCommentsPartialUpdateRequest {
    id: number;
    patchedDashboardCommentRequest?: PatchedDashboardCommentRequest;
}

export interface ReportsDashboardCommentsRetrieveRequest {
    id: number;
}

export interface ReportsDashboardCommentsUpdateRequest {
    id: number;
    dashboardCommentRequest: DashboardCommentRequest;
}

export interface ReportsDashboardListsAddDashboardsPartialUpdateRequest {
    id: number;
    patchedDashboardListRequest?: PatchedDashboardListRequest;
}

export interface ReportsDashboardListsCreateRequest {
    dashboardListRequest: DashboardListRequest;
}

export interface ReportsDashboardListsDestroyRequest {
    id: number;
}

export interface ReportsDashboardListsListRequest {
    name?: string;
    nameIcontains?: string;
    nameSearch?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface ReportsDashboardListsPartialUpdateRequest {
    id: number;
    patchedDashboardListRequest?: PatchedDashboardListRequest;
}

export interface ReportsDashboardListsRetrieveRequest {
    id: number;
}

export interface ReportsDashboardListsUpdateRequest {
    id: number;
    dashboardListRequest: DashboardListRequest;
}

export interface ReportsDashboardsCreateRequest {
    dashboardRequest: DashboardRequest;
}

export interface ReportsDashboardsDestroyRequest {
    id: number;
}

export interface ReportsDashboardsListRequest {
    name?: string;
    nameIcontains?: string;
    nameSearch?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface ReportsDashboardsPartialUpdateRequest {
    id: number;
    patchedDashboardRequest?: PatchedDashboardRequest;
}

export interface ReportsDashboardsRetrieveRequest {
    id: number;
}

export interface ReportsDashboardsUpdateRequest {
    id: number;
    dashboardRequest: DashboardRequest;
}

export interface ReportsDashboardsWidgetsCreateRequest {
    dashboardId: number;
    widgetRequest: WidgetRequest;
}

export interface ReportsDashboardsWidgetsDestroyRequest {
    dashboardId: number;
}

export interface ReportsDashboardsWidgetsDestroy2Request {
    dashboardId: number;
    widgetId: number;
}

export interface ReportsDashboardsWidgetsListRequest {
    dashboardId: number;
    page?: number;
    pageSize?: number;
}

export interface ReportsDashboardsWidgetsPartialUpdateRequest {
    dashboardId: number;
    patchedWidgetRequest?: PatchedWidgetRequest;
}

export interface ReportsDashboardsWidgetsPartialUpdate2Request {
    dashboardId: number;
    widgetId: number;
    patchedWidgetRequest?: PatchedWidgetRequest;
}

export interface ReportsDashboardsWidgetsRetrieveRequest {
    dashboardId: number;
    widgetId: number;
}

export interface ReportsTemplateListsCreateRequest {
    dashboardTemplateListRequest: DashboardTemplateListRequest;
}

export interface ReportsTemplateListsDestroyRequest {
    id: number;
}

export interface ReportsTemplateListsListRequest {
    page?: number;
    pageSize?: number;
}

export interface ReportsTemplateListsPartialUpdateRequest {
    id: number;
    patchedDashboardTemplateListRequest?: PatchedDashboardTemplateListRequest;
}

export interface ReportsTemplateListsRetrieveRequest {
    id: number;
}

export interface ReportsTemplateListsUpdateRequest {
    id: number;
    dashboardTemplateListRequest: DashboardTemplateListRequest;
}

export interface ReportsTemplatesCreateRequest {
    dashboardTemplateRequest: DashboardTemplateRequest;
}

export interface ReportsTemplatesDestroyRequest {
    id: number;
}

export interface ReportsTemplatesListRequest {
    page?: number;
    pageSize?: number;
}

export interface ReportsTemplatesPartialUpdateRequest {
    id: number;
    patchedDashboardTemplateRequest?: PatchedDashboardTemplateRequest;
}

export interface ReportsTemplatesRetrieveRequest {
    id: number;
}

export interface ReportsTemplatesUpdateRequest {
    id: number;
    dashboardTemplateRequest: DashboardTemplateRequest;
}

export interface ReportsThemesCreateRequest {
    reportThemeRequest: ReportThemeRequest;
}

export interface ReportsThemesDestroyRequest {
    id: number;
}

export interface ReportsThemesListRequest {
    page?: number;
    pageSize?: number;
}

export interface ReportsThemesPartialUpdateRequest {
    id: number;
    patchedReportThemeRequest?: PatchedReportThemeRequest;
}

export interface ReportsThemesRetrieveRequest {
    id: number;
}

export interface ReportsThemesUpdateRequest {
    id: number;
    reportThemeRequest: ReportThemeRequest;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     */
    async reportsDashboardCommentsCreateRaw(requestParameters: ReportsDashboardCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardComment>> {
        if (requestParameters['dashboardCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardCommentRequest',
                'Required parameter "dashboardCommentRequest" was null or undefined when calling reportsDashboardCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardCommentRequestToJSON(requestParameters['dashboardCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCommentFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardCommentsCreate(requestParameters: ReportsDashboardCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardComment> {
        const response = await this.reportsDashboardCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardCommentsDestroyRaw(requestParameters: ReportsDashboardCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsDashboardCommentsDestroy(requestParameters: ReportsDashboardCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsDashboardCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsDashboardCommentsListRaw(requestParameters: ReportsDashboardCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDashboardCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDashboardCommentListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardCommentsList(requestParameters: ReportsDashboardCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDashboardCommentList> {
        const response = await this.reportsDashboardCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardCommentsPartialUpdateRaw(requestParameters: ReportsDashboardCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardCommentRequestToJSON(requestParameters['patchedDashboardCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCommentFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardCommentsPartialUpdate(requestParameters: ReportsDashboardCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardComment> {
        const response = await this.reportsDashboardCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardCommentsRetrieveRaw(requestParameters: ReportsDashboardCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCommentFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardCommentsRetrieve(requestParameters: ReportsDashboardCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardComment> {
        const response = await this.reportsDashboardCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardCommentsUpdateRaw(requestParameters: ReportsDashboardCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardCommentsUpdate().'
            );
        }

        if (requestParameters['dashboardCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardCommentRequest',
                'Required parameter "dashboardCommentRequest" was null or undefined when calling reportsDashboardCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardCommentRequestToJSON(requestParameters['dashboardCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCommentFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardCommentsUpdate(requestParameters: ReportsDashboardCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardComment> {
        const response = await this.reportsDashboardCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsAddDashboardsPartialUpdateRaw(requestParameters: ReportsDashboardListsAddDashboardsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardListsAddDashboardsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/{id}/add_dashboards/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardListRequestToJSON(requestParameters['patchedDashboardListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsAddDashboardsPartialUpdate(requestParameters: ReportsDashboardListsAddDashboardsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardList> {
        const response = await this.reportsDashboardListsAddDashboardsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsCreateRaw(requestParameters: ReportsDashboardListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardList>> {
        if (requestParameters['dashboardListRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardListRequest',
                'Required parameter "dashboardListRequest" was null or undefined when calling reportsDashboardListsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardListRequestToJSON(requestParameters['dashboardListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsCreate(requestParameters: ReportsDashboardListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardList> {
        const response = await this.reportsDashboardListsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsDestroyRaw(requestParameters: ReportsDashboardListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardListsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsDashboardListsDestroy(requestParameters: ReportsDashboardListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsDashboardListsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsDashboardListsListRaw(requestParameters: ReportsDashboardListsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDashboardListItemListList>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['nameSearch'] != null) {
            queryParameters['name__search'] = requestParameters['nameSearch'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDashboardListItemListListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsList(requestParameters: ReportsDashboardListsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDashboardListItemListList> {
        const response = await this.reportsDashboardListsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsPartialUpdateRaw(requestParameters: ReportsDashboardListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardListsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardListRequestToJSON(requestParameters['patchedDashboardListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsPartialUpdate(requestParameters: ReportsDashboardListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardList> {
        const response = await this.reportsDashboardListsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsRetrieveRaw(requestParameters: ReportsDashboardListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardListItemList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardListsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListItemListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsRetrieve(requestParameters: ReportsDashboardListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardListItemList> {
        const response = await this.reportsDashboardListsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardListsUpdateRaw(requestParameters: ReportsDashboardListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardListsUpdate().'
            );
        }

        if (requestParameters['dashboardListRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardListRequest',
                'Required parameter "dashboardListRequest" was null or undefined when calling reportsDashboardListsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboard-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardListRequestToJSON(requestParameters['dashboardListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardListsUpdate(requestParameters: ReportsDashboardListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardList> {
        const response = await this.reportsDashboardListsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsCreateRaw(requestParameters: ReportsDashboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters['dashboardRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardRequest',
                'Required parameter "dashboardRequest" was null or undefined when calling reportsDashboardsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardRequestToJSON(requestParameters['dashboardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsCreate(requestParameters: ReportsDashboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.reportsDashboardsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsDestroyRaw(requestParameters: ReportsDashboardsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsDashboardsDestroy(requestParameters: ReportsDashboardsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsDashboardsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsDashboardsListRaw(requestParameters: ReportsDashboardsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDashboardItemListList>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['nameSearch'] != null) {
            queryParameters['name__search'] = requestParameters['nameSearch'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDashboardItemListListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsList(requestParameters: ReportsDashboardsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDashboardItemListList> {
        const response = await this.reportsDashboardsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsPartialUpdateRaw(requestParameters: ReportsDashboardsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardRequestToJSON(requestParameters['patchedDashboardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsPartialUpdate(requestParameters: ReportsDashboardsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.reportsDashboardsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsRetrieveRaw(requestParameters: ReportsDashboardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardItemList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardItemListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsRetrieve(requestParameters: ReportsDashboardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardItemList> {
        const response = await this.reportsDashboardsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsUpdateRaw(requestParameters: ReportsDashboardsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsDashboardsUpdate().'
            );
        }

        if (requestParameters['dashboardRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardRequest',
                'Required parameter "dashboardRequest" was null or undefined when calling reportsDashboardsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardRequestToJSON(requestParameters['dashboardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsUpdate(requestParameters: ReportsDashboardsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.reportsDashboardsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsWidgetsCreateRaw(requestParameters: ReportsDashboardsWidgetsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsCreate().'
            );
        }

        if (requestParameters['widgetRequest'] == null) {
            throw new runtime.RequiredError(
                'widgetRequest',
                'Required parameter "widgetRequest" was null or undefined when calling reportsDashboardsWidgetsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetRequestToJSON(requestParameters['widgetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsWidgetsCreate(requestParameters: ReportsDashboardsWidgetsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Widget> {
        const response = await this.reportsDashboardsWidgetsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsWidgetsDestroyRaw(requestParameters: ReportsDashboardsWidgetsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsDashboardsWidgetsDestroy(requestParameters: ReportsDashboardsWidgetsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsDashboardsWidgetsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsDashboardsWidgetsDestroy2Raw(requestParameters: ReportsDashboardsWidgetsDestroy2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsDestroy2().'
            );
        }

        if (requestParameters['widgetId'] == null) {
            throw new runtime.RequiredError(
                'widgetId',
                'Required parameter "widgetId" was null or undefined when calling reportsDashboardsWidgetsDestroy2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/{widget_id}/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))).replace(`{${"widget_id"}}`, encodeURIComponent(String(requestParameters['widgetId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsDashboardsWidgetsDestroy2(requestParameters: ReportsDashboardsWidgetsDestroy2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsDashboardsWidgetsDestroy2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsDashboardsWidgetsListRaw(requestParameters: ReportsDashboardsWidgetsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedWidgetList>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedWidgetListFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsWidgetsList(requestParameters: ReportsDashboardsWidgetsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedWidgetList> {
        const response = await this.reportsDashboardsWidgetsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsWidgetsPartialUpdateRaw(requestParameters: ReportsDashboardsWidgetsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedWidgetRequestToJSON(requestParameters['patchedWidgetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsWidgetsPartialUpdate(requestParameters: ReportsDashboardsWidgetsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Widget> {
        const response = await this.reportsDashboardsWidgetsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsWidgetsPartialUpdate2Raw(requestParameters: ReportsDashboardsWidgetsPartialUpdate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsPartialUpdate2().'
            );
        }

        if (requestParameters['widgetId'] == null) {
            throw new runtime.RequiredError(
                'widgetId',
                'Required parameter "widgetId" was null or undefined when calling reportsDashboardsWidgetsPartialUpdate2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/{widget_id}/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))).replace(`{${"widget_id"}}`, encodeURIComponent(String(requestParameters['widgetId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedWidgetRequestToJSON(requestParameters['patchedWidgetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsWidgetsPartialUpdate2(requestParameters: ReportsDashboardsWidgetsPartialUpdate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Widget> {
        const response = await this.reportsDashboardsWidgetsPartialUpdate2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsDashboardsWidgetsRetrieveRaw(requestParameters: ReportsDashboardsWidgetsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling reportsDashboardsWidgetsRetrieve().'
            );
        }

        if (requestParameters['widgetId'] == null) {
            throw new runtime.RequiredError(
                'widgetId',
                'Required parameter "widgetId" was null or undefined when calling reportsDashboardsWidgetsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/dashboards/{dashboard_id}/widgets/{widget_id}/`.replace(`{${"dashboard_id"}}`, encodeURIComponent(String(requestParameters['dashboardId']))).replace(`{${"widget_id"}}`, encodeURIComponent(String(requestParameters['widgetId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     */
    async reportsDashboardsWidgetsRetrieve(requestParameters: ReportsDashboardsWidgetsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Widget> {
        const response = await this.reportsDashboardsWidgetsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplateListsCreateRaw(requestParameters: ReportsTemplateListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplateList>> {
        if (requestParameters['dashboardTemplateListRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardTemplateListRequest',
                'Required parameter "dashboardTemplateListRequest" was null or undefined when calling reportsTemplateListsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardTemplateListRequestToJSON(requestParameters['dashboardTemplateListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplateListsCreate(requestParameters: ReportsTemplateListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplateList> {
        const response = await this.reportsTemplateListsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplateListsDestroyRaw(requestParameters: ReportsTemplateListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplateListsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsTemplateListsDestroy(requestParameters: ReportsTemplateListsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsTemplateListsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsTemplateListsListRaw(requestParameters: ReportsTemplateListsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDashboardTemplateListItemListList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDashboardTemplateListItemListListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplateListsList(requestParameters: ReportsTemplateListsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDashboardTemplateListItemListList> {
        const response = await this.reportsTemplateListsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplateListsPartialUpdateRaw(requestParameters: ReportsTemplateListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplateList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplateListsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardTemplateListRequestToJSON(requestParameters['patchedDashboardTemplateListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplateListsPartialUpdate(requestParameters: ReportsTemplateListsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplateList> {
        const response = await this.reportsTemplateListsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplateListsRetrieveRaw(requestParameters: ReportsTemplateListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplateListItemList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplateListsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateListItemListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplateListsRetrieve(requestParameters: ReportsTemplateListsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplateListItemList> {
        const response = await this.reportsTemplateListsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplateListsUpdateRaw(requestParameters: ReportsTemplateListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplateList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplateListsUpdate().'
            );
        }

        if (requestParameters['dashboardTemplateListRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardTemplateListRequest',
                'Required parameter "dashboardTemplateListRequest" was null or undefined when calling reportsTemplateListsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/template-lists/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardTemplateListRequestToJSON(requestParameters['dashboardTemplateListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplateListsUpdate(requestParameters: ReportsTemplateListsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplateList> {
        const response = await this.reportsTemplateListsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplatesCreateRaw(requestParameters: ReportsTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplate>> {
        if (requestParameters['dashboardTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardTemplateRequest',
                'Required parameter "dashboardTemplateRequest" was null or undefined when calling reportsTemplatesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardTemplateRequestToJSON(requestParameters['dashboardTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplatesCreate(requestParameters: ReportsTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplate> {
        const response = await this.reportsTemplatesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplatesDestroyRaw(requestParameters: ReportsTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplatesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsTemplatesDestroy(requestParameters: ReportsTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsTemplatesListRaw(requestParameters: ReportsTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDashboardTemplateItemListList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDashboardTemplateItemListListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplatesList(requestParameters: ReportsTemplatesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDashboardTemplateItemListList> {
        const response = await this.reportsTemplatesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplatesPartialUpdateRaw(requestParameters: ReportsTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplatesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDashboardTemplateRequestToJSON(requestParameters['patchedDashboardTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplatesPartialUpdate(requestParameters: ReportsTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplate> {
        const response = await this.reportsTemplatesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplatesRetrieveRaw(requestParameters: ReportsTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplateItemList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplatesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateItemListFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplatesRetrieve(requestParameters: ReportsTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplateItemList> {
        const response = await this.reportsTemplatesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsTemplatesUpdateRaw(requestParameters: ReportsTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsTemplatesUpdate().'
            );
        }

        if (requestParameters['dashboardTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'dashboardTemplateRequest',
                'Required parameter "dashboardTemplateRequest" was null or undefined when calling reportsTemplatesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardTemplateRequestToJSON(requestParameters['dashboardTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateFromJSON(jsonValue));
    }

    /**
     */
    async reportsTemplatesUpdate(requestParameters: ReportsTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardTemplate> {
        const response = await this.reportsTemplatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsThemesCreateRaw(requestParameters: ReportsThemesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportTheme>> {
        if (requestParameters['reportThemeRequest'] == null) {
            throw new runtime.RequiredError(
                'reportThemeRequest',
                'Required parameter "reportThemeRequest" was null or undefined when calling reportsThemesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportThemeRequestToJSON(requestParameters['reportThemeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportThemeFromJSON(jsonValue));
    }

    /**
     */
    async reportsThemesCreate(requestParameters: ReportsThemesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportTheme> {
        const response = await this.reportsThemesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsThemesDestroyRaw(requestParameters: ReportsThemesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsThemesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsThemesDestroy(requestParameters: ReportsThemesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsThemesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reportsThemesListRaw(requestParameters: ReportsThemesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedReportThemeList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedReportThemeListFromJSON(jsonValue));
    }

    /**
     */
    async reportsThemesList(requestParameters: ReportsThemesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedReportThemeList> {
        const response = await this.reportsThemesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsThemesPartialUpdateRaw(requestParameters: ReportsThemesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportTheme>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsThemesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedReportThemeRequestToJSON(requestParameters['patchedReportThemeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportThemeFromJSON(jsonValue));
    }

    /**
     */
    async reportsThemesPartialUpdate(requestParameters: ReportsThemesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportTheme> {
        const response = await this.reportsThemesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsThemesRetrieveRaw(requestParameters: ReportsThemesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportTheme>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsThemesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportThemeFromJSON(jsonValue));
    }

    /**
     */
    async reportsThemesRetrieve(requestParameters: ReportsThemesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportTheme> {
        const response = await this.reportsThemesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsThemesUpdateRaw(requestParameters: ReportsThemesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportTheme>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportsThemesUpdate().'
            );
        }

        if (requestParameters['reportThemeRequest'] == null) {
            throw new runtime.RequiredError(
                'reportThemeRequest',
                'Required parameter "reportThemeRequest" was null or undefined when calling reportsThemesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/reports/themes/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportThemeRequestToJSON(requestParameters['reportThemeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportThemeFromJSON(jsonValue));
    }

    /**
     */
    async reportsThemesUpdate(requestParameters: ReportsThemesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportTheme> {
        const response = await this.reportsThemesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ReportsDashboardCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type ReportsDashboardCommentsListStatusEnum = typeof ReportsDashboardCommentsListStatusEnum[keyof typeof ReportsDashboardCommentsListStatusEnum];
