/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedReviewFrequencyRequestFrequency } from './PatchedReviewFrequencyRequestFrequency';
import {
    PatchedReviewFrequencyRequestFrequencyFromJSON,
    PatchedReviewFrequencyRequestFrequencyFromJSONTyped,
    PatchedReviewFrequencyRequestFrequencyToJSON,
} from './PatchedReviewFrequencyRequestFrequency';

/**
 * 
 * @export
 * @interface ReviewFrequencyRequest
 */
export interface ReviewFrequencyRequest {
    /**
     * 
     * @type {PatchedReviewFrequencyRequestFrequency}
     * @memberof ReviewFrequencyRequest
     */
    frequency?: PatchedReviewFrequencyRequestFrequency | null;
    /**
     * 
     * @type {Date}
     * @memberof ReviewFrequencyRequest
     */
    nextReviewAt?: Date | null;
}

/**
 * Check if a given object implements the ReviewFrequencyRequest interface.
 */
export function instanceOfReviewFrequencyRequest(value: object): value is ReviewFrequencyRequest {
    return true;
}

export function ReviewFrequencyRequestFromJSON(json: any): ReviewFrequencyRequest {
    return ReviewFrequencyRequestFromJSONTyped(json, false);
}

export function ReviewFrequencyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewFrequencyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'frequency': json['frequency'] == null ? undefined : PatchedReviewFrequencyRequestFrequencyFromJSON(json['frequency']),
        'nextReviewAt': json['next_review_at'] == null ? undefined : (new Date(json['next_review_at'])),
    };
}

export function ReviewFrequencyRequestToJSON(value?: ReviewFrequencyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frequency': PatchedReviewFrequencyRequestFrequencyToJSON(value['frequency']),
        'next_review_at': value['nextReviewAt'] == null ? undefined : ((value['nextReviewAt'] as any).toISOString().substring(0,10)),
    };
}

