import { create } from "zustand";

interface CommonTableV2FiltersModulesType {
  vendor: boolean;
  questionnaires: boolean;
  "risk-register": boolean;
  assessments: boolean;
  risk: boolean;
  agreements: boolean;
  "agreements-attachment": boolean;
  reports: boolean;
  "horizon-scanning": boolean;
  controls: boolean;
  evidences: boolean;
}

const initialToggleFilter: CommonTableV2FiltersModulesType = {
  vendor: false,
  questionnaires: false,
  "risk-register": false,
  risk: false,
  assessments: false,
  agreements: false,
  "agreements-attachment": false,
  reports: false,
  "horizon-scanning": false,
  controls: false,
  evidences: false,
};

interface CommonTableV2FiltersStore {
  toggleFilter: CommonTableV2FiltersModulesType;
  setToggleFilter: (moduleName: keyof CommonTableV2FiltersModulesType) => void;
  resetToggleFilters: () => void;
}

export const useCommonTableV2Filters = create<CommonTableV2FiltersStore>((set) => ({
  toggleFilter: initialToggleFilter,
  setToggleFilter: (moduleName: keyof CommonTableV2FiltersModulesType) =>
    set((state) => ({
      toggleFilter: {
        ...state.toggleFilter,
        [moduleName]: !state.toggleFilter[moduleName],
      },
    })),
  resetToggleFilters: () => set({ toggleFilter: initialToggleFilter }),
}));
