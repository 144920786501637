/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskMatrixSettings } from './RiskMatrixSettings';
import {
    RiskMatrixSettingsFromJSON,
    RiskMatrixSettingsFromJSONTyped,
    RiskMatrixSettingsToJSON,
} from './RiskMatrixSettings';

/**
 * 
 * @export
 * @interface RiskMatrixTemplate
 */
export interface RiskMatrixTemplate {
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixTemplate
     */
    readonly id: number;
    /**
     * 
     * @type {RiskMatrixSettings}
     * @memberof RiskMatrixTemplate
     */
    matrixSettings?: RiskMatrixSettings;
    /**
     * 
     * @type {Date}
     * @memberof RiskMatrixTemplate
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RiskMatrixTemplate
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrixTemplate
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrixTemplate
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixTemplate
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrixTemplate
     */
    company?: number | null;
}

/**
 * Check if a given object implements the RiskMatrixTemplate interface.
 */
export function instanceOfRiskMatrixTemplate(value: object): value is RiskMatrixTemplate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function RiskMatrixTemplateFromJSON(json: any): RiskMatrixTemplate {
    return RiskMatrixTemplateFromJSONTyped(json, false);
}

export function RiskMatrixTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrixTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'matrixSettings': json['matrix_settings'] == null ? undefined : RiskMatrixSettingsFromJSON(json['matrix_settings']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function RiskMatrixTemplateToJSON(value?: Omit<RiskMatrixTemplate, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'matrix_settings': RiskMatrixSettingsToJSON(value['matrixSettings']),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

